<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4444 21H7.55556C6.6715 21 5.82365 20.6488 5.19853 20.0237C4.57341 19.3986 4.22222 18.5507 4.22222 17.6667V6.55556C4.22222 6.26087 4.10516 5.97825 3.89679 5.76988C3.68841 5.56151 3.4058 5.44444 3.11111 5.44444C2.81643 5.44444 2.53381 5.56151 2.32544 5.76988C2.11706 5.97825 2 6.26087 2 6.55556V17.6667C2 19.1401 2.58532 20.5532 3.62718 21.595C4.66905 22.6369 6.08213 23.2222 7.55556 23.2222H16.4444C16.7391 23.2222 17.0217 23.1052 17.2301 22.8968C17.4385 22.6884 17.5556 22.4058 17.5556 22.1111C17.5556 21.8164 17.4385 21.5338 17.2301 21.3254C17.0217 21.1171 16.7391 21 16.4444 21ZM22 8.71111C21.9884 8.60904 21.9661 8.50848 21.9333 8.41111V8.31111C21.8799 8.19687 21.8086 8.09185 21.7222 8L15.0556 1.33333C14.9637 1.24691 14.8587 1.17565 14.7444 1.12222H14.6444L14.2889 1H9.77778C8.89372 1 8.04588 1.35119 7.42076 1.97631C6.79563 2.60143 6.44444 3.44928 6.44444 4.33333V15.4444C6.44444 16.3285 6.79563 17.1763 7.42076 17.8015C8.04588 18.4266 8.89372 18.7778 9.77778 18.7778H18.6667C19.5507 18.7778 20.3986 18.4266 21.0237 17.8015C21.6488 17.1763 22 16.3285 22 15.4444V8.77778C22 8.77778 22 8.77778 22 8.71111ZM19.7778 15.4444C19.7778 15.7391 19.6607 16.0217 19.4523 16.2301C19.244 16.4385 18.9614 16.5556 18.6667 16.5556H9.77778C9.48309 16.5556 9.20048 16.4385 8.9921 16.2301C8.78373 16.0217 8.66667 15.7391 8.66667 15.4444V4.33333C8.66667 4.03865 8.78373 3.75603 8.9921 3.54766C9.20048 3.33929 9.48309 3.22222 9.77778 3.22222H13.1111V6.55556C13.1111 7.43961 13.4623 8.28746 14.0874 8.91258C14.7125 9.5377 15.5604 9.88889 16.4444 9.88889H19.7778V15.4444Z"
      fill="#6747B3"
    />
  </svg>
</template>
