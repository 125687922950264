<template>
  <Nav></Nav>

  <div class="container-page row" style="display: flex">
    <div class="col" id="menu">
      <SideBar></SideBar>
    </div>

    <div
      class="col s10"
      id="colgeral"
      v-if="step == 0"
      @mouseenter="menuRecallDiv"
    >
      <div
        style="
          height: 54px;
          display: flex;
          align-items: center;
          padding-left: 24px;
        "
      >
        <span style="font-weight: bold; font-size: 14px; color: #606060"
          >Conversor.xls</span
        >
      </div>

      <div style="border: 1px solid rgba(96, 96, 96, 0.3)"></div>

      <div class="row">
        <span class="center textconvert">
          <p>
            Conversor é uma ferramenta poderosa que pode ser utilizada<br />
            no seu dia a dia, converta arquivos .xls para dentro do<br />
            sistema Zee de forma simples e sem burocracia.
          </p>
        </span>
      </div>

      <div class="row img-file">
        <ExcelImportImg />
      </div>
      <br />
      <div style="display: flex; justify-content: center" v-if="step == 0">
        <button
          class="btn-charge"
          @click="openModal()"
          style="
            height: 46px;
            width: 280px;
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 500;
          "
        >
          <i
            class="fas fa-folder-open"
            style="padding-right: 10px; font-size: 20px"
          >
          </i>
          <span style="text-transform: uppercase">Carregar arquivo</span>
        </button>
      </div>

      <div
        class="row center names-btns"
        style="
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <i> Ferramenta liberada sem custo por tempo determinado.</i>
      </div>
    </div>

    <div class="col s10 m0 p0 module-excel" id="colgeral" v-if="step == 1">
      <div class="row option-file-correlation">
        <div>
          <label for="col s3 names-col" style="display: flex" class="active"
            >Arquivo:</label
          >
          <div class="chip block z-depth-2 fileName col s12">
            <i class="material-icons prefix" style="padding-left: 10px"
              >folder</i
            >
            <span
              style="padding-left: 10px"
              v-if="excelJson.nameFile.length > 30"
              >{{ excelJson.nameFile.substring(0, 30) }}...</span
            >
            <span
              style="padding-left: 10px"
              v-if="excelJson.nameFile.length < 30"
              >{{ excelJson.nameFile }}
            </span>

            <i @click="returninitial()" class="col s1 material-icons prefix"
              >clear</i
            >
          </div>
        </div>
        <div class="col s3" style="widht: 70%">
          <label for="names-col" class="active">Nome do arquivo</label>
          <input
            disabled="disabled"
            id="first_name"
            type="text"
            class="validate"
            v-model="excelJson.nameFile"
          />
        </div>
        <div class="col s4 columns">
          <label for="names-col" class="active">Pesquisa de correlação</label>
          <div v-show="config.configurationId == undefined">
            <div class="input-field col s8">
              <i class="material-icons prefix">search</i>
              <input
                type="text"
                id="autocomplete-input"
                class="autocomplete"
                v-model="searchCorrelation"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row" v-show="config.configurationId != undefined">
            <div class="input-field col s8">
              <i
                class="material-icons prefix unSelectCorrection"
                v-if="step == 1"
                @click="unSelectCorrection()"
                >close</i
              >
              <input
                type="text"
                disabled="disable"
                id="autocomplete-input"
                class="autocomplete"
                v-model="searchCorrelation"
                autocomplete="off"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row option-correlation p2" v-if="step == 1">
        <div class="row">
          <div class="s12 col">
            <p class="title"><Iconscorrela /> <span> Correlação</span></p>
          </div>
        </div>
        <div class="row col s12">
          <div class="col s2 unselectable">
            <div class="head-property"><h5>Cliente</h5></div>
            <div class="properties">
              <div
                class="property"
                v-bind:class="{ activeDrag: dragStatus }"
                @dragover.prevent
                @drop.prevent="drop"
                v-for="(property, index) in propertiesHub.Client"
                :key="index"
                :dataProperty="property.name"
                :id="property.name"
              >
                {{ property.value }}
                <span
                  :dataProperty="property.name"
                  v-if="
                    property.value == 'Nome' || property.value == 'Cpf ou Cnpj'
                  "
                  style="
                    padding-left: 1px;
                    font-style: italic;
                    font-weight: 300;
                    font-size: 11px;
                    line-height: 14px;
                  "
                  >obrigatório</span
                >
                <div
                  class="correlation center block property-align"
                  v-if="property.correlation != ''"
                >
                  <span>{{ property.correlation }}</span>
                  <i
                    class="material-icons"
                    @click="
                      removeCorrelation(property.correlation);
                      property.correlation = '';
                    "
                    >close</i
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col s2 unselectable">
            <div class="head-property"><h5>Endereço do Cliente</h5></div>
            <div class="properties">
              <div
                class="property"
                v-bind:class="{ activeDrag: dragStatus }"
                @dragover.prevent
                @drop.prevent="drop"
                v-for="(property, index) in propertiesHub.ClientCustomer"
                :key="index"
                :dataProperty="property.name"
                :id="property.name"
              >
                {{ property.value }}
                <div
                  class="correlation center block property-align"
                  v-if="property.correlation != ''"
                >
                  <span>{{ property.correlation }}</span>
                  <i
                    class="material-icons"
                    @click="
                      removeCorrelation(property.correlation);
                      property.correlation = '';
                    "
                    >close</i
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col s2 unselectable">
            <div class="head-property"><h5>Pedido</h5></div>
            <div class="properties">
              <div
                class="property"
                v-bind:class="{ activeDrag: dragStatus }"
                @dragover.prevent
                @drop.prevent="drop"
                v-for="(property, index) in propertiesHub.Order"
                :key="index"
                :dataProperty="property.name"
                :id="property.name"
              >
                {{ property.value }}
                <span
                  :dataProperty="property.name"
                  v-if="property.value == 'Código'"
                  style="
                    padding-left: 1px;
                    font-style: italic;
                    font-weight: 300;
                    font-size: 11px;
                    line-height: 14px;
                  "
                  >obrigatório</span
                >
                <div
                  class="correlation center block property-align"
                  v-if="property.correlation != ''"
                >
                  <span>{{ property.correlation }}</span>
                  <i
                    class="material-icons"
                    @click="
                      removeCorrelation(property.correlation);
                      property.correlation = '';
                    "
                    >close</i
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col s2 unselectable">
            <div class="head-property"><h5>Produto</h5></div>
            <div class="properties">
              <div
                class="property"
                v-bind:class="{ activeDrag: dragStatus }"
                @dragover.prevent
                @drop.prevent="drop"
                v-for="(property, index) in propertiesHub.OrderProduct"
                :key="index"
                :dataProperty="property.name"
                :id="property.name"
              >
                {{ property.value }}

                <span
                  :dataProperty="property.name"
                  v-if="
                    property.value == 'Nome' ||
                      property.value == 'Quantidade' ||
                      property.value == 'Valor Unitario'
                  "
                  style="
                    font-style: italic;
                    font-weight: 300;
                    font-size: 11px;
                    line-height: 14px;
                  "
                  >obrigatório</span
                >
                <div
                  class="correlation center block property-align"
                  v-if="property.correlation != ''"
                >
                  <span>{{ property.correlation }}</span>
                  <i
                    class="material-icons"
                    @click="
                      removeCorrelation(property.correlation);
                      property.correlation = '';
                    "
                    >close</i
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col s2 unselectable">
            <div class="head-property"><h5>Opções do Produto</h5></div>
            <div
              class="property"
              v-bind:class="{ activeDrag: dragStatus }"
              @dragover.prevent
              @drop.prevent="drop"
              v-for="(property, index) in propertiesHub.OrderProductOption"
              :key="index"
              :dataProperty="property.name"
              :id="property.name"
            >
              {{ property.value }}
              <div
                class="correlation center block property-align"
                v-if="property.correlation != ''"
              >
                <span>{{ property.correlation }}</span>
                <i
                  class="material-icons"
                  @click="
                    removeCorrelation(property.correlation);
                    property.correlation = '';
                  "
                  >close</i
                >
              </div>
            </div>
          </div>

          <div class="col s2 unselectable">
            <div class="head-property"><h5>Pagamento</h5></div>
            <div class="properties">
              <div
                class="property"
                v-bind:class="{ activeDrag: dragStatus }"
                @dragover.prevent
                @drop.prevent="drop"
                v-for="(property, index) in propertiesHub.OrderPayment"
                :key="index"
                :dataProperty="property.name"
                :id="property.name"
              >
                {{ property.value }}
                <div
                  class="correlation block property-align"
                  v-if="property.correlation != ''"
                >
                  <span>{{ property.correlation }}</span>
                  <i
                    class="material-icons"
                    @click="
                      removeCorrelation(property.correlation);
                      property.correlation = '';
                    "
                    >close</i
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row p2" v-if="step == 1">
        <div class="col icon-colunas">
          <i class="material-icons prefix">import_export </i><br />

          <span>COLUNAS</span>
        </div>
        <div class="col s11 property-excel">
          <div
            class="drag chip z-depth-2"
            draggable="true"
            :ondragend="dragend"
            :ondragstart="drag"
            v-for="(key, index) in excelJson.columnnames"
            :key="index"
            :dataDrag="key"
          >
            {{ key }}
          </div>
        </div>
      </div>

      <hr v-if="step == 1" />
      <div class="row preview p2" style="margin-left: -7px" v-if="step == 1">
        <div class="s12">
          <h5>Preview</h5>
        </div>
        <div class="s12 scroll">
          <table>
            <thead>
              <tr>
                <td
                  v-for="(key, index) in columnnames"
                  :key="index"
                  style="border: 1px solid rgb(238, 238, 238) !important"
                >
                  {{ key }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in excelJson.filePreview" :key="i">
                <td v-for="(value, j) in row" :key="j">{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="row"
        style="display: flex; justify-content: center"
        v-if="step == 1"
      >
        <button
          class="btn-cancel"
          style="background-color: #fff; border: none"
          @click="returninitial()"
        >
          CANCELAR
        </button>

        <button
          class="btn-charge"
          style="width: 170px; height: 46px; margin-left: 36px"
          @click="confirmCorrelation()"
        >
          <span v-if="config.configurationId == undefined"
            >Salvar Correlações</span
          >
          <span v-if="config.configurationId != undefined">Confirmar</span>
        </button>
      </div>
    </div>

    <div class="col s10 module-rows" id="colgeral" v-show="step == 2">
      <TableSelectRow></TableSelectRow>
    </div>

    <!-- Modal Structure -->
    <div id="modalSelectExcel" class="modal">
      <div class="modal-content">
        <div class="row">
          <div
            class="row"
            style="margin-left: -0.75rem; margin-right: -0.75rem"
          >
            <div class="s12 center p0">
              <div class="row">
                <h4>Importação de arquivo</h4>
                <hr />
              </div>
              <div class="row importrowexcel">
                <div
                  class="col s6"
                  :style="
                    stepupload === 0
                      ? 'opacity:1'
                      : 'opacity: 0.5 ;pointer-events:none;'
                  "
                >
                  <h6 class="titlerowupload">Primeiro passo</h6>
                  <br />
                  <h6>Carregue um arquivo <b>.xls</b> ou <b>.csv</b> .</h6>
                  <br />
                  <br />
                  <div
                    class="btn-charge"
                    style="padding-top: 4px; width: 333px"
                  >
                    <div
                      class="file-field input-field"
                      style="display: flex; justify-content: center"
                    >
                      <span style="font-size: 14px; width: 333px"
                        >Carregar Arquivo</span
                      >
                      <input
                        id="inputimportExcel"
                        @change="importExcel"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col s6"
                  :style="stepupload === 1 ? 'opacity:1' : 'opacity: 0.5;'"
                >
                  <h6 class="titlerowupload">Segundo passo</h6>
                  <br />
                  <h6>Selecione qual linha deve ser lida</h6>
                  <br />
                  <i>Selecione uma linha*</i>
                  <br />
                  <div
                    class="input-field col s3 offset-s4"
                    :style="
                      stepupload === 0
                        ? 'pointer-events:none'
                        : 'pointer-events:true;'
                    "
                  >
                    <i
                      class="material-icons prefix"
                      v-if="lineSelect != 1"
                      @click="
                        lineSelect--;
                        changeRowSelect();
                      "
                      >keyboard_arrow_left</i
                    >
                    <i
                      class="material-icons prefix disabled"
                      v-if="lineSelect == 1"
                      >keyboard_arrow_left</i
                    >
                    <input
                      type="text"
                      class="center"
                      v-model="lineSelect"
                      disabled="disable"
                    />
                    <i
                      class="material-icons prefix"
                      @click="
                        lineSelect++;
                        changeRowSelect();
                      "
                      >keyboard_arrow_right</i
                    >
                  </div>
                </div>
              </div>

              <div class="row no-space" style="margin: 0px !important">
                <div class="col s12 left" style="height: 50px !important">
                  <b>Preview da linha</b>
                  <div style="padding-bottom: 8px"></div>
                  <div
                    class="row"
                    v-for="(property, index) in excelJsonSelect"
                    :key="index"
                    :id="index"
                    style="
                      display: flex;
                      align-items: center;
                      height: 24px;
                      margin-bottom: 2px;
                    "
                  >
                    <div style="background: #fafafa; height: 24px">
                      <i
                        style="color: #fafafa; padding: 4px"
                        class="fas fa-play"
                        id="fa-play"
                      ></i>
                    </div>

                    <table class="centered">
                      <thead>
                        <tr
                          style="border-bottom: 0px solid rgba(0, 0, 0, 0.12)"
                        >
                          <td
                            v-for="(key, index) in property"
                            :key="index"
                            :id="index"
                            style="color: #6747b3"
                          >
                            {{ key }}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 50px"></div>
      <hr />
      <div class="btns" style="display: flex; margin-left: 450px; widht: 100%">
        <button
          class="modal-close btn-flat col"
          style="color: #6747b3"
          @click="returninitial()"
        >
          CANCELAR
        </button>
        <button
          class="btn-charge"
          style="width: 155px; height: 39.96px; margin-left: 22px"
          @click="uploadFile()"
        >
          IMPORTAR ARQUIVO
        </button>
      </div>
    </div>
    <!-- </div> -->

    <div id="modalSaveCorrelation" class="modal">
      <div class="divtitle">
        <h5 class="titlesave">Salvar correlação</h5>
      </div>
      <hr />
      <div class="titlesave">
        <p>Deseja salvar correlação para ser usada futuramente?</p>
      </div>
      <div class="imputsavecorrelation">
        <input
          id="imputcorrela"
          placeholder="Digite um nome aqui"
          type="text"
          class="validate"
          v-model="nameCorrelation"
          @change="selectCorrection(nameCorrelation)"
        />
      </div>
      <div class="modal-footersavecorrelation">
        <hr />
        <div class="btnssavecorrelation">
          <a
            id="btn1"
            class="modal-close waves-effect btn names-btns btn-flat"
            @click="stepEnd()"
            >Não</a
          >
          <a
            id="btn3"
            class="waves-effect btn names-btns btn-flat"
            @click="saveConfig()"
          >
            <span v-if="config.configurationId == undefined">Sim</span>
            <span v-if="config.configurationId != undefined">Sim</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav";
import TableSelectRow from "../components/TableSelectRow";
import XLSX from "xlsx";
import properties from "../factory/Properties.json";
import Service from "../service/Index.js";
import SideBar from "../components/SideBar.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Iconscorrela from "../assets/dashboard/correla-icons.vue";
import ExcelImportImg from "../assets/dashboard/excel-import.vue";
import {
  controlMenu,
  mountedMenu,
  menuRecallDiv
} from "../factory/CenterControlMenu.js";
export default {
  name: "Conversor",
  plugins: [
    {
      src: "~plugins/vue-js-xlsx.js",
      ssr: false
    }
  ],
  components: {
    Nav,
    TableSelectRow,
    SideBar,
    Iconscorrela,
    ExcelImportImg
  },
  data: () => {
    return {
      fieldsobrigatory: false,
      fieldsblock: false,
      showDialog: true,
      modalSelectExcel: null,
      modalSaveCorrelation: null,
      step: 0,
      stepupload: 0,
      file: null,
      excelJson: { file: {}, columnnames: [], nameFile: "" },
      excelJsonSelect: { col1: {}, col2: {}, col3: {}, col4: {} },
      propertiesHub: properties,
      propfields: { columnnames: [] },
      dragStatus: false,
      columnnames: [],
      config: {
        configurationId: null,
        companyId: null,
        userId: null,
        origin: "hub",
        value: "",
        key: ""
      },
      nameCorrelation: "",
      configs: [],
      searchCorrelation: "",
      lineSelect: 1,
      workbook: [],
      sheetName: "",
      worksheet: "",
      data: []
    };
  },

  mounted() {
    mountedMenu();
    window.ExcelComponent = this;
    // document.getElementById("colgeral").style.width = "60%";
    this.initModals();
    window.Emitter.on("changestep", value => {
      this.step = value;
    });
  },
  updated() {
    this.initModals();
    this.funcFieldsObrigatory();
    controlMenu();
    this.emitStep();
  },

  methods: {
    menuRecallDiv() {
      menuRecallDiv();
    },
    stepEnd() {
      var data = {
        excelJson: this.excelJson,
        propertiesHub: this.propertiesHub
      };
      window.Emitter.emit("loadExcel", data);
      this.step = 2;
    },
    funcFieldsObrigatory(propertyname) {
      if (propertyname) {
        this.fieldsobrigatory = true;
        this.fieldsblock = true;
        document.getElementById(propertyname).style.color = "red";
        document.getElementById(propertyname).classList.add("animation");
      } else {
        this.fieldsobrigatory = false;
        this.fieldsblock = false;
      }
    },
    funcVerificationFields(propertyName, propertyHub) {
      for (let i = 0; i < this.excelJson.file.length; i++) {
        for (var [key, value] of Object.entries(this.excelJson.file[i])) {
          if (key == propertyName) {
            this.verificationTypeFields(value, propertyHub, propertyName);
          }
        }
      }
    },
    unSelectCorrection() {
      this.searchCorrelation = "";
      this.nameCorrelation = "";
      this.config = {
        configurationId: null,
        companyId: null,
        userId: null,
        origin: "hub",
        value: "",
        key: ""
      };
      this.propertiesHub = Object.assign({}, properties);
      this.excelJson.columnnames = Object.assign([], this.columnnames);
    },
    selectCorrection(nameCorrelation) {
      this.searchCorrelation = nameCorrelation;
      this.nameCorrelation = nameCorrelation;
      let result = this.configs.filter(val => {
        return val.value.nameCorrelation == nameCorrelation;
      });
      if (result.length > 0) {
        this.propertiesHub = result[0].value;
        this.config = result[0];
      } else {
        this.propertiesHub = Object.assign({}, properties);
      }
      Object.keys(this.propertiesHub).forEach(object => {
        Object.keys(object).forEach(key => {
          if (
            object != "nameCorrelation" &&
            this.propertiesHub[object][key] != undefined &&
            this.propertiesHub[object][key].correlation != ""
          ) {
            this.verificationTypeFields(
              0,
              this.propertiesHub[object][key].name,
              this.propertiesHub[object][key].correlation
            );
            this.excelJson.columnnames.splice(
              this.excelJson.columnnames.findIndex(value => {
                return value == this.propertiesHub[object][key].correlation;
              }),
              1
            );
          }
        });
      });
    },
    async getConfigs() {
      let self = this;
      let result = await Service.Configuration.get(
        JSON.parse(localStorage.getItem("session")).companies[0].companyId
      );

      if (result.status == 200) {
        var search = [];
        var correlations = result.data.filter(value => {
          return value.key == "hub-correlation";
        });
        correlations.forEach(element => {
          element.value = JSON.parse(atob(element.value));
          this.configs.push(element);
          search[element.value.nameCorrelation] = null;
        });
        var elems = document.querySelectorAll(".autocomplete");
        var instances = M.Autocomplete.init(elems, {
          data: search,
          onAutocomplete: function(txt) {
            self.selectCorrection(txt);
          },
          limit: 20
        });
      }
    },

    confirmCorrelation() {
      Object.keys(this.propertiesHub).forEach(object => {
        Object.keys(object).forEach(key => {
          if (
            this.propertiesHub[object][key] != undefined &&
            this.propertiesHub[object][key].correlation == ""
          ) {
            switch (this.propertiesHub[object][key].name) {
              case "Client.Name":
                this.funcFieldsObrigatory("Client.Name");
                break;
              case "Client.CpfCnpj":
                this.funcFieldsObrigatory("Client.CpfCnpj");
                break;
              case "Order.ReferenceId":
                this.funcFieldsObrigatory("Order.ReferenceId");
                break;
              case "OrderProduct.Name":
                this.funcFieldsObrigatory("OrderProduct.Name");
                break;
              case "OrderProduct.UnitaryValue":
                this.funcFieldsObrigatory("OrderProduct.UnitaryValue");
                break;
              case "OrderProduct.Quantity":
                this.funcFieldsObrigatory("OrderProduct.Quantity");
                break;
            }
          }
        });
      });
      if (this.fieldsobrigatory == false) {
        this.modalSaveCorrelation[0].open();
      } else {
        M.toast({ html: "Necessário preencher campos obrigatórios!" });
      }
    },
    async saveConfig() {
      if (this.nameCorrelation == "") {
        Swal.fire({
          timer: 1800,
          showConfirmButton: false,
          icon: "error",
          title: "Preencha um nome para salvar a correlação."
        });
        this.modalSaveCorrelation[0].open();
        return false;
      } else {
        document.getElementById("autocomplete-input").disabled = true;
      }
      this.config.companyId = JSON.parse(
        localStorage.getItem("session")
      ).companies[0].companyId;
      this.propertiesHub.nameCorrelation = this.nameCorrelation;
      this.config.userId = JSON.parse(localStorage.getItem("session")).userId;
      this.config.value = btoa(JSON.stringify(this.propertiesHub));
      this.config.key = "hub-correlation";
      var result = undefined;
      if (this.config.configurationId == undefined) {
        this.config.configurationId = undefined;
        result = await Service.Configuration.post(this.config);
      } else {
        result = await Service.Configuration.put(this.config);
        this.stepEnd();
        this.modalSaveCorrelation[0].close();
      }
      if (result.status == 200 && this.nameCorrelation !== "") {
        this.configs.push(result.data);
        this.stepEnd();
        this.modalSaveCorrelation[0].close();
      }
    },
    removeCorrelation(property) {
      this.excelJson.columnnames.unshift(property);
    },
    addProperty(propertyHub, property) {
      this.excelJson.columnnames.splice(
        this.excelJson.columnnames.findIndex(value => {
          return value == property;
        }),
        1
      );
      this.funcVerificationFields(property, propertyHub);
      if (propertyHub) {
        var nameObject = propertyHub.substring(0, propertyHub.lastIndexOf("."));
        var propertiesSearch = eval("this.propertiesHub." + nameObject);
        if (
          propertiesSearch.filter(value => {
            return value.name == propertyHub;
          })[0].correlation == ""
        ) {
          if (this.fieldsblock == true) {
            this.removeCorrelation(property);
            M.toast({
              html:
                "Não é possivel correlacionar caracteres ou valores em branco neste campo!"
            });
            return;
          }
          propertiesSearch.filter(value => {
            return value.name == propertyHub;
          })[0].correlation = property;

          if (this.fieldsobrigatory == false) {
            document.getElementById(propertyHub).style =
              "color: rgb(99, 99, 99);";
          }
        }
      } else {
        this.removeCorrelation(property);
        M.toast({ html: "Campo já utilizado" });
      }
    },
    drop: e => {
      if (e.target.getAttribute("dataProperty") !== "fieldobrigatory") {
        window.ExcelComponent.addProperty(
          e.target.getAttribute("dataProperty"),
          e.dataTransfer.getData("text")
        );
      }
      // console.log(
      //   e.target.getAttribute("dataProperty"),
      //   e.dataTransfer.getData("text")
      // );
    },
    drag(e) {
      e.dataTransfer.effectAllowed = "copy";
      e.dataTransfer.setData("text", e.target.getAttribute("dataDrag"));
      this.dragStatus = true;
      // e.target.style.opacity = "0.5";
    },
    dragend(event) {
      this.dragStatus = false;
    },
    allowDrop(event) {
      // console.log('allowDrop');
    },

    reverseColumnSelect() {
      document.getElementById("col1").style.background = "#ededed";
      document.getElementById("col1").className = "";
      document.getElementById("col1").querySelector("i").style.color =
        "#fafafa";
      document.getElementById("col1").querySelector("tr").style.fontSize =
        "9px";
      document.getElementById("col1").style.height = "14px";
      document.getElementById("col1").querySelector("tr").style.lineHeight =
        "5px";

      document.getElementById("col2").style.background = "#ededed";
      document.getElementById("col2").querySelector("i").style.color =
        "#fafafa";
      document.getElementById("col2").className = "";
      document.getElementById("col2").style.height = "14px";

      document.getElementById("col2").querySelector("tr").style.fontSize =
        "9px";
      document.getElementById("col2").querySelector("tr").style.lineHeight =
        "5px";

      document.getElementById("col3").style.background = "#ededed";
      document.getElementById("col3").className = "";
      document.getElementById("col3").querySelector("i").style.color =
        "#fafafa";
      document.getElementById("col3").style.height = "14px";

      document.getElementById("col3").querySelector("tr").style.fontSize =
        "9px";
      document.getElementById("col3").querySelector("tr").style.lineHeight =
        "5px";

      document.getElementById("col4").style.background = "#ededed";
      document.getElementById("col4").className = "";
      document.getElementById("col4").querySelector("i").style.color =
        "#fafafa";
      document.getElementById("col4").style.height = "14px";

      document.getElementById("col4").querySelector("tr").style.fontSize =
        "9px";
      document.getElementById("col4").querySelector("tr").style.lineHeight =
        "5px";
    },

    async changeRowSelect() {
      this.reverseColumnSelect();

      if (this.lineSelect == 1) {
        document.getElementById("col1").querySelector("i").style.color =
          "#6747B3";
        document.getElementById("col1").style.background = "#e1daf0";
        document.getElementById("col1").className =
          "animate__animated animate__fadeIn";
        document.getElementById("col1").style.height = "24px";
        document.getElementById("col1").querySelector("tr").style.fontSize =
          "12px";
        document.getElementById("col1").querySelector("tr").style.lineHeight =
          "15px";
      }

      if (this.lineSelect == 2) {
        document.getElementById("col2").querySelector("i").style.color =
          "#6747B3";

        document.getElementById("col2").style.background = "#e1daf0";
        document.getElementById("col2").className =
          "animate__animated animate__fadeIn";
        document.getElementById("col2").style.height = "24px";
        document.getElementById("col2").querySelector("tr").style.fontSize =
          "12px";
        document.getElementById("col2").querySelector("tr").style.lineHeight =
          "15px";
      }
      if (this.lineSelect == 3) {
        document.getElementById("col3").querySelector("i").style.color =
          "#6747B3";
        document.getElementById("col3").style.background = "#e1daf0";
        document.getElementById("col3").className =
          "animate__animated animate__fadeIn";
        document.getElementById("col3").style.height = "24px";
        document.getElementById("col3").querySelector("tr").style.fontSize =
          "12px";
        document.getElementById("col3").querySelector("tr").style.lineHeight =
          "15px";
      }
      if (this.lineSelect == 4) {
        document.getElementById("col4").style.background = "#ededed";
        document.getElementById("col4").className = "";
        document.getElementById("col4").querySelector("i").style.color =
          "#6747B3";
        document.getElementById("col4").style.height = "24px";

        document.getElementById("col4").querySelector("tr").style.fontSize =
          "12px";
        document.getElementById("col4").className =
          "animate__animated animate__fadeIn";
        document.getElementById("col4").querySelector("tr").style.lineHeight =
          "15px";
      }

      var workbook = XLSX.read(this.data, { type: "array" });
      let sheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[sheetName];
      let anyStringlinha = workbook.Sheets[sheetName]["!ref"].substring(
        workbook.Sheets[sheetName]["!ref"].length - 5
      );

      this.workbook.Sheets[this.sheetName]["!ref"] =
        "A" +
        this.lineSelect +
        ":" +
        anyStringlinha.substring(anyStringlinha.indexOf(":") + 1);

      let jsonData = await XLSX.utils.sheet_to_json(this.worksheet);
      var json = jsonData;
      var columnnames = [];
      if (json.length > 0) {
        for (let i = 0; i < json.length; i++) {
          Object.keys(json[i]).forEach((key, obj) => {
            if (key.indexOf("_EMPTY") == -1 && !columnnames.includes(key)) {
              columnnames.push(key);
            }
          });
        }
      }
      var excel = {
        file: json,
        filePreview: [json[0], json[1], json[2], json[3], json[4]],
        columnnames: columnnames,
        nameFile: this.excelJson.nameFile
      };
      this.columnnames = Object.assign([], columnnames);
      this.excelJson = excel;
      localStorage.setItem("ExcelPayload", JSON.stringify(excel));
    },
    importExcel(event) {
      var self = this;
      var reader = new FileReader();
      reader.onload = async function(e) {
        self.data = new Uint8Array(e.target.result);
        self.workbook = XLSX.read(self.data, { type: "array" });
        self.sheetName = self.workbook.SheetNames[0];
        self.worksheet = self.workbook.Sheets[self.sheetName];

        for (var line = 1; line <= 4; line++) {
          let anyStringlinha = self.workbook.Sheets[self.sheetName][
            "!ref"
          ].substring(self.workbook.Sheets[self.sheetName]["!ref"].length - 5);
          self.workbook.Sheets[self.sheetName]["!ref"] =
            "A" +
            line +
            ":" +
            anyStringlinha.substring(anyStringlinha.indexOf(":") + 1);

          let jsonData = await XLSX.utils.sheet_to_json(self.worksheet);
          var json = jsonData;

          switch (line) {
            case 1:
              var col1 = [];
              if (json.length > 0) {
                Object.keys(json[0]).forEach((key, obj) => {
                  if (key.indexOf("_EMPTY") == -1 && !col1.includes(key)) {
                    col1.push(key);
                  }
                });
              }

            case 2:
              var col2 = [];

              if (json.length > 0) {
                Object.keys(json[0]).forEach((key, obj) => {
                  if (key.indexOf("_EMPTY") == -1 && !col2.includes(key)) {
                    col2.push(key);
                  }
                });
              }
            case 3:
              var col3 = [];

              if (json.length > 0) {
                Object.keys(json[0]).forEach((key, obj) => {
                  if (key.indexOf("_EMPTY") == -1 && !col3.includes(key)) {
                    col3.push(key);
                  }
                });
              }

            case 4:
              var col4 = [];

              if (json.length > 0) {
                Object.keys(json[0]).forEach((key, obj) => {
                  if (key.indexOf("_EMPTY") == -1 && !col4.includes(key)) {
                    col4.push(key);
                  }
                });
              }
              var excel = {
                col1: col1.slice(0, 6),
                col2: col2.slice(0, 6),
                col3: col3.slice(0, 6),
                col4: col4.slice(0, 6)
              };

              self.excelJsonSelect = excel;
          }
        }

        let anyStringlinha = self.workbook.Sheets[self.sheetName][
          "!ref"
        ].substring(self.workbook.Sheets[self.sheetName]["!ref"].length - 5);
        self.workbook.Sheets[self.sheetName]["!ref"] =
          "A" +
          self.lineSelect +
          ":" +
          anyStringlinha.substring(anyStringlinha.indexOf(":") + 1);

        let jsonData = await XLSX.utils.sheet_to_json(self.worksheet);

        var json = jsonData;
        var columnnames = [];

        if (json.length > 0) {
          Object.keys(json[0]).forEach((key, obj) => {
            if (key.indexOf("_EMPTY") == -1 && !columnnames.includes(key)) {
              columnnames.push(key);
            }
          });
        }
        var excel = {
          file: json,
          filePreview: [json[0], json[1], json[2], json[3], json[4]],
          columnnames: columnnames,
          nameFile: event.target.files[0].name
        };
        self.columnnames = Object.assign([], columnnames);
        self.excelJson = excel;
        localStorage.setItem("ExcelPayload", JSON.stringify(excel));
      };
      reader.readAsArrayBuffer(event.target.files[0]);
      this.stepupload = 1;
      Swal.fire({
        showConfirmButton: false,
        timer: 1500,
        icon: "success",
        title: "Arquivo importado com sucesso!"
      });

      this.reverseColumnSelect();

      document.getElementById("col1").style.background = "#e1daf0";

      document.getElementById("col1").querySelector("i").style.color =
        "#6747B3";
      document.getElementById("col1").style.height = "24px";
      document.getElementById("col1").querySelector("tr").style.fontSize =
        "13px";
      document.getElementById("col1").querySelector("tr").style.lineHeight =
        "15px";
    },

    openModalCorrelation() {
      this.instances[1].open();
    },
    initModals() {
      var options = {
        edge: "right",
        draggable: true,
        inDuration: 250,
        outDuration: 200,
        onOpenStart: null,
        onOpenEnd: null,
        onCloseStart: null,
        onCloseEnd: null,
        preventScrolling: true
      };
      if (this.modalSelectExcel == null) {
        var elemsCorrelation = document.querySelectorAll(
          "#modalSaveCorrelation"
        );
        var elemsExcel = document.querySelectorAll("#modalSelectExcel");
        this.modalSaveCorrelation = M.Modal.init(elemsCorrelation, options);
        this.modalSelectExcel = M.Modal.init(elemsExcel, options);
      }
    },
    openModal() {
      this.modalSelectExcel[0].open();
    },
    uploadFile() {
      if (this.excelJson.columnnames.length == 0) {
        Swal.fire({
          timer: 1800,
          showConfirmButton: false,
          icon: "error",
          title: "Não foi possível localizar as colunas!"
        });
        return;
      }
      this.step = 1;
      this.stepupload = 0;
      this.getConfigs();
      this.modalSelectExcel[0].close();
    },
    clearData() {
      this.excelJson.columnnames.splice(0);
      this.excelJsonSelect.col1.splice(0);
      this.excelJsonSelect.col2.splice(0);
      this.excelJsonSelect.col3.splice(0);
      this.excelJsonSelect.col4.splice(0);
      document.getElementById("inputimportExcel").value = "";
      this.lineSelect = 1;
    },
    returninitial() {
      if (this.step > 0) {
        Swal.fire({
          title: "Deseja realmente sair?",
          text: "Todo processo será perdido!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#6747b3",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não"
        }).then(result => {
          if (result.isConfirmed) {
            localStorage.removeItem("ExcelPayload");
            this.step = 0;
            this.stepupload = 0;
            this.modalSelectExcel[0].close();
            this.lineSelect = 1;
            this.clearData();

            this.reverseColumnSelect();

            document.getElementById("col1").style.background = "#e1daf0";

            document.getElementById("col1").querySelector("i").style.color =
              "#e1daf0";
            document.getElementById("col1").style.height = "24px";
            document.getElementById("col1").querySelector("tr").style.fontSize =
              "13px";
            document
              .getElementById("col1")
              .querySelector("tr").style.lineHeight = "15px";
          }
        });
      } else {
        localStorage.removeItem("ExcelPayload");
        this.step = 0;
        this.stepupload = 0;
        this.lineSelect = 1;
        this.modalSelectExcel[0].close();
        this.clearData();

        this.reverseColumnSelect();

        document.getElementById("col1").style.background = "#e1daf0";

        document.getElementById("col1").querySelector("i").style.color =
          "#e1daf0";
        document.getElementById("col1").style.height = "24px";
        document.getElementById("col1").querySelector("tr").style.fontSize =
          "13px";
        document.getElementById("col1").querySelector("tr").style.lineHeight =
          "15px";
      }
    },
    verificationTypeFields(propertyValue, propertyHub, propertyName) {
      //Quando vier 0 indica que nao foi correlacionado
      // manualmente e sim do metodo selectCorrection
      if (propertyValue == 0) {
        for (let i = 0; i < this.excelJson.file.length; i++) {
          var propertyValue = this.excelJson.file[i][propertyName];
        }
      }

      switch (propertyHub) {
        case "OrderProduct.UnitaryValue":
          this.verificExistFields(propertyName);
        case "OrderProduct.Quantity":
        case "OrderProduct.Amount":
        case "OrderProductOption.Amount":
        case "OrderPayment.Amount":
          if (isNaN(propertyValue)) {
            this.funcFieldsObrigatory(propertyHub);
          }
      }

      if (propertyHub != null) {
        var nameObject = propertyHub.substring(0, propertyHub.lastIndexOf("."));
        var propertiesSearch = eval("this.propertiesHub." + nameObject);
        for (let i = 0; i < propertiesSearch.length; i++) {
          var propertytypehub = propertiesSearch[i].type;
          var propertyhubname = propertiesSearch[i].name;
          if (
            typeof propertyValue !== propertytypehub &&
            propertyhubname == propertyHub
          ) {
            if (
              typeof propertyValue != propertytypehub &&
              !this.propfields.columnnames.includes(propertyName)
            ) {
              if (
                typeof propertyValue == "number" &&
                propertytypehub != "decimal" &&
                propertytypehub != undefined
              ) {
                this.propfields.columnnames.push(propertyName);
                this.updateTypeFields(propertyName);
              }
            }
          }
        }
      }
    },
    updateTypeFields(propertyName) {
      for (let i = 0; i < this.excelJson.file.length; i++) {
        if (this.excelJson.file[i][propertyName]) {
          this.excelJson.file[i][propertyName] = this.excelJson.file[i][
            propertyName
          ].toString();
        }
      }
    },
    verificExistFields(propertyName) {
      for (let i = 0; i < this.excelJson.file.length; i++) {
        if (!this.excelJson.file[i][propertyName]) {
          this.fieldsblock = true;
        }
      }
    },
    emitStep() {
      window.Emitter.emit("stepconvert", this.step);
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Zee - Conversor";
      }
    }
  }
};
</script>

<style scoped>
.search-i {
  margin-top: 12px;
}
.modal-footer {
  text-align: center !important;
}
.modal .modal-footer {
  height: 100px !important;
}
.modal {
  max-height: 100% !important;
}
h4,
h5 {
  color: #666 !important;
}
.col.s10.module-excel {
  height: 90vh;
  overflow-x: auto;
  padding: 0px !important;
  margin: 0px !important;
}
.col.s10.module-rows {
  overflow-x: auto;
  padding: 0px !important;
  margin: 0px !important;
  /* max-height: 90vh; */
  /* width: 75% !important; */
}
.preview-line {
  padding: 0;
  margin: 0px;
}
.input-field {
  margin: 0px !important;
  padding: 0px !important;
}
h4 {
  font-size: 24px;
  line-height: 28px;
  padding-right: 410px;
}
h5 {
  color: #5e5e5e;
  font-size: 14px;
  font-weight: 700 !important;
}
.modal .modal-content {
  margin: 0px;
  padding: 10px 50px !important;
}
.importrowexcel {
  margin-bottom: 0px !important;
}
.input-field .disabled {
  color: rgb(202, 202, 202) !important;
}
.input-field.prefix.active {
  color: #6747b3 !important;
}
.icon-colunas i {
  font-size: 35px;
  color: #6747b3;
  margin-left: -15px;
}
.icon-colunas span {
  color: #6747b3;
  margin-left: -11px;
  font-size: 14px;
  font-weight: bold;
  color: #6747b3;
}
.active {
  color: #6747b3;
}
.icon-colunas {
  color: #6747b3;
  text-align: center;
}
.title i {
  font-size: 35px;
  vertical-align: middle;
}
.title {
  margin: 0;
  font-size: 24px;
  color: rgb(131, 130, 130);
  font-weight: 500;
}
.row {
  padding: 0px;
}
.unSelectCorrection {
  cursor: pointer;
}
#modalSaveCorrelation {
  padding: 10px;
  position: absolute;
  width: 524px;
  height: 347px;
  border-radius: 15px;
}
#modalSelectExcel {
  width: 738px;
  height: 510px !important;
  border-radius: 15px;
}
.correlation {
  background-color: white;
  padding: 5px;
}
.correlation span {
  margin-right: 10px;
}
.correlation i {
  color: #6747b3;
  background-color: #fff;
  cursor: pointer;
}
.btn-save {
  background: #6747b3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.btn-cancel {
  right: 10px;
  color: #6747b3;
}
.names-btns {
  font-size: 14px;
}
.activeDrag {
  border: 1px solid #6747b3 !important;
}
.fileName {
  width: 300px !important;
  display: flex;
  align-items: center;
}
.scroll {
  overflow: auto;
  height: 230px;
}
thead {
  font-weight: 700;
}
tr {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12);
}
tr td {
  border: 0px solid rgb(238, 238, 238) !important;

  padding: 5px;
  white-space: nowrap;

  border-left-color: rgb(255, 255, 255) !important;
  border-left-width: 2px !important;
}
hr {
  border-color: rgb(226, 226, 226) !important;
}
.property-excel {
  background-color: rgb(243, 243, 243);
}
.filter i {
  color: #6747b3 !important;
}
h5 {
  font-weight: 400;
  color: rgb(131, 131, 131);
}
h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 16px;
  color: #606060;
}
.property {
  padding: 10px 5px;
  width: 84%;
  background-color: #f8f8f8;
  color: rgb(99, 99, 99);
  margin-top: 3px;
  margin-bottom: 0px;
  font-size: 14px !important;
}
.head-property {
  padding: 0.3px;
  width: 100%;
  background-color: #f8f8f8;
}
.option-file-correlation {
  border-bottom: 1px solid rgb(241, 241, 241);
  display: flex;
  align-items: center;
}
.p2 {
  padding: 0px 17px !important;
}
.mb2 {
  margin-top: 20px !important;
}
.img-file {
  margin-top: 5vh;
  margin-bottom: 10vh;
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
}
.file-path-wrapper i {
  margin: 0px;
  padding: 0px;
  color: #6747b3 !important;
}
.option-file-correlation {
  margin-top: 15px;
}
.chip {
  margin-top: 5px;
  cursor: pointer;
}
.chip i:first {
  margin-top: 20px !important;
}
.input-field i {
  color: #6747b3 !important;
  cursor: pointer;
}
.columns {
  height: 76px;
}
.divcolimport {
  color: #5e5e5e !important;
}
h4 {
  color: #5e5e5e;
  padding-top: -20px;
}
.row .col.s3 {
  left: 110px;
}
.titlesave {
  font-size: 24px;
  line-height: 28px;
  color: #606060;
}
.titlesave p {
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: #606060;
}
.imputsavecorrelation {
  padding-top: 60px;
  align-items: center;
  padding-left: 11px;
}
.modal-footersavecorrelation {
  padding-top: 45px;
}
.btnssavecorrelation {
  padding-left: 180px;
}
.btnssavecorrelation.btn {
  color: #5e5e5e;
}
.titlerowupload {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.modal .modal-footer {
  padding-top: 95px;
  text-align: right !important;
}
.nameconvert {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  width: 100%;
  height: 54px;
}
.textconvert {
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  color: #606060;
}
.property-align {
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.file-field.input-field {
  width: 333px !important;
  height: 36px !important;
}
.col .row {
  margin-right: 0rem !important;
}
.row .col.s11 {
  margin-left: 10px !important;
}

.col .row {
  margin-left: -0rem;
  margin-right: -0rem;
}

#btn1 {
  margin: 0 8px;
  color: #6747b3;
  background: #fafafa;
}
#btn3 {
  margin: 0 8px;
  color: #6747b3;
  background: #fafafa;
}
#btnupload {
  width: 333px;
  height: 45px;
}
#first_name {
  border-bottom: 1px solid;
}
.animation {
  animation: treme 0.1s;
  animation-iteration-count: 3;
}
.file-field span {
  padding-top: 6px !important;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@keyframes treme {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 5px;
  }
  50% {
    margin-left: 0;
  }
  75% {
    margin-left: -5px;
  }
  100% {
    margin-left: 0;
  }
}
</style>
