<template>
  <div>
    <Nav></Nav>
    <div class="container-page row">
      <div class="col s2 mt3" id="menu">
        <SideBar></SideBar>
      </div>

      <div>
        <div class="row menu">
          <span>Impressões</span>
        </div>
        <div style="border-bottom: 1px solid rgba(96, 96, 96, 0.3)"></div>

        <div
          class="row"
          style="
            margin-bottom: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: #f8f6fe;
            width: 70%;
            border-radius: 5px;
          "
        >
          <div class="col s4 l5 xl5">
            <span
              style="
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                color: #606060;
              "
              >Quer imprimir de forma
              <br />
              <span>automática no seu computador?</span>
              <br />
              <span style="font-size: 16px; color: #6747b3"
                >Instale nosso AssistenteLS de forma gratuita.
              </span>
            </span>
          </div>
          <div class="col s4 l5 xl4">
            <button
              onclick="location.href='https://www.zeeintegra.com.br/assistenteLS.exe'"
              class="btn-charge"
              style="width: 96px; height: 47px"
            >
              <Arrow />Baixar
            </button>
          </div>
          <div class="col s4 l5 xl4">
            <Imp />
          </div>
        </div>
        <div
          class="col s10 over p2"
          style="height: 88vh; overflow: auto"
          id="colgeral"
        >
          <div class="progress" v-if="loading">
            <div class="indeterminate"></div>
          </div>
          <table class="highlight">
            <thead>
              <tr>
                <td>Nome</td>
                <td>Tipo</td>
                <td>Data envio</td>
                <td>Status de impressão</td>
                <td><center>Visualizar</center></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in filesReceived" :key="index">
                <td>
                  <div class="file-icon" data-type="pdf"></div>
                </td>
                <td>
                  {{ item.mensagem }}
                </td>
                <td>{{ format(item.datainserido) }}</td>
                <td>
                  <span
                    class="new badge"
                    data-badge-caption=""
                    v-if="item.status == 0"
                    >Não realizada</span
                  >
                  <span
                    class="new badge"
                    data-badge-caption=""
                    v-if="item.status == 1"
                    >Realizada</span
                  >
                </td>
                <td class="center">
                  <i
                    @click="openBase64NewTab(item.value)"
                    class="fas fa-eye"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../components/Nav";
import SideBar from "../components/SideBar.vue";
import Service from "../service/Index.js";
import Imp from "../assets/files/tutorial_impressao.vue";
import Arrow from "../assets/files/arrow_button.vue";
import { controlMenu, mountedMenu } from "../factory/CenterControlMenu.js";

var moment = require("moment");

export default {
  name: "Files",
  components: {
    Nav,
    SideBar,
    Imp,
    Arrow
  },
  data: () => {
    return {
      filesReceived: [],
      loading: false
    };
  },
  created() {
    this.getFiles();
    document.title = "Zee - Impressões";
  },

  mounted() {
    mountedMenu();
  },
  updated() {
    controlMenu();
  },
  methods: {
    expired(data, time) {
      if (data === undefined) {
        return "";
      }
      const dateNow = new Date();
      const dateFile = moment(data, "YYYYMMDD HH:mm:ss").add(time, "hours");
      console.log(dateFile);

      if (dateFile <= dateNow) {
        return false;
      }
      return dateFile.format("DD/MM/YYYY hh:mm A");
    },
    format(date) {
      return moment(date).format("DD/MM/YYYY, HH:mm:ss");
    },
    async getFiles() {
      this.loading = true;
      this.filesReceived = [];
      let session = JSON.parse(localStorage.getItem("session"));
      let company = await Service.Company.get(session.companies[0].companyId);
      let result = await Service.Print.get(company.data.CnpjCpf, session.email);

      if (result.status == 200) {
        result.data = result.data.reverse();
        for (let index = 0; index < result.data.length; index++) {
          result.data[index].select = false;
        }
        this.filesReceived = result.data;
        this.filesReceived = this.filesReceived.sort((a, b) => {
          let da = new Date(a.datainserido),
            db = new Date(b.datainserido);
          return da - db;
        });
        this.filesReceived = this.filesReceived.reverse();
      }
      this.loading = false;
    },
    openBase64NewTab(base64Pdf) {
      var blob = this.base64toBlob(base64Pdf);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      }
    },
    base64toBlob(base64Data) {
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: "application/pdf" });
    },
    async downloadFile(id) {
      this.loading = true;
      let result = await Service.File.getById(id);

      if (result.status == 200)
        this.decodeRequest(
          result.data.extensao,
          result.data.arquivo,
          result.data.mensagem.trim().replaceAll(" ", "-")
        );
      this.loading = false;
    },
    decodeRequest(extensao, textToDecode, name) {
      if (extensao == "application/excel") {
        window.location.href =
          "data:application/vnd.ms-excel;base64, " + textToDecode;
        this.loading = false;
        return;
      }

      var decodedString = atob(textToDecode);

      var fileName = name;
      var fileType = ".xml";
      if (extensao == "text/plain") {
        fileType = ".txt";
      }

      var blob = new Blob([decodedString], { type: fileType });

      var a = document.createElement("a");
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() {
        URL.revokeObjectURL(a.href);
      }, 1500);
    }
  }
};
</script>
<style scoped>
.badge {
  background-color: #732792 !important;
}
.progress {
  background-color: #d9c4de !important;
}
.progress .indeterminate {
  background-color: #732792 !important;
}
thead tr td {
  font-weight: bold;
  font-size: 14px;
  color: #6747b3 !important;
}
td i {
  padding: 10px;
  font-size: 23px;
  color: #6f29a8;
  cursor: pointer;
}
/* .p2 {
  padding: 20px !important;
} */
tr td {
  padding-left: 20px;
}
.menu {
  height: 48px;
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: 14px;
  color: #606060;
  padding-left: 24px;
  margin-bottom: 0px !important;
}
</style>
