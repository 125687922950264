<template>
    <svg id="iconcorrela" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M12.2603 0.201527L15.7604 3.45046C16.0796 3.74687 16.0801 4.25284 15.7604 4.54965L12.2603 7.79887C11.7822 8.24281 11 7.90437 11 7.24928L11 5.25006L2.5 5.25006L2.5 7.87431C2.5 7.92355 2.49031 7.97232 2.47146 8.01782C2.45261 8.06331 2.42498 8.10465 2.39016 8.13947L0.640156 9.88947C0.403938 10.1257 -1.82094e-09 9.9584 -1.64246e-08 9.62431L-2.84124e-07 3.50006C-3.0223e-07 3.08584 0.335781 2.75006 0.75 2.75006L11 2.75006L11 0.751121C11 0.0986835 11.7803 -0.244223 12.2603 0.201527Z" fill="#6747B3"/>
        </g>
        <g clip-path="url(#clip1)">
        <path d="M11.7397 23.7985L8.23956 20.5495C7.92037 20.2531 7.91991 19.7472 8.23956 19.4503L11.7397 16.2011C12.2178 15.7572 13 16.0956 13 16.7507L13 18.7499L21.5 18.7499L21.5 16.1257C21.5 16.0764 21.5097 16.0277 21.5285 15.9822C21.5474 15.9367 21.575 15.8954 21.6098 15.8605L23.3598 14.1105C23.5961 13.8743 24 14.0416 24 14.3757L24 20.4999C24 20.9142 23.6642 21.2499 23.25 21.2499L13 21.2499L13 23.2489C13 23.9013 12.2197 24.2442 11.7397 23.7985Z" fill="#6747B3"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="10" height="16" fill="white" transform="translate(0 10) rotate(-90)"/>
        </clipPath>
        <clipPath id="clip1">
        <rect width="10" height="16" fill="white" transform="translate(24 14) rotate(90)"/>
        </clipPath>
        </defs>
    </svg>
</template>