<template>
  <!-- eslint-disable -->
  <div v-if="loading" class="loading">
    <div class="container center">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
    <h2>Carregando Orçamento...</h2>
  </div>
  <!-- Loader -->
  <div v-else class="wrapper" id="scroll">
    <div class="header-gradient"></div>
    <div class="content">
      <h1>Orçamento Digital</h1>

      <div class="header">
        <p>
          Número do orçamento: <strong>{{ order.referenceId }}</strong>
        </p>
        <p>
          Validade do orçamento:
          <strong>{{
            new Date(order.dateExpirationLink).toLocaleDateString()
          }}</strong>
        </p>

        <div class="info">
          <div>
            <span>{{ dateExpirationLinkFormat }}</span>
            <span>{{ dateExpirationLinkHourFormat }}</span>
          </div>

          <span v-if="approved">status: <strong>APROVADO</strong></span>
          <span v-else
            >status: <strong style="color: red">PENDENTE</strong></span
          >
        </div>
      </div>
      <div class="container-row">
        <div>
          <h2>Vendedor</h2>
          <div class="row">
            <div class="wrapper-input col s9">
              <span>Nome do Vendedor</span>
              <p>{{ order.nameSeller }}</p>
            </div>
          </div>
          <!-- <div class="row">
            <div class="wrapper-input col s9">
              <span>E-mail</span>
              <p>{{ order.emailSaller }}</p>
            </div>
          </div> -->
          <div class="row">
            <div class="wrapper-input col s3">
              <span>Data do Pedido</span>
              <p style="text-align: right">
                {{ new Date(order.dateCreated).toLocaleDateString() }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-input col s3">
              <span>Contato</span>
              <p style="text-align: right">{{ order.phoneSeller }}</p>
            </div>
          </div>
          <div class="row">
            <a target="_blank" :href="sendWths" class="whatsapp-button col s5 ">
              <i class="fab fa-whatsapp"></i>
              CONVERSAR COM VENDEDOR
            </a>
          </div>
        </div>
        <div>
          <h2>Cliente</h2>
          <div class="row">
            <div class="wrapper-input col s10">
              <span>Nome do Cliente</span>
              <p>{{ client.name }}</p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-input col s6">
              <span>E-mail</span>
              <p>{{ client.email }}</p>
            </div>
            <div class="col s1"></div>
            <div class="wrapper-input col s3">
              <span>Contato</span>
              <p style="text-align: right">{{ client.cellPhone }}</p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-input col s6">
              <span>Endereço</span>
              <p>{{ client.clientCustomer[0].address }}</p>
            </div>
            <div class="col s1"></div>
            <div class="wrapper-input col s2">
              <span>Número</span>
              <p>{{ client.clientCustomer[0].number }}</p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-input col s6">
              <span>Bairro</span>
              <p>{{ client.clientCustomer[0].complement }}</p>
            </div>
            <div class="col s1"></div>
            <div class="wrapper-input col s3">
              <span>CEP</span>
              <p>{{ client.clientCustomer[0].zipCode }}</p>
            </div>
          </div>
          <div class="row">
            <div class="wrapper-input col s6">
              <span>Cidade</span>
              <p>{{ client.clientCustomer[0].city }}</p>
            </div>
            <div class="col s1"></div>
            <div class="wrapper-input col s1">
              <span>UF</span>
              <p>{{ client.clientCustomer[0].state }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-row">
        <div>
          <h2>Produtos</h2>
          <table class="striped">
            <thead>
              <tr>
                <th>Código</th>
                <th>Produtos</th>
                <th>Quantidade</th>
                <th>Valor</th>
                <th>Desconto</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index) in order.orderProduct" :key="index">
                <td style="padding-left: 10px">{{ product.referenceId }}</td>
                <td>
                  {{ product.name }}
                </td>
                <td>{{ String(product.quantity).padStart(2, "0") }}</td>
                <td>{{ formatMoney(product.unitaryValue) }}</td>
                <td>
                  {{
                    formatMoney(
                      product.quantity * product.unitaryValue - product.amount
                    )
                  }}
                </td>
                <td>
                  {{ formatMoney(product.amount) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container-row">
        <div>
          <h2>Faturamento</h2>
          <div class="row ">
            <div class="col s4"></div>
            <div class="wrapper-input col s2">
              <span>Sub-total</span>
              <p style="text-align: right">{{ formatMoney(subTotal) }}</p>
            </div>
            <div class="col s1"></div>
            <div class="wrapper-input col s2">
              <span>Total de Desctonto</span>
              <p style="text-align: right">{{ formatMoney(totalDesconto) }}</p>
            </div>
            <div class="col s1"></div>
            <div class="wrapper-input col s2">
              <span>Valor Total</span>
              <p style="text-align: right; font-weight: 700">
                {{ formatMoney(total) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-row" style="margin-top: 24px; padding-bottom: 24px">
        <div>
          <p>Descrição</p>
          <textarea
            v-model="order.observation"
            disabled
            name=""
            id=""
            cols="30"
            rows="30"
            class="text-area"
          ></textarea>
        </div>
        <div style="flex: .1"></div>
        <div>
          <p>Observação do cliente</p>
          <textarea
            v-model="obsClient"
            :disabled="approved"
            name=""
            id=""
            cols="30"
            rows="60"
            class="text-area"
            style="padding: 10px"
          ></textarea>
        </div>
      </div>
      <div class="normal-row">
        <button
          v-if="!approved"
          @click="confirmChangeOrder()"
          class="approved-button"
          :disabled="loadingButton"
        >
          <div v-if="loadingButton" class="preloader-wrapper small active">
            <div class="spinner-layer">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="gap-patch">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
          <span v-else>Aprovar Orçamento</span>
        </button>
        <span v-if="!approved" style="margin-top: 10px"
          >Esse link expira em 10 dias</span
        >
      </div>
    </div>
    <div class="footer">
      <div>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/londrisoft/mycompany/"
        >
          <Linkedin />
        </a>
        <a target="_blank" href="https://pt-br.facebook.com/londrisoft">
          <Facebook />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q"
        >
          <Youtube />
        </a>
        <a target="_blank" href="https://www.instagram.com/londrisoft/?hl=pt">
          <Instagram />
        </a>
      </div>

      <img :src="logoFooter" alt="" />
      <div>
        <a target="_blank" href="https://londrisoft.com.br/sobre/">Sobre nós</a>
        <a target="_blank" href="https://londrisoft.com.br/contato/">Contato</a>
        <a target="_blank" href="https://londrisoft.movidesk.com/kb/pt-br"
          >Ajuda</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../service/Index.js";
import Illustration from "../assets/estimate/illustration.vue";
import LondriFooter from "../assets/estimate/londrifooter.vue";
import Facebook from "../assets/estimate/facebook.vue";
import Instagram from "../assets/estimate/instagram.vue";
import Youtube from "../assets/estimate/youtube.vue";
import Linkedin from "../assets/estimate/linkedin.vue";
import logoFooter from "../assets/estimate/logo_londrisoft.png";
import londriIcon from "../assets/estimate/londri.png";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export default {
  components: {
    Illustration,
    LondriFooter,
    Facebook,
    Instagram,
    Linkedin,
    Youtube
  },
  name: "Estimate",
  data: () => {
    return {
      loading: false,
      loadingButton: false,
      image: londriIcon,
      logoFooter: logoFooter,
      client: {},
      company: {},
      order: {},
      sendWths: "",
      obsClient: "",
      total: 0,
      subTotal: 0,
      totalDesconto: 0,
      approved: false,
      dateExpirationLinkFormat: "",
      dateExpirationLinkHourFormat: "",
      differenceInDays: 0
    };
  },
  beforeMount() {
    const hash = this.$route.params.hash;
    this.getContentByHash(hash);
    this.loading = true;
  },
  methods: {
    async getContentByHash(hash) {
      try {
        const { data: order } = await Service.Estimate.getByHash(hash);
        this.order = order[0];

        const { data: client } = await Service.Estimate.getClient(
          order[0].clientId
        );

        this.client = client[0];
        const { data: company } = await Service.Estimate.getCompany(
          order[0].companyId
        );
        this.company = company;
        this.setTotal(order[0].orderProduct);

        this.sendWths = `https://wa.me/55${this.order.phoneSeller}?text=Olá, ${this.order.nameSeller}, estou entrando em contato pelo orçamento ${this.order.referenceId}`;

        (this.dateExpirationLinkFormat = format(
          parseISO(this.order.dateCreated),
          "d MMM yyyy",
          {
            locale: ptBR
          }
        )),
          setTimeout(() => {
            this.loading = false;
            this.approved = this.order.status === 1 ? true : false;
            this.obsClient =
              this.order.status === 1 ? this.order.obsClient : "";
          }, 1000);
      } catch (error) {
        console.log("Error: ", error.message);
      }
    },
    confirmChangeOrder() {
      Swal.fire({
        title: "Tem certeza?",
        text: "Você não podera mais interagir após a aprovação.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6747b3",
        cancelButtonColor: "#d33",
        confirmButtonText: "SIM",
        cancelButtonText: "NÃO"
      }).then(result => {
        if (result.isConfirmed) {
          this.changeOrder();
        }
      });
    },
    async changeOrder() {
      this.loadingButton = true;
      try {
        this.order.obsClient = this.obsClient;
        this.order.status = 1;
        const authorization = await Service.Estimate.getAuthorization(
          this.company.CnpjCpf
        );

        await Service.Estimate.changeOrder(
          this.order.orderId,
          this.order,
          authorization.data.Token
        );
        this.loadingButton = false;
        Swal.fire({
          title: "Orçamento Aprovado!",
          text: "Aguarde, o vendedor entra em contato com mais informaçoes.",
          icon: "success",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          timer: 3500,
          timerProgressBar: true
        }).then(res => {
          this.approved = !res.isConfirmed;
          document
            .getElementById("scroll")
            .scroll({ top: 50, behavior: "smooth" });
        });
      } catch (error) {
        console.log(error.message);
        this.loadingButton = false;
      }
    },
    formatMoney(value) {
      return new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL"
      }).format(value);
    },
    setTotal(prod) {
      const sum = prod.reduce(
        (acc, item) => {
          acc.totalDesconto += item.quantity * item.unitaryValue - item.amount;

          acc.subTotal += item.quantity * item.unitaryValue;

          acc.total = acc.subTotal - acc.totalDesconto;
          return acc;
        },
        {
          total: 0,
          totalDesconto: 0,
          subTotal: 0
        }
      );

      this.total = sum.total;
      this.subTotal = sum.subTotal;
      this.totalDesconto = sum.totalDesconto;
    }
  }
};
</script>

<style scoped>
h1,
p,
span {
  color: #303030;
  margin: 0;
}
.rol .col {
  padding: 0;
}
.wrapper {
  height: 100vh;
  overflow-y: auto;
}

.header {
  padding: 24px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(96, 96, 96, 0.3);
}
.header p {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
}
.header p strong {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-left: 5px;
  color: #6747b3;
}

.header p + p {
  margin-left: 48px;
}

.header .info {
  margin-left: auto;
  display: flex;
}

.header .info span {
  margin-top: auto;
}

.header .info span strong {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;

  color: #118f2d;
}

.header .info div {
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid #606060;
}

.header .info div span {
  display: block;
  text-align: right;
}

.content {
  max-width: 1188px;
  width: 100%;
  margin: 0 auto;
}
.content h1 {
  font-size: 26px;
  font-weight: 700;
  color: #6747b3;
  text-transform: uppercase;
  margin: 24px 0;
  text-align: center;
}

.wrapper .container-row {
  display: flex;
  border-bottom: 1px solid rgba(96, 96, 96, 0.3);
}
.wrapper .container-row div {
  flex: 1;
}
.wrapper .container-row div h2 {
  font-size: 20px;
  color: #606060;
  font-weight: 700;
}
.wrapper .container-row .wrapper-input {
  background: #f8f6fe;
  height: 56px;
  border-bottom: 1px solid rgba(96, 96, 96, 0.7);
}
.wrapper .container-row .wrapper-input span {
  color: #6747b3;
  font-size: 12px;
}
.wrapper .container-row .wrapper-input p {
  font-size: 16px;
  color: #606060;
  margin-top: 4px;
}
.wrapper-input {
  padding: 0;
}
.whatsapp-button {
  height: 54px;
  width: 282px;
  border-radius: 4px;
  border: 1px solid #7551cc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6747b3;
  font-weight: 700;
  font-size: 12px;
  padding: 0;
}

.whatsapp-button i {
  font-size: 24px;
  margin-right: 10px;
}

.footer {
  margin-top: 23px;
  height: 108px;
  display: flex;
  /* padding: 0 400px; */
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(89.81deg, #1d5ed6 -3.95%, #6747b3 104.23%);
}

.footer div {
  display: flex;
  align-items: center;
}

.footer img {
  width: 164.73px;
  height: 24px;
}

.footer div a {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 0;
}
.footer div a + a {
  margin-left: 70px;
}

.footer div a svg + a svg {
  margin-left: 40px;
}

.container-row div table th,
td {
  font-size: 14px;
  color: #606060;
}
.container-row div table th {
  color: #6747b3;
  padding: 0 0 17px 15px;
  font-weight: 700;
  position: relative;
}
.container-row div table th::after {
  content: "";
  width: 1px;
  height: 22px;
  left: 0px;
  position: absolute;
  background-color: rgba(96, 96, 96, 0.3);
}
.container-row div table th:first-child {
  padding: 0 0 17px 0px;
}

.container-row div table th:first-child::after {
  padding-left: 0;
  width: 0;
}
.container-row div table td {
  padding-left: 15px;
}
.container-row div table td:first-child {
  padding-left: 0;
  color: #606060;
  opacity: 0.7;
}
table.striped > tbody > tr:nth-child(odd) {
  background-color: #f8f6fe;
}

.text-area {
  height: 9rem;
  border-radius: 4px;
  border: 1px solid #606060;
  resize: none;
}

textarea:disabled {
  background-color: #f8f6fe;
  border: none;
}

.approved-button {
  width: 200px;
  height: 46px;
  padding: 15px 12px;
  background: #6747b3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 0;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.approved-button span {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  font-family: "Oxygen";
}

/* .approved-button:hover {
  background: linear-gradient(180deg, #432d7e 0%, #6747b3 100%);
} */

.approved-button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.approved-button:disabled:hover {
  background: linear-gradient(180deg, #6747b3 0%, #432d7e 100%);
}

.normal-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 39px;
  margin-bottom: 87px;
}
/* .normal-row span {
  margin-top: 10px;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;


  color: rgba(96, 96, 96, 0.7);
}
*/

.loading {
  margin-top: 160px;
}

.loading h2 {
  text-align: center;
  color: rgba(96, 96, 96, 0.7);
  font-size: 32px;
}

.approved-button .preloader-wrapper.active {
  height: 18px;
  width: 18px;
}
.approved-button .preloader-wrapper .spinner-layer {
  border-color: #fff;
}

.style-p p {
  font-weight: 700;
}

.header-gradient {
  height: 42px;
  background: linear-gradient(89.95deg, #1d5ed6 -1.87%, #6747b3 103.75%);
  box-shadow: 0px 4px 2px rgba(103, 71, 179, 0.1);
  margin-bottom: 60px;
}
</style>
