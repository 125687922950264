<template>
  <div>
    <Nav></Nav>

    <div class="container-page row">
      <div class="col" id="menu">
        <SideBar></SideBar>
      </div>
      <div
        class="col s10 module-integration"
        id="colgeral"
        v-if="configs.value != undefined"
      >
        <div class="row">
          <div class="nav-wrapper cursor fontsmenu">
            <div class="row" style="padding-top: 15px">
              <span class="breadcrumb" @click="navigation('Integracoes')"
                >Marketplaces</span
              >
              <span class="breadcrumb"> Mercado Livre</span>
            </div>
          </div>
          <div style="border: 1px solid rgba(96, 96, 96, 0.3)"></div>
        </div>

        <div class="row marketplace">
          <div class="row">
            <div class="col s12 content-marketplace">
              <div class="row">
                <div style="display: flex; flex-direction: row">
                  <div
                    class="card card-imagesecond"
                    style="margin-top: 20px; width: 50px"
                  >
                    <LogoMercadoLivre />
                  </div>
                  <div style="margin-left: 15px; margin-top: 2px">
                    <span style="font-size: 48px; font-weight: bold"
                      >Mercado Livre</span
                    >
                    <br />
                    <span
                      style="
                        font-size: 20px;
                        line-height: 25px;

                        color: #606060;
                      "
                      >Conecte seu produto com Mercado Livre o maior marketplace
                      da América Latina.</span
                    >
                  </div>

                  <div style="font-weight: bold; line-height: 25px">
                    <span v-bind:class="{ disable: !configs.value[0].value }">
                      {{
                        configs.value[0].value ? "Habilitado" : "Desabilitado"
                      }}
                    </span>
                    <div
                      class="switch"
                      @change="selectConfig(configs.value[0].value)"
                    >
                      <label>
                        <input
                          type="checkbox"
                          :checked="configs.value[0].value"
                          v-model="configs.value[0].value"
                          @change="changeStatus()"
                        />
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col s12 color-with-pseudo">
                  <p class="sub-title">Por que que integrar?</p>
                  <li class="text-subtitle">Automatizamos seu processo</li>
                  <li class="text-subtitle">Facilitamos seu trabalho</li>
                  <li class="text-subtitle">Integramos seu pedido</li>
                  <li class="text-subtitle">Economizamos seu tempo</li>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <p class="sub-title">Informações técnica</p>
                  <span class="text-subtitle"
                    >Sincronização automática a cada 15 minutos.</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <p class="sub-title">Como integrar?</p>
                  <span class="text-subtitle"
                    >Para realizar a integração da sua conta no Mercado Livre,
                    clique no botão
                  </span>
                  <strong style="font-weight: bold; color: #6747b3"
                    >“realizar acesso”</strong
                  ><span class="text-subtitle">e realize o login.</span>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <p class="sub-title">Como trocar de conta?</p>
                  <span class="text-subtitle"
                    >Para troca de conta no Mercado Livre, é
                  </span>
                  <span style="font-weight: bold"
                    >importante que você realize a troca de conta pelo próprio
                    site do </span
                  ><a
                    style="font-weight: bold"
                    href="https://www.mercadolivre.com.br/"
                    target="_blank"
                    >Mercado Livre</a
                  >
                  <span> e depois clique no botão “trocar de conta”.</span>
                </div>

                <div class="row">
                  <div class="col s10 center">
                    <span v-if="user.results.length != 0">
                      Conta vinculada:
                      <b>
                        {{ user.results[0].seller.first_name }}
                        {{ user.results[0].seller.last_name }} -
                        {{ user.results[0].seller.nickname }}</b
                      >
                    </span>
                    <br />
                    <div style="display: flex; justify-content: center">
                      <button
                        class="btn-charge"
                        style="height: 50px; width: 200px; margin: 24px"
                        :style="
                          user.query == undefined
                            ? 'opacity:1'
                            : 'opacity: 0.5 ;pointer-events:none;'
                        "
                        target="_blank"
                        @click="open(e)"
                      >
                        <span v-if="user.query != undefined"
                          >Conta Vinculada</span
                        >
                        <i
                          style="padding-left: 5px"
                          v-if="user.query != undefined"
                          class="far fa-check-circle"
                        ></i>
                        <span v-if="user.query == undefined"
                          >Carregar Conta</span
                        >
                      </button>

                      <button
                        class="btn-charge"
                        style="height: 50px; width: 200px; margin: 24px"
                        target="_blank"
                        @click="open(e)"
                        :style="
                          user.query != undefined
                            ? 'opacity:1'
                            : 'opacity: 0.5 ;pointer-events:none;'
                        "
                      >
                        Trocar de Conta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../components/Nav";
import SideBar from "../components/SideBar.vue";
import Service from "../service/Index.js";
import Iconspedido from "../assets/Integration/integration-icons-pedido.vue";
import Iconspedcarrinho from "../assets/Integration/integration-icons-pedcarrinhos.vue";
import { controlMenu, mountedMenu } from "../factory/CenterControlMenu.js";
import LogoMercadoLivre from "../assets/Integration/logo-mercadolivre.vue";

export default {
  name: "MercaoLivre",
  components: {
    Nav,
    SideBar,
    Iconspedido,
    Iconspedcarrinho,
    LogoMercadoLivre
  },
  data: () => {
    return {
      step: 0,
      moduleSelect: null,
      configs: {},
      user: { results: [] },
      menushow: true
    };
  },
  mounted() {
    this.getConfigs();

    mountedMenu();
  },
  updated() {
    this.controlMenu();
  },
  methods: {
    menurecalldiv() {
      // document.getElementById("menu-arrow-show").style.display = "none";

      if (localStorage.getItem("menurecall") == 0) {
        window.Emitter.emit("functionreduce", "reducemousenter");
      }
    },
    controlMenu() {
      controlMenu();
    },
    async getUser(token) {
      var session = JSON.parse(window.localStorage.getItem("session"));
      let result = await Service.MercadoLivre.get(
        session.companies[0].companyId,
        token
      );
      if (result.status == 200) {
        this.user = result.data;
      }
    },
    changeStatus() {
      let config = JSON.parse(JSON.stringify(this.configs));
      config.value = btoa(JSON.stringify(config.value));
      this.putConfig(config);
    },
    findConfig(configModule, name) {
      return configModule.filter(value => {
        return value.name == name;
      })[0];
    },
    selectConfig(config) {
      this.changeStatus();
      if (config) {
        M.toast({ html: "Integração com o Mercado Livre foi Habilitada." });
      } else {
        M.toast({ html: "Integração com o Mercado Livre foi desabilitada." });
      }
    },
    selectSession(config) {
      // if(config){
      this.step = 1;
      this.moduleSelect = { name: "Mercado Livre", version: "1.1" };
      // }
    },
    async getConfigs() {
      let self = this;
      let result = await Service.Configuration.get(
        JSON.parse(localStorage.getItem("session")).companies[0].companyId
      );
      if (result.status == 200) {
        this.configs = result.data.filter(value => {
          return value.key.indexOf("modules") != -1;
        });
        if (this.configs.length == 0) {
          let session = JSON.parse(localStorage.getItem("session"));
          let module = btoa(
            JSON.stringify([{ name: "mercadoLivre", value: false }])
          );
          await this.saveConfig(
            {
              companyId: session.companies[0].companyId,
              userId: session.userId,
              key: "hub-modules",
              value: module
            },
            ""
          );
          this.getConfigs();
        } else {
          this.configs[0].value = JSON.parse(atob(this.configs[0].value));
          this.configs = this.configs[0];
        }
        var tokenData = result.data.filter(value => {
          return value.key == "mercado-livre-token";
        });
        if (tokenData.length > 0) {
          this.getUser(tokenData[0].value);
        }
      }
    },
    async saveConfig(config, message) {
      let result = await Service.Configuration.post(config);
      if (result.status == 200) {
        if (message != "") M.toast({ html: message });
      }
    },
    async putConfig(config) {
      let result = await Service.Configuration.put(config);
      if (result.status == 200) {
        if (message != "") M.toast({ html: "atualizado" });
      }
    },
    navigation(to) {
      this.$router.push(to);
    },
    open(e) {
      window.open(
        "https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=233765220085463&redirect_uri=https://www.zeeintegra.com.br/MercadoLivreSaveCode"
      );
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Zee - Integrações";
      }
    }
  }
};
</script>
<style scoped>
.container-page {
  height: 90vh;
  overflow-x: auto;
  width: 100%;
}
.breadcrumb {
  font-size: 14px;
  margin-left: 0px !important;
  padding-left: 20px !important;
}
.breadcrumb:before {
  font-size: 15px;
}
.disable {
  opacity: 0.4;
}
.legend span {
  display: inline-block;
}
.legend .switch {
  display: inline-block;
}
.cursor {
  cursor: pointer;
}
.sub-title {
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid rgb(219, 219, 219);
  font-weight: bold;
  font-size: 20px;
  color: #303030;
}

.text-subtitle {
  font-size: 14px;
  line-height: 30px;
  color: #606060;
}
.logo {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.content-marketplace {
  margin-top: 23px;
  padding-left: 50px !important;
}
.card .card-image img {
  width: 90%;
  margin: auto;
  padding: 30px;
}

.card {
  width: 267px !important;
  height: 267px !important;
}

.card .card-content {
  padding: 0px;
  padding-top: 25px;
  padding-left: 15px;
  border-radius: 0 0 2px 2px;
}
.card-imagesecond {
  width: 260px !important;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
}

.nav-wrapper .col .breadcrumb:first-child {
  color: #666 !important;
  font-size: 14px;
}
.breadcrumb,
.breadcrumb:before {
  color: #6747b3;
  vertical-align: middle;
  margin-left: 10px;
}
.switch label input[type="checkbox"]:checked + .lever {
  background-color: #6747b365;
}
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #6747b3;
}
.card-content p {
  color: rgb(110, 110, 110);
  margin-top: 20px;
}
.title-market {
  color: #6747b3 !important;
  font-size: 18px;
  font-weight: bold;
}
.card-image {
  text-align: center;
  padding-top: 50px;
}
.marketplaces {
  padding: 30px !important;
}
.title {
  border-bottom: 1px solid rgb(231, 231, 231);
  padding: 10px !important;
}
.col .row {
  padding: 0px;
  margin: 0px;
}
.module-integration {
  padding: 25px;
  /* width: 83% !important; */
}
p {
  font-size: 14px;
  color: #606060;
  /* font-weight: bold; */
}

.fontsmenu {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  width: 100%;
  height: 54px;
}
/* .btn {
  height: 50px;
  width: 200px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin: 24px;
  background: linear-gradient(45deg, #432d7e, #6747b3);
  background-size: 1000% 1000%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1);
}
.btn:hover {
  animation: gradient 5s ease infinite;
} */
.color-with-pseudo {
  list-style: none;
  list-style-position: inside;
}
.color-with-pseudo li::before {
  content: "•";
  font-size: 180%;
  line-height: 0;
  margin: 0 0.9rem 0 -0rem;
  position: relative;
  color: #6747b3;
  top: 4px;
}
</style>
