<template>
  <div id="container-all" v-if="loading">
    <span v-if="!failLoading" style="font-size: 35px; color: #6747b3"
      >Aguarde estamos construindo seu arquivo.</span
    >
    <br />
    <div v-if="!failLoading" class="preloader-wrapper big active">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>

    <div v-else id="container-all">
      <span style="font-size: 35px; color: #6747b3"
        >Não foi possível disponibilizar o arquivo, favor consultar no
        zee!</span
      >

      <button @click="reloadWindow" class="btn reloadButton">
        Acessar o zeee
      </button>
    </div>
  </div>
</template>

<script>
import Service from "../service/Index.js";

export default {
  beforeMount() {
    this.loading = true;
    if (this.$route.params.hash !== undefined) {
      var hash = this.$route.params.hash;
      this.myHash = this.$route.params.hash;
      this.downloadFile(hash);
    }
  },
  created() {
    document.title = "Zee - Arquivos";
  },
  data: () => {
    return {
      loading: false,
      failLoading: false
    };
  },
  methods: {
    async viewPdf(id) {
      this.loading = false;
      let result = await Service.File.getById(id);
      if (result.status == 200) this.openBase64NewTab(result.data.arquivo, id);
      this.loading = false;
    },
    openBase64NewTab(base64Pdf, id) {
      try {
        var blob = this.base64toBlob(base64Pdf);

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl);
        }
      } catch (e) {
        window.open(
          "https://api.serverlondrisoft.com:8010/Relatorios/View/PDF/" + id
        );
      }
    },
    base64toBlob(base64Data) {
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: "application/pdf" });
    },
    async downloadFile(hash) {
      let res = await Service.File.getByHash(hash);
      if (res.status === 200) {
        this.decodeRequest(
          res.data.extensao,
          res.data.arquivo,
          res.data.mensagem.trim().replaceAll(" ", "-"),
          res.data.nome,
          res.data.id
        );
        return;
      }

      setTimeout(async () => {
        let res = await Service.File.getByHash(hash);
        if (res.status === 200) {
          this.decodeRequest(
            res.data.extensao,
            res.data.arquivo,
            res.data.mensagem.trim().replaceAll(" ", "-"),
            res.data.nome,
            res.data.id
          );
        } else {
          this.failLoading = true;
        }
      }, 10000);
    },

    decodeRequest(extensao, textToDecode, message, name, id) {
      var decodedString = atob(textToDecode);

      var fileName = message;

      if (name.indexOf(".REM") != -1 || name.indexOf(".CRM") != -1) {
        var fileName = name;
      }
      var fileType = extensao;

      if (fileType == "application/pdf") {
        this.viewPdf(id);
        return;
      }

      var blob = new Blob([decodedString], {
        type: "application/octet-stream"
      });

      if (fileType == "application/excel") {
        if (
          name.toUpperCase().indexOf("CONSIGNADO") != -1 ||
          message.toUpperCase().indexOf("CONSIGNADO")
        ) {
          var a = document.createElement("a");
          a.href = "data:application/vnd.ms-excel;base64," + textToDecode;
          a.download = name;
          a.click();
          return;
        }
        extensao = ".xls";
      } else if (name.indexOf(".REM") != -1 || name.indexOf(".CRM") != -1) {
        extensao = "";
      } else if (name.toUpperCase().indexOf(".XML") != -1) {
        extensao = ".xml";
      } else {
        extensao = ".txt";
      }

      var a = document.createElement("a");
      a.download = fileName + extensao;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() {
        URL.revokeObjectURL(a.href);
      }, 1500);
    },
    reloadWindow() {
      this.$router.push("/");
    }
  },
  name: "Documents"
};
</script>

<style scoped>
#container-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.reloadButton {
  margin-top: 20px;
}
</style>
