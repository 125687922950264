import Https from "./Config";

const base_url = process.env.VUE_APP_URL_API;
// process.env.NODE_ENV === "production"
//   ? process.env.VUE_APP_URL_API
//   : process.env.VUE_APP_LOCAL_URL;

console.log(base_url);
export default {
  getByHash: hash =>
    Https.createAxiosInstance(false, base_url, false).get(
      `Order/ContentByHash/${hash}`
    ),

  getClient: id =>
    Https.createAxiosInstance(false, base_url, false).get(`Client/${id}`),

  getCompany: id =>
    Https.createAxiosInstance(false, base_url, false, false, false, id).get(
      `Company/${id}`
    ),

  changeOrder: (id, order, auth) =>
    Https.createAxiosInstance(
      false,
      base_url,
      false,
      false,
      false,
      false,
      auth
    ).put(`Order/${id}`, order),

  getAuthorization: document =>
    Https.createAxiosInstance(false, base_url, false).get(
      `Company/getTokenByCnpjCpf/${document}`
    )
};
