<template>
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9283 6.77539L9.26038 4.66309C9.04584 4.53949 8.80261 4.47424 8.55505 4.47394C7.77258 4.47284 7.13745 5.10626 7.13635 5.88867V10.1113C7.1333 10.6168 7.40326 11.0847 7.84241 11.335C8.05817 11.4611 8.30347 11.5278 8.55334 11.5283C8.802 11.5272 9.04602 11.4612 9.26135 11.3369L12.9283 9.22461C13.1443 9.10028 13.3235 8.92102 13.4479 8.70508C13.8373 8.02875 13.6047 7.16479 12.9283 6.77539ZM12.4284 8.35742L8.76245 10.4697C8.63306 10.5457 8.47278 10.5457 8.34344 10.4697C8.21442 10.3968 8.13513 10.2595 8.13641 10.1113V5.88867C8.13513 5.74048 8.21442 5.60321 8.34344 5.53027C8.40808 5.49341 8.48096 5.47357 8.55536 5.47266C8.62799 5.4729 8.69916 5.4928 8.76135 5.53027L12.4284 7.64258C12.4929 7.6792 12.5462 7.73248 12.5828 7.79694C12.6949 7.99432 12.6259 8.24524 12.4284 8.35742ZM17.1364 0H3.13635C1.48022 0.0018311 0.138184 1.34387 0.136353 3V13C0.138184 14.6561 1.48022 15.9982 3.13635 16H17.1364C18.7925 15.9982 20.1345 14.6561 20.1364 13V3C20.1345 1.34387 18.7925 0.0018311 17.1364 0ZM19.1364 13C19.1349 14.104 18.2404 14.9986 17.1364 15H3.13635C2.03235 14.9986 1.13776 14.104 1.13635 13V3C1.13776 1.896 2.03235 1.0014 3.13635 1H17.1364C18.2404 1.0014 19.1349 1.896 19.1364 3V13Z"
      fill="white"
    />
  </svg>
</template>
