<template>
  <svg
    width="300"
    height="90"
    viewBox="0 0 401 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M285.502 60C257.502 40.8 277.336 24 289.002 16.5C313.002 1.07161 313.502 29 354.502 6C395.502 -17 398.002 42 400.502 63C403.002 84 366.502 69.5 344.002 84C321.502 98.5 320.502 84 285.502 60Z"
      fill="#6747B3"
      fill-opacity="0.2"
    />
    <path
      d="M384.503 74.9877H353.364H281.143V57.4557C281.143 44.1105 292.656 35.4754 303.123 35.4754H384.503V74.9877Z"
      fill="#23164C"
    />
    <path
      d="M288.469 55.6242C293.441 39.9239 302.861 35.4755 309.926 35.4755H365.924C357.341 35.4755 344.205 46.2039 344.205 55.624L337.402 55.6242H288.469Z"
      fill="#6747B3"
    />
    <path
      d="M288.469 55.6241C287.936 59.2482 288.469 74.7259 288.469 74.7259H294.226H304.431H322.748H338.187H344.467V55.8856C344.467 53.3735 344.729 50.6523 347.869 46.2039C330.686 46.1167 292.656 46.2039 292.656 46.2039C290.563 49.3441 289.003 52 288.469 55.6241Z"
      fill="#23164C"
    />
    <path
      d="M334.262 21.3452L320.393 35.4754H365.139L379.007 21.3452H334.262Z"
      fill="#23164C"
    />
    <path
      d="M364.877 35.4754L379.007 21.3452C381.101 23.4386 378.222 28.1487 377.96 28.4103L370.895 35.4754H364.877Z"
      fill="#6747B3"
    />
    <path
      d="M384.503 74.7261H352.317C352.317 74.7261 352.188 57.6843 353.002 53.5C353.817 49.3157 360.167 38.3537 371.157 35.4755H384.503V74.7261Z"
      fill="#6747B3"
    />
    <circle cx="348.13" cy="60.8575" r="1.57002" fill="#6747B3" />
    <circle cx="348.13" cy="67.1376" r="1.57002" fill="#6747B3" />
    <path
      d="M316.468 40.9706C319.347 37.8305 325.627 35.4755 325.627 35.4755H339.495C339.495 35.4755 333.477 38.0922 330.598 40.9706H316.468Z"
      fill="#23164C"
    />
    <path
      d="M285.329 70.801C285.538 74.9878 288.992 74.9878 289.778 74.9878H337.925V70.801H327.196H285.329Z"
      fill="#23164C"
    />
    <path
      d="M329.706 70.801H285.329C285.329 73.7038 287.494 74.8017 288.576 74.9878H332.953C330.788 74.9878 329.886 72.1966 329.706 70.801Z"
      fill="#6747B3"
    />
    <path
      d="M338.187 70.2408V70.801H294.75V70.2408C294.75 63.6134 300.122 58.2408 306.75 58.2408H326.187C332.814 58.2408 338.187 63.6134 338.187 70.2408Z"
      fill="#010101"
    />
    <path
      d="M372.727 8C375.313 15.0962 369.95 27.1911 358.074 35.7371H325.103C335.909 27.162 342.416 14.5286 339.757 8H372.727Z"
      fill="#FCF9CD"
    />
    <path
      d="M278.002 82.8379C294.749 78.1278 278.787 74.4644 299.721 68.7077H332.691C312.828 74.4935 331.121 80.2212 310.188 82.8379H278.002Z"
      fill="#FCF9CD"
    />
    <path
      opacity="0.7"
      d="M270.002 81.8303C191.668 84.8303 62.5024 86.4659 71.0016 31.8303C74.5017 9.33032 105.668 12.1636 105.002 31.8303C104.335 51.497 99.0002 80.3303 40.0002 74.5"
      stroke="#6747B3"
      stroke-linecap="round"
      stroke-dasharray="10 10"
    />
    <g opacity="0.7" clip-path="url(#clip0)">
      <path
        d="M1.9891 53.8401L33.1407 56.6662C34.3561 56.7744 34.8519 58.168 34.083 59.0126L29.5356 63.9854L8.08233 57.5929C7.67034 57.4678 7.4403 58.057 7.82945 58.2442L27.8086 67.9128L29.7822 72.1846C30.3608 73.4369 29.0764 74.6292 27.9142 74.065L22.6495 71.5198L17.3173 77.3446C16.7109 78.0112 15.633 77.9361 15.1199 77.1879L0.707984 56.0289C0.0323256 55.0403 0.825899 53.7335 1.9891 53.8401Z"
        fill="#6747B3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="29.929"
          height="29.929"
          fill="white"
          transform="matrix(-0.907796 0.419411 0.419411 0.907796 27.1694 42)"
        />
      </clipPath>
    </defs>
  </svg>
</template>