export const controlMenu = () => {

    if (localStorage.getItem("menufixed") == 1) {
        document.getElementById("menu").style.width = "16.6%";
        document.getElementById("colgeral").style.width = "83.3% ";
    }

    if (localStorage.getItem("menufixed") == 0) {
        document.getElementById("menu").style.width = "4.7%";
        document.getElementById("colgeral").style.width = "95.3%";
    }
}

 
export const mountedMenu = (menushow) => {
    
    var menushow = menushow
    window.Emitter.on("expandmenu", (value) => {
    menushow = value;
    document.getElementById("menu").style.width = "16.6%";
    document.getElementById("colgeral").style.width = "83.3% ";
    
});

window.Emitter.on("reducemenu", (value) => {
    menushow = value;
    document.getElementById("menu").style.width = "4.7%";
    if (document.getElementById("colgeral")) {
        document.getElementById("colgeral").style.width = "95.3%";
    }
    
});
}

export const menuRecallDiv = () => {
    if (localStorage.getItem("menufixed") == 0) {
        window.Emitter.emit("reducemenu", false);
        window.Emitter.emit("menureduce", false);
    }
}