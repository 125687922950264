<template>
  <div>
    <Nav></Nav>

    <div class="container-page row">
      <div class="col" id="menu">
        <SideBar></SideBar>
      </div>
      <div
        class="col s10 module-integration"
        id="colgeral"
        v-if="configs.value != undefined"
      >
        <div class="row">
          <div class="nav-wrapper">
            <div class="col s12 cursor fontsmenu">
              <div class="row" style="padding-top: 15px">
                <span class="breadcrumb">Marketplaces</span>
                <span v-if="step != 0" class="breadcrumb">
                  {{ moduleSelect.name }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div style="border: 1px solid rgba(96, 96, 96, 0.3)"></div>

        <div class="row" v-if="step == 0">
          <div class="row">
            <div class="col s12 m2 l3" style="margin-left: 20px">
              <div class="card cursor" @click="selectSession('MercadoLivre')">
                <div class="card-image">
                  <LogoMercadoLivre />
                </div>
                <div
                  class="card-content"
                  style="padding-bottom: 30px; position: relative"
                >
                  <div class="row" style="padding-top: 20px">
                    <div class="col s6 title-market">Mercado Livre</div>
                  </div>
                  <p>
                    Funcionalidade liberada sem custo por tempo determinado.
                  </p>
                </div>
              </div>
            </div>
            <div class="col s12 m2 l3">
              <div class="card cursor" @click="selectSession('LojaIntegrada')">
                <div class="card-image">
                  <LogoLojaIntegrada />
                </div>
                <div
                  class="card-content"
                  style="padding-bottom: 30px; position: relative"
                >
                  <div class="row" style="padding-top: 20px">
                    <div class="col s6 title-market">Loja Integrada</div>
                  </div>
                  <p>
                    Funcionalidade liberada sem custo por tempo determinado.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col s1 m3" style="margin-left: 20px"></div>
        </div>
        <div class="row marketplace" v-if="step == 1">
          <div style="font-weight: bold; line-height: 25px; padding-left: 90%">
            <span v-bind:class="{ disable: !configs.value[0].value }">
              {{ configs.value[0].value ? "Habilitado" : "Desabilitado" }}
            </span>
            <div class="switch" @change="selectConfig(configs.value[0].value)">
              <label>
                <input
                  type="checkbox"
                  :checked="configs.value[0].value"
                  v-model="configs.value[0].value"
                  @change="changeStatus()"
                />
                <span class="lever"></span>
              </label>
            </div>
          </div>
          <MercadoLivre />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../components/Nav";
import SideBar from "../components/SideBar.vue";
import Service from "../service/Index.js";
import Iconspedido from "../assets/Integration/integration-icons-pedido.vue";
import Iconspedcarrinho from "../assets/Integration/integration-icons-pedcarrinhos.vue";
import MercadoLivre from "../components/MercadoLivreIntegration.vue";
import LogoMercadoLivre from "../assets/Integration/logo-mercadolivre.vue";
import LogoLojaIntegrada from "../assets/Integration/integration-lojaintegrada";

import { controlMenu, mountedMenu } from "../factory/CenterControlMenu.js";

export default {
  name: "Integration",
  components: {
    Nav,
    SideBar,
    Iconspedido,
    Iconspedcarrinho,
    MercadoLivre,
    LogoMercadoLivre,
    LogoLojaIntegrada
  },
  data: () => {
    return {
      step: 0,
      moduleSelect: null,
      configs: {},
      user: { results: [] }
    };
  },
  mounted() {
    this.getConfigs();
    mountedMenu();
  },
  updated() {
    controlMenu();
  },

  methods: {
    async getUser(token) {
      var session = JSON.parse(window.localStorage.getItem("session"));
      let result = await Service.MercadoLivre.get(
        session.companies[0].companyId,
        token
      );
      if (result.status == 200) {
        this.user = result.data;
      }
    },
    changeStatus() {
      let config = JSON.parse(JSON.stringify(this.configs));
      config.value = btoa(JSON.stringify(config.value));
      this.putConfig(config);
    },
    findConfig(configModule, name) {
      return configModule.filter(value => {
        return value.name == name;
      })[0];
    },
    selectConfig(config) {
      this.changeStatus();
      if (config) {
        M.toast({ html: "Integração com o Mercado Livre foi Habilitada." });
        document.getElementsByClassName("toast").style.color = "blue";
      } else {
        M.toast({ html: "Integração com o Mercado Livre foi desabilitada." });
      }
    },
    selectSession(integration) {
      console.log(integration);
      switch (integration) {
        case "MercadoLivre":
          this.step = 1;
          this.$router.push("/MercadoLivre");
          this.moduleSelect = { name: "Mercado Livre", version: "1.1" };
          break;
        case "LojaIntegrada":
          this.step = 1;
          this.$router.push("/LojaIntegrada");
          this.moduleSelect = { name: "Loja Integrada", version: "1.1" };
          break;
      }
    },
    async getConfigs() {
      let self = this;
      let result = await Service.Configuration.get(
        JSON.parse(localStorage.getItem("session")).companies[0].companyId
      );
      if (result.status == 200) {
        this.configs = result.data.filter(value => {
          return value.key.indexOf("modules") != -1;
        });
        if (this.configs.length == 0) {
          let session = JSON.parse(localStorage.getItem("session"));
          let module = btoa(
            JSON.stringify([{ name: "mercadoLivre", value: false }])
          );
          await this.saveConfig(
            {
              companyId: session.companies[0].companyId,
              userId: session.userId,
              key: "hub-modules",
              value: module
            },
            ""
          );
          this.getConfigs();
        } else {
          this.configs[0].value = JSON.parse(atob(this.configs[0].value));
          this.configs = this.configs[0];
        }
        var tokenData = result.data.filter(value => {
          return value.key == "mercado-livre-token";
        });
        if (tokenData.length > 0) {
          this.getUser(tokenData[0].value);
        }
      }
    },
    async saveConfig(config, message) {
      let result = await Service.Configuration.post(config);
      if (result.status == 200) {
        if (message != "") M.toast({ html: message });
      }
    },
    async putConfig(config) {
      let result = await Service.Configuration.put(config);
      if (result.status == 200) {
        if (message != "") M.toast({ html: "atualizado" });
      }
    },
    open(e) {
      window.open(
        "https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=233765220085463&redirect_uri=https://www.zeeintegra.com.br/MercadoLivreSaveCode"
      );
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Zee - Integrações";
      }
    }
  }
};
</script>
<style scoped>
.breadcrumb {
  font-size: 14px;
  margin-left: 0px !important;
  padding-left: 20px !important;
}
.breadcrumb:before {
  font-size: 15px;
}
.disable {
  opacity: 0.4;
}
.legend span {
  display: inline-block;
}
.legend .switch {
  display: inline-block;
}
.cursor {
  cursor: pointer;
}
.sub-title {
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid rgb(219, 219, 219);
  font-weight: bold;
  font-size: 20px;
  color: #303030;
}

.text-subtitle {
  font-size: 14px;
  line-height: 30px;
  color: #606060;
}
.logo {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.content-marketplace {
  margin-top: 23px;
  padding-left: 50px !important;
}
.card .card-image img {
  width: 90%;
  margin: auto;
  padding: 30px;
}

.card {
  width: 267px !important;
  height: 267px !important;
}

.card .card-content {
  padding: 0px;
  padding-top: 25px;
  padding-left: 15px;
  border-radius: 0 0 2px 2px;
}
.card-imagesecond {
  width: 260px !important;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
}

.nav-wrapper .col .breadcrumb:first-child {
  color: #666 !important;
  font-size: 14px;
}
.breadcrumb,
.breadcrumb:before {
  color: #6747b3;
  vertical-align: middle;
  margin-left: 10px;
}
.switch label input[type="checkbox"]:checked + .lever {
  background-color: #6747b365;
}
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #6747b3;
}
.card-content p {
  color: rgb(110, 110, 110);
  margin-top: 20px;
}
.title-market {
  color: #6747b3 !important;
  font-size: 18px;
  font-weight: bold;
}
.card-image {
  text-align: center;
  padding-top: 50px;
}
.marketplaces {
  padding: 30px !important;
}
.title {
  border-bottom: 1px solid rgb(231, 231, 231);
  padding: 10px !important;
}
.col .row {
  padding: 0px;
  margin: 0px;
}
.module-integration {
  /* padding: 25px;
  width: 81%; */
  height: 90vh;
  overflow-x: auto;
}
p {
  font-size: 14px;
  color: #606060;
  /* font-weight: bold; */
}

.fontsmenu {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  width: 100%;
  height: 54px;
}

.color-with-pseudo {
  list-style: none;
  list-style-position: inside;
}
.color-with-pseudo li::before {
  content: "•";
  font-size: 180%;
  line-height: 0;
  margin: 0 0.9rem 0 -0rem;
  position: relative;
  color: #6747b3;
  top: 4px;
}
</style>
