<template>
  <div></div>
</template>
<script>
import Services from "../service/Index.js";

export default {
  name: "Login",
  data: () => {
    return {};
  },
  beforeMount() {
    this.getSession();
  },
  methods: {
    async getSession() {
      debugger;
      if (this.$route.params.code != undefined) {
        let result = await Services.Session.get(this.$route.params.code);
        if (result.status == 200) {
          let session = JSON.stringify(JSON.parse(atob(result.data.value)));
          localStorage.clear();
          localStorage.setItem("session", session);
          this.$router.push("/Excel");
        } else {
          this.$router.push("/");
        }
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>