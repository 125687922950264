<template>
  <div class="row">
    <div class="col s12 content-marketplace">
      <div class="row">
        <div style="display: flex; flex-direction: row">
          <div
            class="card card-imagesecond"
            style="margin-top: 20px; width: 50px"
          >
            <LogoMercadoLivre />
          </div>
          <div style="margin-left: 15px; margin-top: 2px">
            <span style="font-size: 48px; font-weight: bold"
              >Mercado Livre</span
            >
            <br />
            <span
              style="
                font-size: 20px;
                line-height: 25px;

                color: #606060;
              "
              >Conecte seu produto com Mercado Livre o maior marketplace da
              América Latina.</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s12 color-with-pseudo">
          <p class="sub-title">Por que que integrar?</p>
          <li class="text-subtitle">Automatizamos seu processo</li>
          <li class="text-subtitle">Facilitamos seu trabalho</li>
          <li class="text-subtitle">Integramos seu pedido</li>
          <li class="text-subtitle">Economizamos seu tempo</li>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <p class="sub-title">Informações técnica</p>
          <span class="text-subtitle"
            >Sincronização automática a cada 15 minutos.</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <p class="sub-title">Como integrar?</p>
          <span class="text-subtitle"
            >Para realizar a integração da sua conta no Mercado Livre, clique no
            botão
          </span>
          <strong style="font-weight: bold; color: #6747b3"
            >“realizar acesso”</strong
          ><span class="text-subtitle">e realize o login.</span>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <p class="sub-title">Como trocar de conta?</p>
          <span class="text-subtitle"
            >Para troca de conta no Mercado Livre, é
          </span>
          <span style="font-weight: bold"
            >importante que você realize a troca de conta pelo próprio site do </span
          ><a
            style="font-weight: bold"
            href="https://www.mercadolivre.com.br/"
            target="_blank"
            >Mercado Livre</a
          >
          <span> e depois clique no botão “trocar de conta”.</span>
        </div>

        <div class="row">
          <div class="col s10 center">
            <span v-if="user.results.length != 0">
              Conta vinculada:
              <b>
                {{ user.results[0].seller.first_name }}
                {{ user.results[0].seller.last_name }} -
                {{ user.results[0].seller.nickname }}</b
              >
            </span>
            <br />
            <div style="display: flex; justify-content: center">
              <button
                class="btn-charge"
                style="height: 50px; width: 200px; margin: 24px"
                :style="
                  user.query == undefined
                    ? 'opacity:1'
                    : 'opacity: 0.5 ;pointer-events:none;'
                "
                target="_blank"
                @click="open(e)"
              >
                <span v-if="user.query != undefined">Conta Vinculada</span>
                <i
                  style="padding-left: 5px"
                  v-if="user.query != undefined"
                  class="far fa-check-circle"
                ></i>
                <span v-if="user.query == undefined">Carregar Conta</span>
              </button>

              <button
                class="btn-charge"
                style="height: 50px; width: 200px; margin: 24px"
                target="_blank"
                @click="open(e)"
                :style="
                  user.query != undefined
                    ? 'opacity:1'
                    : 'opacity: 0.5 ;pointer-events:none;'
                "
              >
                Trocar de Conta
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoMercadoLivre from "../assets/Integration/logo-mercadolivre.vue";
export default {
  name: "MercadoLivre",
  components: {
    LogoMercadoLivre,
  },
  data: () => {
    return {
      user: { results: [] },
    };
  },

  created() {
    window.Emitter.on("user", (value) => {
      this.user = value;
    });
  },
};
</script>

<style scoped>
.breadcrumb {
  font-size: 14px;
  margin-left: 0px !important;
  padding-left: 20px !important;
}
.breadcrumb:before {
  font-size: 15px;
}
.disable {
  opacity: 0.4;
}
.legend span {
  display: inline-block;
}
.legend .switch {
  display: inline-block;
}
.cursor {
  cursor: pointer;
}
.sub-title {
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid rgb(219, 219, 219);
  font-weight: bold;
  font-size: 20px;
  color: #303030;
}

.text-subtitle {
  font-size: 14px;
  line-height: 30px;
  color: #606060;
}
.logo {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.content-marketplace {
  margin-top: 23px;
  padding-left: 50px !important;
}
.card .card-image img {
  width: 90%;
  margin: auto;
  padding: 30px;
}

.card {
  width: 267px !important;
  height: 267px !important;
}

.card .card-content {
  padding: 0px;
  padding-top: 25px;
  padding-left: 15px;
  border-radius: 0 0 2px 2px;
}
.card-imagesecond {
  width: 260px !important;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
}

.nav-wrapper .col .breadcrumb:first-child {
  color: #666 !important;
  font-size: 14px;
}
.breadcrumb,
.breadcrumb:before {
  color: #6747b3;
  vertical-align: middle;
  margin-left: 10px;
}
.switch label input[type="checkbox"]:checked + .lever {
  background-color: #6747b365;
}
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #6747b3;
}
.card-content p {
  color: rgb(110, 110, 110);
  margin-top: 20px;
}
.title-market {
  color: #6747b3 !important;
  font-size: 18px;
  font-weight: bold;
}
.card-image {
  text-align: center;
  padding-top: 50px;
}
.marketplaces {
  padding: 30px !important;
}
.title {
  border-bottom: 1px solid rgb(231, 231, 231);
  padding: 10px !important;
}
.col .row {
  padding: 0px;
  margin: 0px;
}
.module-integration {
  /* padding: 25px;
  width: 81%; */
  height: 90vh;
  overflow-x: auto;
}
p {
  font-size: 14px;
  color: #606060;
  /* font-weight: bold; */
}

.fontsmenu {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  width: 100%;
  height: 54px;
}

.color-with-pseudo {
  list-style: none;
  list-style-position: inside;
}
.color-with-pseudo li::before {
  content: "•";
  font-size: 180%;
  line-height: 0;
  margin: 0 0.9rem 0 -0rem;
  position: relative;
  color: #6747b3;
  top: 4px;
}
</style>

