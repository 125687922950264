<template>
  <div class="container-all">
    <div class="row">
      <div class="s12 divicons pointer" style="padding-left: 30px">
        <Iconscorrela class="iconscorrela" />
        <h5 @click="returnInit()">Correlação</h5>
      </div>
      <hr />
      <div class="s12 scroll">
        <div class="row">
          <div class="col s9 left-align">
            <div class="s12 divicons">
              <Iconspedido />
              <h5>Selecione os pedidos</h5>
            </div>
          </div>
          <div style="display: flex" class="col s3">
            <i class="fas fa-eye iconseyesline"></i>
            <select
              class="selectPage"
              v-model="rowsPerPage"
              @change="mountedPages()"
            >
              <option v-if="excelJsonSepared.length >= 5" v-bind:value="5">
                5
              </option>
              <option v-if="excelJsonSepared.length >= 10" v-bind:value="10">
                10
              </option>
              <option v-if="excelJsonSepared.length >= 20" v-bind:value="20">
                20
              </option>
              <option v-if="excelJsonSepared.length >= 30" v-bind:value="30">
                30
              </option>
            </select>
            <div class="pagination">
              {{ calcRowInitialAndEnd().initial }} -
              {{ calcRowInitialAndEnd().end }} de {{ excelJsonSepared.length }}
              <i
                v-show="pageSelect !== 0"
                @click="pageSelect--"
                class="material-icons"
                >keyboard_arrow_left</i
              >
              <i v-show="pageSelect === 0" class="material-icons disable"
                >keyboard_arrow_left</i
              >
              <i
                v-show="pageSelect !== pagination.length - 1"
                @click="pageSelect++"
                class="material-icons"
                >keyboard_arrow_right</i
              >
              <i
                v-show="pageSelect === pagination.length - 1"
                class="material-icons disable"
                >keyboard_arrow_right</i
              >
            </div>
          </div>
        </div>
        <table v-if="excelJsonSepared.length != 0">
          <thead>
            <tr>
              <th class="columns-check">
                <label>
                  <input
                    type="checkbox"
                    class="filled-in"
                    @click="selectAllRow()"
                  />
                  <span>
                    <a class="order">Selecione quais pedidos deseja importar</a>
                  </span>
                </label>
              </th>
            </tr>
          </thead>
        </table>
        <table class="striped">
          <tbody
            v-for="(page, index) in pagination"
            :key="index"
            v-show="pageSelect == index"
          >
            <tr>
              <th></th>
              <th v-for="(key, index) in columnnames" :key="index">
                {{ key }}
              </th>
            </tr>
            <tr v-for="(row, i) in page" :key="i">
              <td v-for="(cell, j) in row" :key="j">
                <label v-if="typeof cell === 'boolean'">
                  <input type="checkbox" class="filled-in" v-model="row[j]" />
                  <span></span>
                </label>
                <span v-if="typeof cell == 'string' || typeof cell == 'number'">
                  {{ cell }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" style="display: flex; justify-content: center">
      <button
        class="btn-cancel"
        style="background-color: #fff; border: none"
        @click="returnInit()"
      >
        RETORNAR
      </button>

      <button
        class="btn-charge"
        style="width: 170px; height: 46px; margin-left: 36px"
        @click="mountExcelPropertiesHub('imgsend'), (showModal = true)"
      >
        <span>FINALIZAR CONVERSÃO</span>
      </button>
    </div>
  </div>
</template>

<script>
import { bus } from "../main";
import Service from "../service/Index.js";
import OrderFactory from "../factory/Order.ts";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Iconscorrela from "../assets/dashboard/correla-icons.vue";
import Iconspedido from "../assets/dashboard/pedido-icons.vue";

export default {
  components: {
    Iconscorrela,
    Iconspedido
  },
  name: "TableSelectRow",
  created() {
    window.Emitter.on("loadExcel", value => {
      this.excelJson = value.excelJson;
      this.propertiesHub = value.propertiesHub;
      Object.keys(this.propertiesHub).forEach(object => {
        Object.keys(object).forEach(key => {
          if (
            object != "nameCorrelation" &&
            this.propertiesHub[object][key] != undefined &&
            this.propertiesHub[object][key].correlation != ""
          ) {
            this.columnnames.push(this.propertiesHub[object][key].correlation);
          }
        });
      });
      this.mountExcel();
    });

    this.excelJson = JSON.parse(localStorage.getItem("ExcelPayload"));
  },
  data: () => {
    return {
      propertiesHub: {},
      excelJson: { file: [], columnnames: [], nameFile: "" },
      columnnames: [],
      excelJsonSepared: [],
      exceljsonCorrelacion: [],
      orders: [],
      pagination: [[]],
      rowsPerPage: 5,
      pageSelect: 0,
      showModal: false
    };
  },
  methods: {
    calcRowInitialAndEnd() {
      return {
        initial: this.pageSelect * this.rowsPerPage + 1,
        end:
          this.pageSelect * this.rowsPerPage +
            this.pagination[this.pageSelect].length || 0
      };
    },
    mountExcel() {
      this.excelJson.file.forEach(row => {
        var obj = {};
        obj.select = false;
        Object.keys(row).forEach(cell => {
          if (this.columnnames.includes(cell)) {
            eval("obj." + cell.replace(/[^A-Z0-9]+/gi, "") + "= row[cell]");
          }
        });
        this.excelJsonSepared.push(obj);
      });
      this.columnnames = [];
      Object.keys(this.excelJsonSepared[0]).forEach(object => {
        if (object != "select") this.columnnames.push(object);
      });
      this.mountedPages();
    },
    async mountExcelPropertiesHub(el) {
      this.orders = [];
      if (
        this.excelJsonSepared.filter(object => {
          return object.select;
        }).length == 0
      ) {
        Swal.fire({
          timer: 1800,
          showConfirmButton: false,
          icon: "error",
          title: "Selecione ao menos um pedido para importar."
        });
      }
      var ExcelOrder = [];
      this.excelJsonSepared.forEach(object => {
        if (!object.select) {
          return;
        }
        let order = {};
        Object.keys(object).forEach(key => {
          if (key != "select") {
            var nameObject = this.findNamePropertyHub(key).replace(".", "_");
            console.log(nameObject);
            eval("order." + nameObject + " = object." + key + ";");
          }
        });
        order.userId = JSON.parse(localStorage.getItem("session")).userId;
        this.orders.push(order);
      });
      let orderHub = OrderFactory.MountObject(this.orders);
      let result = await Service.Order.post(orderHub);

      if (result.status == 200 && orderHub.length > 0) {
        Swal.fire({
          showConfirmButton: false,
          timer: 1500,
          icon: "success",
          title: "Pedidos importado com sucesso!"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        localStorage.removeItem("ExcelPayload");
      }
      if (result.status > 204) {
        Swal.fire({
          showConfirmButton: false,
          timer: 1500,
          icon: "error",
          title: "Ocorreu um erro" + " " + result.status + " na importação!"
        });
        setTimeout(() => {}, 1500);
      }
    },
    mountedPages() {
      this.pageSelect = 0;
      let pageInt = 0;
      this.pagination = [[]];
      this.excelJsonSepared.forEach((row, index) => {
        if (this.pagination[pageInt].length == this.rowsPerPage) {
          pageInt++;
          this.pagination[pageInt] = [];
        }
        this.pagination[pageInt].push(row);
      });
    },
    findNamePropertyHub(columnNames) {
      var nameProperty = "";
      Object.keys(this.propertiesHub).forEach(object => {
        Object.keys(object).forEach(key => {
          if (
            object != "nameCorrelation" &&
            this.propertiesHub[object][key] != undefined &&
            this.propertiesHub[object][key].correlation != undefined
          ) {
            if (
              this.propertiesHub[object][key].correlation.replace(
                /[^A-Z0-9]+/gi,
                ""
              ) == columnNames
            ) {
              nameProperty = this.propertiesHub[object][key].name;
            }
          }
        });
      });
      return nameProperty;
    },
    selectAllRow() {
      console.log("hm");
      this.excelJsonSepared.forEach(e => {
        e.select = !e.select;
      });
    },
    returnInit() {
      window.Emitter.emit("changestep", 1);
      this.excelJsonSepared = [];
    },
    hmm(s) {
      console.log(s);
    }
  }
};
</script>
<style scoped>
select {
  display: block !important;
}
.pagination .disable {
  opacity: 0.3;
}
.pagination i {
  font-size: 40px;
  vertical-align: middle;
  cursor: pointer;
}
.pagination {
  padding-top: 2px;
  padding-left: 15px;
}
.container-all {
  margin: 0px 20px;
}
.scroll {
  overflow: auto;
  padding: 20px;
}
h5 {
  /* font-family: Ubuntu; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #606060;
  padding-left: 20px;
}
.iconseyesline {
  color: #606060;
  padding-top: 16px;
  padding-right: 10px;
}
.order {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #606060;
}
.divicons {
  padding-left: 20px;
  display: flex;
  align-items: center;
}
i {
  color: #6747b3;
}
thead {
  font-weight: 700;
}

tr th {
  white-space: nowrap;
  width: 0%;
}

.columns:first-child {
  width: 100px !important;
}
.columns-check {
  width: 100px !important;
  height: 60px !important;
}
[type="checkbox"].td {
  width: 10px !important;
}

[type="checkbox"].filled-in:checked + span:not(.lever):before {
  border-right: 2px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(255, 255, 255);
}

[type="checkbox"].filled-in:checked + span:not(.lever):after {
  border: 2px solid #6747b3;
  background-color: #6747b3;
}

[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {
  background-color: #6747b3;
  border-color: #6747b3;
}
hr {
  border-color: none;
}
.swing:hover {
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
#btncancel {
  color: #6747b3;
}
#btnsend {
  font-size: 14px;
}
#iconsend {
  color: #ffffff;
}
.selectPage {
  width: 80px;
}
.selectline {
  width: 95px !important;
}
.pointer {
  cursor: pointer;
}
.btn-cancel {
  color: #6747b3;
}
</style>
