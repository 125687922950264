import { createRouter, createWebHistory } from "vue-router";
import Documents from "../views/Documents.vue";
import Estimate from "../views/Estimate.vue";
import Excel from "../views/Excel.vue";
import File from "../views/File.vue";
import FilePrint from "../views/FilePrint.vue";
import FileSend from "../views/FileSend.vue";
import Home from "../views/Home.vue";
import Loading from "../views/Loading.vue";
import Login from "../views/Login.vue";
import LojaIntegrada from "../views/LojaIntegrada.vue";
import MercadoLivre from "../views/MercadoLivre.vue";
import NotFoundPage from "../views/NotFoundPage.vue";
import Integration from "../views/Integration.vue";
import MercadoLivreSaveCode from "../components/MercadoLivre.vue";

import Orcamento from "../screens/Estimate/estimate.vue";

const routes = [
  {
    path: "/redefinir/:token",
    name: "RememberPassword",
    component: Login
  },
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/Conversor",
    name: "Conversor",
    component: Excel
  },
  {
    path: "/Home",
    name: "Home",
    component: Home
  },
  {
    path: "/Relatorio",
    name: "Relatorio",
    component: File
  },
  {
    path: "/EnviarArquivos",
    name: "EnviarArquivos",
    component: FileSend
  },
  {
    path: "/Impressao",
    name: "Impressao",
    component: FilePrint
  },
  {
    path: "/Integracoes",
    name: "Integracoes",
    component: Integration
  },
  {
    path: "/MercadoLivre/",
    name: "MercadoLivre",
    component: MercadoLivre
  },
  {
    path: "/LojaIntegrada/",
    name: "LojaIntegrada",
    component: LojaIntegrada
  },
  {
    path: "/redirecionando/:code",
    name: "Loading",
    component: Loading
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundPage",
    component: NotFoundPage
  },
  {
    path: "/Documentos/:hash",
    name: "Documents",
    component: Documents
  },
  {
    path: "/MercadoLivreSaveCode",
    name: "MercadoLivreSaveCode",
    component: MercadoLivreSaveCode
  },
  {
    path: "/orcamentodigital/:hash",
    name: "Orcamento",
    component: Orcamento
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
