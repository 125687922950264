<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="18" width="14" height="2" fill="white" />
    <rect
      x="15"
      y="9"
      width="6"
      height="6"
      transform="rotate(-180 15 9)"
      fill="white"
    />
    <path d="M12 16L4.99998 9L19 9L12 16Z" fill="white" />
  </svg>
</template>

