<template>
  <div class="col s6 offset-s3 card card-login center">
    <div class="row center">
      <span><Logo /></span>
      <p>Enviamos um link de recuperação para o seguinte e-mail:</p>
    </div>

    <div class="row center">
      <span><Email /></span>
    </div>

    <div class="row center">
      <span>{{ user }}</span>
    </div>

    <br />
    <br />

    <div class="row">
      <div class="s12 group-button">
        <button class="btn-flat names-btns" @click="returninit()">
          Voltar
        </button>
      </div>
    </div>
    <div class="row">
      <div class="s12 center">
        <a href="https://cadastro.serverlondrisoft.com/"
          >Ainda não tem uma conta? Cadastre-se</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "../assets/dashboard/logo-zee.vue";
import Email from "../assets/dashboard/forget-password-email.vue";

export default {
  name: "SendLinkForgetPassword",
  components: {
    Logo,
    Email,
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("forgetpassword")).email;
    },
  },
  methods: {
    returninit() {
      window.Emitter.emit("changesteplogin", 1);
    },
  },
};
</script>

<style scoped>
.input-field .prefix ~ label {
  margin-left: 0rem;
}
.prefix {
  margin-left: -29px;
}
h3 {
  color: #6747b3;
  padding: 0px;
  margin: 2px;
  font-weight: 600;
}
.icon-password {
  padding-top: 28px;
  color: #6747b3;
}
.material-icons {
  color: #8f8e8e;
}
.card-login {
  margin-top: 20vh;
  padding: 10px 30px !important;
  height: 480px !important;
}
.group-button {
  margin-top: 40px;
}
.names-btns {
  font-size: 12px;
  color: #6747b3;
}
.input-field .prefix.active {
  color: #6747b3;
}
.active {
  color: #513497;
}
p {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #606060;
}
.btn-large {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  font-size: 14px;
}
span {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #6747b3;
}
</style>
