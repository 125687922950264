<template>
  <div class="wrapper-loading" v-if="state.loading">
    <div class="container center">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-blue-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
    <h2>Carregando Orçamento...</h2>
  </div>
  <div v-else class="wrapper" id="scrolls">
    <div class="wrapper-degrade"></div>
    <header class="wrapper-header">
      <div>
        <h1>Orçamento digital</h1>
        <div class="company-info">
          <p>{{ state.orderCompanyData[0] }}</p>
        </div>
        <div class="estimate-info">
          <div class="box-info">
            <p>Número:</p>
            <span>{{ state.order.referenceId }}</span>
          </div>
          <hr class="divider" style="margin: 0 26px" />
          <div class="box-info">
            <p>Validade:</p>
            <span>{{
              new Date(state.order.dateExpirationLink).toLocaleDateString()
            }}</span>
          </div>
          <div class="estimate-status">
            <p>{{ state.dateExpiration }}</p>
            <hr class="divider" style="margin: 0 12px; height:24px" />
            <p v-if="state.approved">
              status: <strong style="color: #118F2D">Aprovado</strong>
            </p>
            <p v-else>status: <strong style="color: red">Aguardando</strong></p>
          </div>
        </div>
      </div>
    </header>
    <div class="wrapper-main">
      <div class="vendedor">
        <h2>Vendedor</h2>
        <div>
          <div class="wrapper-input">
            <span>Nome do vendedor</span>
            <p>{{ state.order.nameSeller }}</p>
          </div>
          <div class="wrapper-input">
            <span>Contato</span>
            <p>{{ state.order.phoneSeller }}</p>
          </div>
          <a target="_blank" :href="state.messageWhatsapp">
            <i class="fab fa-whatsapp"></i>
            whatsapp
          </a>
        </div>
      </div>
      <div class="client">
        <h2>Cliente</h2>
        <div>
          <div class="wrapper-input">
            <span>Nome do cliente</span>
            <p>{{ state.client.name }}</p>
          </div>
          <div class="wrapper-input">
            <span>E-mail</span>
            <p>{{ state.client.email }}</p>
          </div>
          <div class="wrapper-input">
            <span>Contato</span>
            <p>{{ state.client.cellPhone }}</p>
          </div>
          <div class="wrapper-input">
            <span>Endereço</span>
            <p>{{ state.client.clientCustomer[0].address }}</p>
          </div>
          <div class="wrapper-input">
            <span>Número</span>
            <p>{{ state.client.clientCustomer[0].number }}</p>
          </div>
          <div class="wrapper-input">
            <span>Bairro</span>
            <p>{{ state.client.clientCustomer[0].complement }}</p>
          </div>
          <div class="wrapper-input">
            <span>CEP</span>
            <p>{{ state.client.clientCustomer[0].zipCode }}</p>
          </div>
          <div class="wrapper-input">
            <span>Cidade</span>
            <p>{{ state.client.clientCustomer[0].city }}</p>
          </div>
          <div class="wrapper-input">
            <span>UF</span>
            <p>{{ state.client.clientCustomer[0].state }}</p>
          </div>
        </div>
      </div>
      <div class="products">
        <h2>Produtos</h2>
        <table class="striped">
          <thead>
            <tr>
              <th>Código</th>
              <th>Produto</th>
              <th>Quantidade</th>
              <th>Valor</th>
              <th>Desconto</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, index) in state.order.orderProduct"
              :key="index"
            >
              <td style="padding-left: 10px">{{ product.referenceId }}</td>
              <td>
                {{ product.name }}
              </td>
              <td>{{ String(product.quantity).padStart(2, "0") }}</td>
              <td>{{ formatMoney(product.unitaryValue) }}</td>
              <td>
                {{
                  formatMoney(
                    product.quantity * product.unitaryValue - product.amount
                  )
                }}
              </td>
              <td>
                {{ formatMoney(product.amount) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="faturamento">
        <h2>Faturamento</h2>
        <div>
          <div class="wrapper-input">
            <span>Subtotal</span>
            <p>{{ formatMoney(state.subTotal) }}</p>
          </div>
          <div class="wrapper-input">
            <span>Total Desconto</span>
            <p>{{ formatMoney(state.totalDesc) }}</p>
          </div>
          <div class="wrapper-input">
            <span>Valor total</span>
            <p>{{ formatMoney(state.total) }}</p>
          </div>
        </div>
      </div>
      <div class="observations">
        <div class="obs">
          <label for="">Descrição</label>
          <textarea
            v-model="state.order.observation"
            disabled
            name=""
            id=""
            cols="30"
            rows="60"
            style="padding: 10px"
          ></textarea>
        </div>
        <div class="obs">
          <label for="">Observação do cliente</label>
          <textarea
            v-model="state.clientObs"
            :disabled="state.approved"
            name=""
            id=""
            cols="30"
            rows="60"
            style="padding: 10px"
          ></textarea>
        </div>
      </div>
      <div class="actions" v-if="!state.approved">
        <button @click="confirmOrder">aceitar</button>
      </div>
    </div>
    <footer class="wrapper-footer">
      <div>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/londrisoft/mycompany/"
        >
          <Linkedin />
        </a>
        <a target="_blank" href="https://pt-br.facebook.com/londrisoft">
          <Facebook />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCoQWBxPXIDBgubbkb066u0Q"
        >
          <Youtube />
        </a>
        <a target="_blank" href="https://www.instagram.com/londrisoft/?hl=pt">
          <Instagram />
        </a>
      </div>
      <img :src="Logo" alt="" />
      <div>
        <a target="_blank" href="https://londrisoft.com.br/sobre/">Sobre nós</a>
        <a target="_blank" href="https://londrisoft.com.br/contato/">Contato</a>
        <a target="_blank" href="https://londrisoft.movidesk.com/kb/pt-br"
          >Ajuda</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { reactive, defineComponent, onMounted } from "vue";
import logoLondri from "../../assets/estimate/logo_londrisoft.png";
import Facebook from "../../assets/estimate/facebook.vue";
import Instagram from "../../assets/estimate/instagram.vue";
import Youtube from "../../assets/estimate/youtube.vue";
import Linkedin from "../../assets/estimate/linkedin.vue";
import Service from "../../service/Index.js";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  name: "Estimate",
  components: { Facebook, Instagram, Linkedin, Youtube },
  setup: () => {
    const state = reactive({
      loading: true,
      order: {},
      client: {},
      orderCompanyData: [],
      company: {},
      total: 0,
      totalDesc: 0,
      subTotal: 0,
      dateExpiration: "",
      dateExpirationHour: "",
      approved: false,
      messageWhatsapp: "",
      clientObs: ""
    });
    const Logo = logoLondri;

    const getContentByHash = async hash => {
      try {
        const { data: order } = await Service.Estimate.getByHash(hash);
        state.order = order[0];
        const { data: client } = await Service.Estimate.getClient(
          order[0].clientId
        );
        state.client = client[0];
        const { data: company } = await Service.Estimate.getCompany(
          order[0].companyId
        );
        state.company = company;

        setValuesOrder(order[0].orderProduct);
        state.orderCompanyData = order[0].obsInternal.split("\n");

        state.dateExpiration = format(
          parseISO(order[0].dateCreated),
          "d MMM yyyy",
          { locale: ptBR }
        );

        state.messageWhatsapp = `https://wa.me/55${state.order.phoneSeller}?text=Olá, ${state.order.nameSeller}, estou entrando em contato pelo orçamento ${state.order.referenceId}`;

        setTimeout(() => {
          state.loading = false;
          state.approved = order[0].status === 1 ? true : false;
          state.clientObs = order[0].status === 1 ? order[0].obsClient : "";
        }, 1000);
      } catch (error) {
        console.log("Error: ", error.message);
      }
    };

    const formatMoney = value => {
      return new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL"
      }).format(value);
    };
    const setValuesOrder = prods => {
      const sum = prods.reduce(
        (acc, item) => {
          acc.totalDesconto += item.quantity * item.unitaryValue - item.amount;

          acc.subTotal += item.quantity * item.unitaryValue;

          acc.total = acc.subTotal - acc.totalDesconto;
          return acc;
        },
        {
          total: 0,
          totalDesconto: 0,
          subTotal: 0
        }
      );
      state.total = sum.total;
      state.totalDesc = sum.totalDesconto;
      state.subTotal = sum.subTotal;
    };
    const confirmOrder = () =>
      Swal.fire({
        title: "Tem certeza?",
        text: "Você não podera mais interagir após a aprovação.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#6747b3",
        cancelButtonColor: "#d33",
        confirmButtonText: "SIM",
        cancelButtonText: "NÃO"
      }).then(result => {
        if (result.isConfirmed) {
          changeOrder();
        }
      });

    const changeOrder = async () => {
      try {
        const payload = {
          ...state.order,
          status: 1,
          obsClient:
            state.clientObs.length > 3
              ? state.clientObs
              : `${state.clientObs}...!`
        };

        const authorization = await Service.Estimate.getAuthorization(
          state.company.CnpjCpf
        );

        await Service.Estimate.changeOrder(
          state.order.orderId,
          payload,
          authorization.data.Token
        );

        Swal.fire({
          title: "Orçamento Aprovado!",
          text: "Aguarde, o vendedor entra em contato com mais informaçoes.",
          icon: "success",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          timer: 3500,
          timerProgressBar: true
        }).then(res => {
          state.approved = !res.isConfirmed;
          setTimeout(() => {
            document
              .getElementById("scrolls")
              .scroll({ top: 1, behavior: "smooth" });
          }, 200);
        });
      } catch (error) {
        console.log(error.message);
      }
    };

    onMounted(() => {
      const hash = window.location.pathname.split("/")[2];
      getContentByHash(hash);
    });

    return { state, Logo, formatMoney, confirmOrder };
  }
});
</script>

<style src="./styles.scss" lang="scss" scoped />
