<template>
<svg width="48" height="42.67" viewBox="0 0 24 24" fill="#6747B3" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.4063 13.9375H20.4375V17.9453L19.25 17.0561L18.0625 17.9453V13.9375H15.0937C14.7672 13.9375 14.5 14.2381 14.5 14.6055V22.6211C14.5 22.9885 14.7672 23.2891 15.0937 23.2891H23.4063C23.7328 23.2891 24 22.9885 24 22.6211V14.6055C24 14.2381 23.7328 13.9375 23.4063 13.9375Z" fill="#6747B3"/>
    <path d="M8.90625 1H5.9375V5.00781L4.75 4.11858L3.5625 5.00781V1H0.59375C0.267188 1 0 1.30059 0 1.66797V9.6836C0 10.051 0.267188 10.3516 0.59375 10.3516H8.90625C9.23281 10.3516 9.5 10.051 9.5 9.6836V1.66797C9.5 1.30059 9.23281 1 8.90625 1Z" fill="#6747B3"/>
    <g clip-path="url(#clip0)">
    <path d="M20.5827 7.87204L19.229 9.51271C19.1055 9.66232 18.8947 9.66254 18.771 9.51271L17.4172 7.87204C17.2322 7.64791 17.3732 7.28125 17.6462 7.28125H18.4792V3.29688H17.3857C17.3652 3.29688 17.3449 3.29233 17.3259 3.2835C17.307 3.27466 17.2898 3.26171 17.2752 3.24539L16.5461 2.42507C16.4476 2.31435 16.5174 2.125 16.6566 2.125L19.2083 2.125C19.3809 2.125 19.5208 2.2824 19.5208 2.47656V7.28125H20.3537C20.6256 7.28125 20.7685 7.64701 20.5827 7.87204Z" fill="#6747B3"/>
    </g>
    <g clip-path="url(#clip1)">
    <path d="M3.58378 16.628L4.93751 14.9873C5.06101 14.8377 5.27183 14.8375 5.3955 14.9873L6.74934 16.628C6.93432 16.8521 6.7933 17.2187 6.52034 17.2187L5.68734 17.2187L5.68734 21.2031L6.78077 21.2031C6.80129 21.2031 6.82161 21.2077 6.84057 21.2165C6.85953 21.2253 6.87675 21.2383 6.89126 21.2546L7.62042 22.0749C7.71886 22.1857 7.64915 22.375 7.50994 22.375L4.95817 22.375C4.78558 22.375 4.64567 22.2176 4.64567 22.0234L4.64567 17.2187L3.81278 17.2187C3.54093 17.2187 3.39805 16.853 3.58378 16.628Z" fill="#6747B3"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="4.16667" height="7.5" fill="#6747B3" transform="translate(16.5 2.125)"/>
    </clipPath>
    <clipPath id="clip1">
    <rect width="4.16667" height="7.5" fill="#6747B3" transform="translate(7.6665 22.375) rotate(-180)"/>
    </clipPath>
    </defs>
</svg>
</template>