<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.96117 0.00012207H13.2906C16.235 0.00012207 18.6155 2.38063 18.6363 5.32495V12.6753C18.6363 15.6196 16.2558 18.0001 13.3115 18.0001H5.96117C3.01686 18.0001 0.636353 15.6196 0.636353 12.6753V5.32495C0.636353 2.38063 3.01686 0.00012207 5.96117 0.00012207ZM13.2906 16.956C15.6503 16.956 17.5714 15.0349 17.5714 12.6753H17.5923V5.32495C17.5923 2.96532 15.6712 1.0442 13.3115 1.0442H5.96117C3.60155 1.0442 1.68044 2.96532 1.68044 5.32495V12.6753C1.68044 15.0349 3.60155 16.956 5.96117 16.956H13.2906ZM14.5435 2.10902C14.0841 2.10902 13.6665 2.27607 13.3533 2.5893C13.04 2.90252 12.873 3.32016 12.873 3.77955C12.873 4.23895 13.04 4.65659 13.3533 4.96981C13.6665 5.28304 14.0841 5.45009 14.5435 5.45009C15.0029 5.45009 15.4205 5.28304 15.7337 4.96981C16.0261 4.67747 16.214 4.25983 16.2349 3.80044V3.77955C16.2349 3.34104 16.047 2.92341 15.7547 2.63106C15.4414 2.29696 15.0029 2.10902 14.5435 2.10902ZM14.982 4.21807C14.7523 4.44777 14.3138 4.44777 14.0841 4.21807C13.9588 4.09278 13.8962 3.94661 13.8962 3.77955C13.8962 3.6125 13.9588 3.44545 14.0841 3.34104C14.2094 3.21575 14.3556 3.1531 14.5226 3.1531C14.6897 3.1531 14.8567 3.21575 14.982 3.34104C15.0864 3.44545 15.17 3.6125 15.17 3.75867C15.17 3.94661 15.1073 4.11366 14.982 4.21807ZM9.63637 4.59411C7.2141 4.59411 5.23033 6.57787 5.23033 9.00014C5.23033 11.4224 7.2141 13.4062 9.63637 13.4062C12.0586 13.4062 14.0215 11.4224 14.0215 9.00014C14.0215 6.57787 12.0378 4.59411 9.63637 4.59411ZM9.63637 12.3621C7.77791 12.3621 6.27443 10.8586 6.27443 9.00014C6.27443 7.14168 7.77791 5.6382 9.63637 5.6382C11.474 5.6382 12.9774 7.14168 12.9774 9.00014C12.9774 10.8586 11.474 12.3621 9.63637 12.3621Z"
      fill="white"
    />
  </svg>
</template>
