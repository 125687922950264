<template>
  <div>
    <Nav></Nav>

    <div class="container-page row">
      <div class="col" id="menu">
        <SideBar></SideBar>
      </div>
      <div
        class="col s10 module-integration"
        id="colgeral"
        @mouseenter="menurecalldiv"
      >
        <div class="row">
          <div class="nav-wrapper cursor fontsmenu">
            <div class="row" style="padding-top: 15px">
              <span class="breadcrumb" @click="navigation('Integracoes')"
                >Marketplaces</span
              >
              <span class="breadcrumb">Loja Integrada</span>
            </div>
          </div>
          <div style="border: 1px solid rgba(96, 96, 96, 0.3)"></div>
        </div>

        <div class="row marketplace">
          <div class="row">
            <div class="col s12 content-marketplace">
              <div class="row">
                <div style="display: flex; flex-direction: row">
                  <div
                    class="card card-imagesecond"
                    style="margin-top: 20px; width: 50px"
                  >
                    <LogoLojaIntegrada />
                  </div>
                  <div style="margin-left: 15px; margin-top: 2px">
                    <span style="font-size: 48px; font-weight: bold"
                      >Loja Integrada</span
                    >
                    <br />
                    <span
                      style="
                        font-size: 20px;
                        line-height: 25px;

                        color: #606060;
                      "
                      >Conecte seu produto com a Loja Integrada.</span
                    >
                  </div>

                  <div
                    style="
                      font-weight: bold;
                      line-height: 25px;
                      padding-left: 31%;
                    "
                    v-if="config.value != underfined"
                  >
                    <span v-bind:class="{ disable: !config.value[0].value }">
                      {{
                        config.value[0].value ? "Habilitado" : "Desabilitado"
                      }}
                    </span>
                    <div
                      class="switch"
                      @change="selectConfig(config.value[0].value)"
                    >
                      <label>
                        <input
                          type="checkbox"
                          :checked="config.value[0].value"
                          v-model="config.value[0].value"
                          @change="changeStatus()"
                        />
                        <span class="lever"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col s12 color-with-pseudo">
                  <p class="sub-title">Por que que integrar?</p>
                  <li class="text-subtitle">Automatizamos seu processo</li>
                  <li class="text-subtitle">Facilitamos seu trabalho</li>
                  <li class="text-subtitle">Integramos seu pedido</li>
                  <li class="text-subtitle">Economizamos seu tempo</li>
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <p class="sub-title">Informações técnicas</p>
                  <span class="text-subtitle"
                    >Sincronização automática a cada 15 minutos.</span
                  >
                </div>
              </div>
               <div class="row">
                <div class="col s12">
                  <p class="sub-title">Requisitos Mínimos</p>
                  <span class="text-subtitle"
                    >Para utilizar integrações nativas a Loja Integrada exige que o usuário utilize o plano Pro1 ou superior.</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <!-- <p class="sub-title">Como integrar?</p>
                  <span class="text-subtitle"
                    >Para realizar a integração da sua conta no Mercado Livre,
                    clique no botão
                  </span>
                  <strong style="font-weight: bold; color: #6747b3"
                    >“realizar acesso”</strong
                  ><span class="text-subtitle">e realize o login.</span> -->
                </div>
              </div>
              <div class="row">
                <div class="col s12">
                  <!-- <p class="sub-title">Como trocar de conta?</p> -->
                  <!-- <span class="text-subtitle"
                    >Para troca de conta no Mercado Livre, é
                  </span> -->
                  <!-- <span style="font-weight: bold"
                    >importante que você realize a troca de conta pelo próprio
                    site do </span
                  ><a
                    style="font-weight: bold"
                    href="https://www.mercadolivre.com.br/"
                    target="_blank"
                    >Mercado Livre</a
                  > -->
                  <!-- <span> e depois clique no botão “trocar de conta”.</span> -->
                </div>
              </div>
              <div class="display-flex">
                <button
                  class="btn-charge"
                  style="height: 50px; width: 200px; margin: 24px"
                  target="_blank"
                  @click="openModal()"
                >
                  <span>Vincular Chave</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="modalLojaIntegrada" class="modal">
        <div
          style="height: 47px; border: 1px solid rgba(96, 96, 96, 0.3)"
        ></div>
        <div style="margin-left: 27px; margin-top: 22px">
          <h5 style="font-size: 20px; color: #606060">
            Primeiro entre em contato com o
            <span style="font-weight: bold; color: #303030">suporte</span> da
            <span style="font-weight: bold; color: #303030"
              >Loja Integrada</span
            >
            para adquirir a chave da API e da Aplicação.
          </h5>
          <h5 style="font-size: 18px; color: #606060">
            Após adquirir as chaves, informe-as no campo abaixo correspondente e
            clique em
            <span style="font-weight: bold; color: #303030">validar chave</span
            >.
          </h5>
        </div>
        <div class="modal-keys" style="margin-top: 49px">
          <div class="row center">
            <div style="margin-right: 240px">
              <label
                style="font-weight: bold; font-size: 14px; color: #606060"
                class="active"
                >Chave API</label
              >
            </div>
            <div class="row display-flex">
              <div class="input-field">
                <i
                  class="material-icons prefix"
                  style="font-size: 20px; color: #606060"
                  >vpn_key</i
                >
                <input
                  style="
                    width: 267px;
                    height: 34px;
                    background: #f8f6fe;
                    border-radius: 4px;
                  "
                  type="text"
                  v-model="chaveapi"
                  v-on:keyup="validImput"
                />
                <span
                  v-if="validkey == 1 || validkey == 3"
                  class="material-icons prefix"
                  id="check_key"
                  style="color: #55d690; font-size: 18px"
                  >check</span
                >
                <span
                  v-if="validkey == 2"
                  class="material-icons prefix"
                  id="check_key"
                  style="color: red; font-size: 18px"
                  >close</span
                >
              </div>
            </div>
            <div class="row display-flex">
              <button
                class="btn-charge"
                style="width: 122px; height: 46px"
                @click="validateKey()"
              >
                Validar Chave
              </button>
            </div>
            <hr />
            <div class="display-flex" style="margin-left: 75%">
              <button
                class="modal-close btn-flat col"
                style="color: #6747b3; margin-right: 24px"
                @click="closeModalKey()"
              >
                CANCELAR
              </button>
              <button
                :style="
                  validkey == 1
                    ? 'opacity:1'
                    : 'opacity: 0.5 ;pointer-events:none;'
                "
                class="btn-charge"
                style="width: 69px; height: 46px"
                @click="confirmLojaIntegrada()"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../components/Nav";
import SideBar from "../components/SideBar.vue";
import Service from "../service/Index.js";
import Iconspedido from "../assets/Integration/integration-icons-pedido.vue";
import Iconspedcarrinho from "../assets/Integration/integration-icons-pedcarrinhos.vue";
import LogoLojaIntegrada from "../assets/Integration/integration-lojaintegrada.vue";
import { controlMenu, mountedMenu } from "../factory/CenterControlMenu.js";

export default {
  name: "LojaIntegrada",
  components: {
    Nav,
    SideBar,
    Iconspedido,
    Iconspedcarrinho,
    LogoLojaIntegrada,
  },
  data: () => {
    return {
      step: 0,
      moduleSelect: "Loja Integrada",
      configs: {},
      config: {},
      menushow: true,
      modalLojaIntegrada: null,
      validkey: 0,
      chaveapi: "",
    };
  },
  mounted() {
    window.LogoLojaIntegrada = this;

    this.getConfigs();
    this.initModals();
    this.controlMenu();
  },
  updated() {
    this.controlMenu();
    this.initModals();
  },
  methods: {
    initModals() {
      var options = {
        edge: "right",
        draggable: true,
        inDuration: 250,
        outDuration: 200,
        onOpenStart: null,
        onOpenEnd: null,
        onCloseStart: null,
        onCloseEnd: null,
        preventScrolling: true,
      };
      if (this.modalLojaIntegrada == null) {
        var elemsmodalLojaIntegrada = document.querySelectorAll(
          "#modalLojaIntegrada"
        );
        this.modalLojaIntegrada = M.Modal.init(
          elemsmodalLojaIntegrada,
          options
        );
      }
    },
    openModal() {
      this.modalLojaIntegrada[0].open();
    },
    menurecalldiv() {
      if (localStorage.getItem("menurecall") == 0) {
        window.Emitter.emit("functionreduce", "reducemousenter");
      }
    },
    controlMenu() {
      controlMenu();
    },
    changeStatus() {
      let config = JSON.parse(JSON.stringify(this.config));
      config.value = btoa(JSON.stringify(config.value));
      this.putConfig(config);
    },
    findConfig(configModule, name) {
      return configModule.filter((value) => {
        return value.key == name;
      })[0];
    },
    selectConfig(config) {
      this.changeStatus();
      if (config) {
        M.toast({ html: "Integração com a Loja Integrada foi Habilitada." });
      } else {
        M.toast({ html: "Integração com a Loja Integrada foi desabilitada." });
      }
    },

    async getConfigs() {
      let result = await Service.Configuration.get(
        JSON.parse(localStorage.getItem("session")).companies[0].companyId
      );
      if (result.status == 200) {
        this.configs = result.data.filter((value) => {
          return value.key.indexOf("loja-integrada") != -1;
        });
        var configlojaintegrada = this.findConfig(
          this.configs,
          "loja-integrada"
        );
        this.config = result.data.filter((value) => {
          return value.key.indexOf("hub-modules-lojaintegrada") != -1;
        });
        if (configlojaintegrada != undefined) {
          this.chaveapi = configlojaintegrada.value;
          this.validkey = 3;
        }
        if (this.config.length == 0) {
          let session = JSON.parse(localStorage.getItem("session"));
          let module = btoa(
            JSON.stringify([{ name: "lojaintegrada", value: false }])
          );
          await this.saveConfig(
            {
              companyId: session.companies[0].companyId,
              userId: session.userId,
              key: "hub-modules-lojaintegrada",
              value: module,
            },
            ""
          );
          this.getConfigs();
        } else {
          this.config[0].value = JSON.parse(atob(this.config[0].value));
          this.config = this.config[0];
        }
      }
    },
    async saveConfig(config, message) {
      let result = await Service.Configuration.post(config);

      if (result.status == 200 || result.status == 204) {
        this.modalLojaIntegrada[0].close();
      }
    },
    async putConfig(config) {
      let result = await Service.Configuration.put(config);

      if (result.status == 200 || result.status == 204) {
        this.modalLojaIntegrada[0].close();
      }
    },
    async validateKey() {
      if (this.chaveapi == "") {
        M.toast({ html: "Necessário chave_api para verificação!" });
        this.validkey = 2;
        return;
      }

      let result = await Service.LojaIntegrada.getValidKey(this.chaveapi);
      if (result.status == 200) {
        this.validkey = 1;
      } else {
        M.toast({ html: "Atenção chave_api informada inválida!" });
        this.validkey = 2;
        return;
      }
    },
    confirmLojaIntegrada() {
      var configlojaintegrada = this.findConfig(this.configs, "loja-integrada");
      this.configlojaintegrada == undefined;
      if (configlojaintegrada == undefined) {
        this.saveConfig({
          companyId: JSON.parse(localStorage.getItem("session")).companies[0]
            .companyId,
          value: this.chaveapi,
          origin: "hub",
          key: "loja-integrada",
        });
      } else {
        configlojaintegrada.value = this.chaveapi;
        this.putConfig(configlojaintegrada);
      }
      this.getConfigs();
    },
    navigation(to) {
      this.$router.push(to);
    },
    validImput() {
      this.validkey = 2;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Zee - Integrações";
      },
    },
  },
};
</script>
<style scoped>
.container-page {
  height: 90vh;
  overflow-x: auto;
  width: 100%;
}
.breadcrumb {
  font-size: 14px;
  margin-left: 0px !important;
  padding-left: 20px !important;
}
.breadcrumb:before {
  font-size: 15px;
}
.disable {
  opacity: 0.4;
}
.legend span {
  display: inline-block;
}
.legend .switch {
  display: inline-block;
}
.cursor {
  cursor: pointer;
}
.sub-title {
  font-weight: 400;
  font-size: 18px;
  border-bottom: 1px solid rgb(219, 219, 219);
  font-weight: bold;
  font-size: 20px;
  color: #303030;
}

.text-subtitle {
  font-size: 14px;
  line-height: 30px;
  color: #606060;
}
.logo {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.content-marketplace {
  margin-top: 23px;
  padding-left: 50px !important;
}
.card .card-image img {
  width: 90%;
  margin: auto;
  padding: 30px;
}

.card {
  width: 267px !important;
  height: 267px !important;
}

.card .card-content {
  padding: 0px;
  padding-top: 25px;
  padding-left: 15px;
  border-radius: 0 0 2px 2px;
}
.card-imagesecond {
  width: 260px !important;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
}

.nav-wrapper .col .breadcrumb:first-child {
  color: #666 !important;
  font-size: 14px;
}
.breadcrumb,
.breadcrumb:before {
  color: #6747b3;
  vertical-align: middle;
  margin-left: 10px;
}
.switch label input[type="checkbox"]:checked + .lever {
  background-color: #6747b365;
}
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #6747b3;
}
.card-content p {
  color: rgb(110, 110, 110);
  margin-top: 20px;
}
.title-market {
  color: #6747b3 !important;
  font-size: 18px;
  font-weight: bold;
}
.card-image {
  text-align: center;
  padding-top: 50px;
}
.marketplaces {
  padding: 30px !important;
}
.title {
  border-bottom: 1px solid rgb(231, 231, 231);
  padding: 10px !important;
}
.col .row {
  padding: 0px;
  margin: 0px;
}
.module-integration {
  padding: 25px;
  /* width: 831% !important; */
}
p {
  font-size: 14px;
  color: #606060;
  /* font-weight: bold; */
}

.fontsmenu {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #606060;
  width: 100%;
  height: 54px;
}
.color-with-pseudo {
  list-style: none;
  list-style-position: inside;
}
.color-with-pseudo li::before {
  content: "•";
  font-size: 180%;
  line-height: 0;
  margin: 0 0.9rem 0 -0rem;
  position: relative;
  color: #6747b3;
  top: 4px;
}
#modalLojaIntegrada {
  width: 738px;
  height: 564px;
  background: #ffffff;
  border-radius: 12px;
}
input[type="text"]:not(.browser-default) {
  border-bottom: 0px solid #ffffff !important;
}
input.valid[type="text"]:not(.browser-default):focus {
  border-bottom: 0px solid #ffffff !important;
}
.input-field {
  margin-top: 0rem;
  background: #f8f6fe;
  border-radius: 4px;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>