export default class Order {
  static MountObject(itens) {
    var listOrder = [];
    var referenceId = 0;

    itens.forEach(element => {
      if (referenceId == this.findVal(element, "Order_ReferenceId"))
        listOrder[0].orderProduct.push({
          productId: this.findVal(element, "OrderProduct_ProductId"),
          name: this.findVal(element, "OrderProduct_Name"),
          quantity:
            this.findVal(element, "OrderProduct_Quantity") != undefined
              ? typeof this.findVal(element, "OrderProduct_Quantity") ==
                "number"
                ? parseInt(this.findVal(element, "OrderProduct_Quantity"))
                : parseFloat(
                    this.findVal(element, "OrderProduct_Quantity").replaceAll(
                      ",",
                      "."
                    )
                  )
              : 0,
          unitaryValue:
            this.findVal(element, "OrderProduct_UnitaryValue") != undefined
              ? typeof this.findVal(element, "OrderProduct_UnitaryValue") ==
                "number"
                ? parseFloat(this.findVal(element, "OrderProduct_UnitaryValue"))
                : parseFloat(
                    this.findVal(
                      element,
                      "OrderProduct_UnitaryValue"
                    ).replaceAll(",", ".")
                  )
              : 0,
          amount:
            this.findVal(element, "OrderProduct_Amount") != undefined
              ? typeof this.findVal(element, "OrderProduct_Amount") == "number"
                ? parseFloat(this.findVal(element, "OrderProduct_Amount"))
                : parseFloat(
                    this.findVal(element, "OrderProduct_Amount").replaceAll(
                      ",",
                      "."
                    )
                  )
              : 0,

          sku: this.findVal(element, "OrderProduct_Sku")
        });
      else
        listOrder.push({
          companyId: JSON.parse(localStorage.getItem("session")).companies[0]
            .companyId,
          referenceId: this.findVal(element, "Order_ReferenceId"),
          clientId: this.findVal(element, "Order_ClientId"),
          clientCPF: this.findVal(element, "Client_CpfCnpj"),
          clientReferenceId: this.findVal(element, "Order_ClientReferenceId"),
          client: {
            name: this.findVal(element, "Client_Name"),
            cpfCnpj: this.findVal(element, "Client_CpfCnpj"),
            rgIE: this.findVal(element, "Client_RgIE"),
            cellPhone: this.findVal(element, "Client_CellPhone"),
            phone: this.findVal(element, "Client_Phone"),
            email: this.findVal(element, "Client_Email"),
            clientCustomer: [
              {
                address: this.findVal(element, "ClientCustomer_Address"),
                number: this.findVal(element, "ClientCustomer_Number"),
                state: this.findVal(element, "ClientCustomer_State"),
                city: this.findVal(element, "ClientCustomer_City"),
                zipCode: this.findVal(element, "ClientCustomer_ZipCode"),
                cityCode: this.findVal(element, "ClientCustomer_CityCode"),
                complement: this.findVal(element, "ClientCustomer_Complement"),
                observation: this.findVal(
                  element,
                  "ClientCustomer_Observation"
                ),
                priority: this.findVal(element, "ClientCustomer_Priority")
              }
            ]
          },
          observation: this.findVal(element, "Order_Observation"),
          orderProduct: [
            {
              productId: this.findVal(element, "OrderProduct_ProductId"),
              name: this.findVal(element, "OrderProduct_Name"),
              quantity:
                this.findVal(element, "OrderProduct_Quantity") != undefined
                  ? typeof this.findVal(element, "OrderProduct_Quantity") ==
                    "number"
                    ? parseInt(this.findVal(element, "OrderProduct_Quantity"))
                    : parseFloat(
                        this.findVal(
                          element,
                          "OrderProduct_Quantity"
                        ).replaceAll(",", ".")
                      )
                  : 0,
              unitaryValue:
                this.findVal(element, "OrderProduct_UnitaryValue") != undefined
                  ? typeof this.findVal(element, "OrderProduct_UnitaryValue") ==
                    "number"
                    ? parseFloat(
                        this.findVal(element, "OrderProduct_UnitaryValue")
                      )
                    : parseFloat(
                        this.findVal(
                          element,
                          "OrderProduct_UnitaryValue"
                        ).replaceAll(",", ".")
                      )
                  : 0,

              amount:
                this.findVal(element, "OrderProduct_Amount") != undefined
                  ? typeof this.findVal(element, "OrderProduct_Amount") ==
                    "number"
                    ? parseFloat(this.findVal(element, "OrderProduct_Amount"))
                    : parseFloat(
                        this.findVal(element, "OrderProduct_Amount").replaceAll(
                          ",",
                          "."
                        )
                      )
                  : 0,

              sku: this.findVal(element, "OrderProduct_Sku")
            }
          ],
          orderPaymentTerm: [
            {
              name: this.findVal(element, "OrderPayment_Name"),
              referenceId: this.findVal(element, "OrderPayment_ReferenceId"),
              amount:
                this.findVal(element, "OrderPayment_Amount") != undefined
                  ? typeof this.findVal(element, "OrderPayment_Amount") ==
                    "number"
                    ? parseInt(this.findVal(element, "OrderPayment_Amount"))
                    : parseFloat(
                        this.findVal(element, "OrderPayment_Amount").replaceAll(
                          ",",
                          "."
                        )
                      )
                  : 0
            }
          ]
        });
      referenceId = this.findVal(element, "Order_ReferenceId");
    });

    return listOrder;
  }

  static findVal(object, propName) {
    for (let key in object) {
      if (key.toLowerCase() === propName.toLowerCase()) {
        return object[key];
      }
    }
    return undefined;
  }
}
