<template>
  <div class="container center">
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Service from "../service/Index.js";

export default {
  data: () => {
    return {
      configMercado: {},
      configs: [],
    };
  },
  beforeMount() {
    this.getConfigs();
  },
  methods: {
    async getConfigs() {
      let self = this;
      let result = await Service.Configuration.get(
        JSON.parse(localStorage.getItem("session")).companies[0].companyId
      );
      if (result.status == 200) {
        this.configs = JSON.parse(JSON.stringify(result.data));
        this.configMercado = result.data.filter((value) => {
          return value.key.indexOf("mercado-livre-token") != -1;
        });
        console.log(this.configMercado);
        if (this.configMercado.length == 0) {
          this.configMercado = this.configMercado[0];
          let session = JSON.parse(localStorage.getItem("session"));
          this.saveConfig(
            {
              companyId: session.companies[0].companyId,
              userId: session.userId,
              key: "mercado-livre-token",
              value: this.$route.query.code,
            },
            ""
          );
        } else {
          this.configMercado = this.configMercado[0];
          this.configMercado.value = this.$route.query.code;
          this.putConfig(this.configMercado);
        }
        this.updatedModules();
      }
      setTimeout(() => {
        window.close();
      }, 2000);
    },
    updatedModules() {
      console.log(this.configs);
      let modules = this.configs.filter((value) => {
        return value.key.indexOf("hub-modules") != -1;
      })[0];
      console.log(modules);
      modules.value = JSON.parse(atob(modules.value));
      modules.value[0].value = true;
      modules.value = btoa(JSON.stringify(modules.value));
      this.putConfig(modules);
    },
    async saveConfig(config, message) {
      let result = await Service.Configuration.post(config);
      if (result.status == 200) {
        if (this.message != "") M.toast({ html: message });
      }
    },
    async putConfig(config) {
      let result = await Service.Configuration.put(config);
      if (result.status == 200 || result.status == 204) {
        if (this.message != "") M.toast({ html: "atualizado" });
      }
    },
  },
};
</script>
<style scoped>
.preloader-wrapper {
  margin-top: 100px;
}
</style>