<template>
  <div>
    <Nav></Nav>
    <div class="container-page row">
      <div class="col " id="menu">
        <SideBar></SideBar>
      </div>
      <div class="col s10">
        <div class="section banner-home left-align">
          <div class="col s12 center">
            <!-- <div class="section">Zee</div> -->
            <div class="section">
              <div class="col s5 left-align">
                <img
                  src="../../public/imgs/Logo-Zee.svg"
                  alt="Logo Zee Integra"
                />
                <h1 class="text-violet title-primary">
                  Seja Bem Vindo
                </h1>
                <p class="text-normal">
                  Zee integra é mais uma ferramenta desenvolvida pela Londrisoft
                  feita para <b class="text-violet">conectar seu negócio</b> ao
                  mundo digital.
                </p>
                <p class="text-small">Conheça mais sobre as integrações.</p>
                <button
                  class="btn-white-violet"
                  type="button"
                  @click="redirect('/Integracoes')"
                >
                  Saiba mais
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import SideBar from "../components/SideBar.vue";
export default {
  created() {
    document.title = "Zee - Home";
  },
  data() {
    return {};
  },
  components: { SideBar, Nav },
  methods: {
    redirect(routeName) {
      console.log("teste");
      this.$router.push(routeName);
    }
  },
  name: "Home"
};
</script>

<style scoped>
@font-face {
  font-family: "Roboto";
  src: url("../../public/fonts/Roboto-Medium.ttf");
}
.banner-home {
  font-family: "Roboto";
  color: #606060;
  background-image: url("../../public/imgs/Banner-Home.svg");
  background-size: 55%;
  min-height: 92vh;
  background-repeat: no-repeat;
  background-position: right bottom;
  font-weight: 400;
  padding-left: 100px;
  font-size: 16px;
}
.text-violet {
  color: #6747b3;
}
.btn-white-violet {
  border: 1px solid #6747b3;
  background-color: #ffffff;
  color: #6747b3;
  padding: 15px 50px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.title-primary {
  font-size: 48px;
}
.text-small {
  font-size: 14px;
}
</style>
