<template>
  <div class="container">
    <div class="row">
      <div
        class="col s6 offset-s3 card card-login animate__animated animate__fadeIn"
        v-if="steplogin == 0"
      >
        <div class="row center">
          <span><Logo /></span>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input id="email" type="text" v-model="user.email" />
            <i
              class="material-icons prefix fa fa-user-circle"
              aria-hidden="true"
              style="font-size: 20px"
            ></i>
            <label for="email">E-mail</label>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12">
            <input
              id="password"
              type="password"
              v-model="user.password"
              @keypress.enter="login()"
            />
            <i
              class="material-icons prefix fas fa-key"
              style="font-size: 20px"
            ></i>
            <label for="password">Senha</label>
          </div>
          <div class="s12 right" @click="forgetPassword()">
            <a style="cursor: pointer">Esqueci a senha</a>
          </div>
        </div>
        <div class="row">
          <div class="s12 group-button">
            <button
              class="btn-charge"
              style="width: 100%; height: 46px"
              @click="login()"
            >
              Entrar
            </button>
          </div>
        </div>
        <div class="row">
          <div class="s12 center">
            <a href="https://materiais.londrisoft.com.br/zee-integra"
              >Ainda não tem uma conta? Cadastre-se</a
            >
          </div>
        </div>
      </div>
      <div class="animate__animated animate__fadeIn" v-if="steplogin == 1">
        <ForgetPassword />
      </div>
      <div class="animate__animated animate__fadeIn" v-if="steplogin == 2">
        <SendLink />
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../service/Index.js";
import Logo from "../assets/dashboard/logo-zee.vue";
import ForgetPassword from "../components/ForgetPassword.vue";
import SendLink from "../components/SendLinkForgetPassword.vue";
export default {
  name: "Login",
  components: {
    Logo,
    ForgetPassword,
    SendLink
  },
  beforeMount() {
    if (this.$route.params.token !== undefined) {
      this.steplogin = 1;
    }
  },
  data: () => {
    return {
      user: { email: "", password: "" },
      steplogin: 0
    };
  },
  mounted() {
    const session = JSON.parse(localStorage.getItem("session"));
    if (!session) {
      localStorage.clear();
      this.$router.push("/");
    }

    if (!localStorage.getItem("menufixed")) {
      localStorage.setItem("menufixed", 1);
    }

    window.Emitter.on("changesteplogin", value => {
      this.steplogin = value;
    });
    console.log(this.$route);
    if (this.$route.params.token !== undefined) {
      this.steplogin = 1;
    }
  },
  methods: {
    async login() {
      let result = await Service.Login.post(this.user);
      if (result.status == 404) {
        M.toast({ html: "Usuario não localizado" });
      } else if (result.status == 500) {
        M.toast({ html: "E-mail ou senha inválidos" });
      } else {
        localStorage.setItem("session", JSON.stringify(result.data));
        let resultdetail = await Service.Company.getDetailEmp(
          JSON.parse(localStorage.getItem("session")).companies[0].companyId
        );
        localStorage.setItem("empdetail", JSON.stringify(resultdetail.data));
        this.$router.push("/Home");
      }
    },
    forgetPassword() {
      this.steplogin = 1;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || "Zee - Login";
      }
    }
  }
};
</script>
<style scoped>
.input-field .prefix ~ label {
  margin-left: 0rem;
}
.prefix {
  margin-left: -29px;
}
h3 {
  color: #6747b3;
  padding: 0px;
  margin: 2px;
  font-weight: 600;
}
.icon-password {
  padding-top: 28px;
  color: #6747b3;
}
.material-icons {
  color: #8f8e8e;
}
.card-login {
  margin-top: 20vh;
  padding: 10px 30px !important;
  height: 480px !important;
}
.group-button {
  margin-top: 40px;
}
.names-btns {
  font-size: 12px;
}
.input-field .prefix.active {
  color: #6747b3;
}
.active {
  color: #513497;
}
.btn-large {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  font-size: 14px;
}
</style>
