<template>
  <svg
    width="48"
    height="14"
    viewBox="0 0 48 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.718939 9.35493C1.116 9.35493 1.43788 9.03424 1.43788 8.63865C1.43788 8.24305 1.116 7.92236 0.718939 7.92236C0.321879 7.92236 0 8.24305 0 8.63865C0 9.03424 0.321879 9.35493 0.718939 9.35493Z"
      fill="#23164C"
    />
    <path
      d="M47.2809 9.35493C47.678 9.35493 47.9999 9.03424 47.9999 8.63865C47.9999 8.24305 47.678 7.92236 47.2809 7.92236C46.8839 7.92236 46.562 8.24305 46.562 8.63865C46.562 9.03424 46.8839 9.35493 47.2809 9.35493Z"
      fill="#23164C"
    />
    <path
      d="M43.578 3.35085C43.0835 2.33998 42.3501 1.52025 41.3979 0.914374C40.4447 0.307575 39.3007 0 37.9978 0C36.6074 0 35.4187 0.330307 34.4646 0.981178C33.5119 1.63159 32.7962 2.49586 32.3375 3.55033C31.8812 4.59878 31.6503 5.75903 31.6503 7C31.6503 7.28067 31.6605 7.56041 31.681 7.8318L31.6889 7.93525H43.1128H43.1165C43.4662 7.91344 43.7507 7.79097 43.9626 7.57154C44.2001 7.32567 44.3202 7.01995 44.3202 6.66227C44.3197 5.47279 44.0702 4.35894 43.578 3.35085ZM34.283 5.79614C34.3463 5.02233 34.5563 4.36404 34.9083 3.83842C35.2771 3.28729 35.7446 2.8758 36.2978 2.61694C37.4111 2.09643 38.5859 2.09643 39.6988 2.61694C40.2519 2.87627 40.7241 3.28776 41.1017 3.83982C41.4617 4.36543 41.6759 5.02326 41.7392 5.79661H34.283V5.79614Z"
      fill="#6747B3"
    />
    <path
      d="M33.4698 12.16C34.6767 13.381 36.3763 13.9999 38.5201 13.9999C39.5687 13.9999 40.4394 13.902 41.1081 13.7095C41.7739 13.5174 42.458 13.2252 43.142 12.8411C43.629 12.5613 43.8763 12.205 43.8763 11.7824C43.8763 11.4567 43.7589 11.184 43.527 10.9719C43.2975 10.7627 43.0265 10.6565 42.721 10.6565C42.5306 10.6565 42.356 10.6954 42.2009 10.7725C41.6883 11.0281 41.1556 11.2262 40.6187 11.3621C40.0837 11.498 39.4481 11.5667 38.7287 11.5667C37.389 11.5667 36.3344 11.235 35.594 10.5813C35.2145 10.2464 34.9119 9.83489 34.6898 9.35474H31.9276C32.2163 10.4737 32.7332 11.4154 33.4698 12.16Z"
      fill="#23164C"
    />
    <path
      d="M28.8107 3.35085C28.3162 2.33998 27.5828 1.52025 26.6306 0.914374C25.6774 0.307575 24.5334 0 23.2305 0C21.8401 0 20.6514 0.330307 19.6973 0.981178C18.7446 1.63159 18.0289 2.49586 17.5702 3.55033C17.1139 4.59878 16.883 5.75903 16.883 7C16.883 7.28067 16.8932 7.56041 16.9137 7.8318L16.9216 7.93525H28.3455H28.3492C28.6989 7.91344 28.9834 7.79097 29.1953 7.57154C29.4328 7.32567 29.5529 7.01995 29.5529 6.66227C29.5524 5.47279 29.3029 4.35894 28.8107 3.35085ZM19.5157 5.79614C19.579 5.02233 19.789 4.36404 20.141 3.83842C20.5098 3.28729 20.9773 2.8758 21.5305 2.61694C22.6438 2.09643 23.8186 2.09643 24.9315 2.61694C25.4846 2.87627 25.9568 3.28776 26.3344 3.83982C26.6944 4.36543 26.9086 5.02326 26.9719 5.79661H19.5157V5.79614Z"
      fill="#23164C"
    />
    <path
      d="M18.702 12.16C19.9089 13.381 21.6085 13.9999 23.7523 13.9999C24.8009 13.9999 25.6716 13.902 26.3403 13.7095C27.0061 13.5174 27.6902 13.2252 28.3742 12.8411C28.8612 12.5613 29.1085 12.205 29.1085 11.7824C29.1085 11.4567 28.9911 11.184 28.7593 10.9719C28.5297 10.7627 28.2587 10.6565 27.9532 10.6565C27.7628 10.6565 27.5882 10.6954 27.4331 10.7725C26.9205 11.0281 26.3878 11.2262 25.8509 11.3621C25.3159 11.498 24.6803 11.5667 23.9609 11.5667C22.6213 11.5667 21.5666 11.235 20.8262 10.5813C20.4467 10.2464 20.1441 9.83489 19.922 9.35474H17.1598C17.4485 10.4737 17.9654 11.4154 18.702 12.16Z"
      fill="#6747B3"
    />
    <path
      d="M14.3437 0.707883C14.0443 0.41005 13.649 0.259277 13.1685 0.259277H4.89042C4.54725 0.259277 4.25669 0.380359 4.02667 0.618811C3.79804 0.856335 3.6821 1.14906 3.6821 1.48911C3.6821 1.83055 3.79851 2.11957 4.02806 2.34782C4.25716 2.57653 4.54725 2.69251 4.88996 2.69251H11.4778L6.89964 7.9352H10.0036L14.4029 2.9639C14.6622 2.65029 14.794 2.27638 14.794 1.85282C14.7935 1.39123 14.6422 1.00572 14.3437 0.707883Z"
      fill="#6747B3"
    />
    <path
      d="M4.1267 11.1109C3.83103 11.4417 3.68109 11.8253 3.68109 12.2508C3.68109 12.7184 3.85384 13.089 4.19376 13.353C4.52529 13.61 4.94343 13.7404 5.43654 13.7404H13.5772C13.9185 13.7404 14.2124 13.6249 14.4508 13.3971C14.6901 13.1684 14.8112 12.8789 14.8112 12.537C14.8112 12.196 14.6906 11.9028 14.4522 11.6653C14.2138 11.4278 13.919 11.3076 13.5772 11.3076H7.11469L8.78725 9.35547H5.66004L4.1267 11.1109Z"
      fill="#23164C"
    />
  </svg>
</template>