<template>
  <svg
    width="124.79"
    height="64.79"
    viewBox="0 0 125 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.86909 24.4108C2.90136 24.4108 3.73818 23.574 3.73818 22.5417C3.73818 21.5094 2.90136 20.6726 1.86909 20.6726C0.836818 20.6726 0 21.5094 0 22.5417C0 23.574 0.836818 24.4108 1.86909 24.4108Z"
      fill="#23164C"
    />
    <path
      d="M122.921 24.4108C123.953 24.4108 124.79 23.574 124.79 22.5417C124.79 21.5094 123.953 20.6726 122.921 20.6726C121.888 20.6726 121.052 21.5094 121.052 22.5417C121.052 23.574 121.888 24.4108 122.921 24.4108Z"
      fill="#23164C"
    />
    <path
      d="M113.293 8.74394C112.008 6.10615 110.101 3.9671 107.626 2.38612C105.148 0.802717 102.173 0.00012207 98.7861 0.00012207C95.1713 0.00012207 92.0808 0.862035 89.6004 2.56044C87.1236 4.25763 85.263 6.51289 84.0706 9.26448C82.8842 12.0003 82.2838 15.0279 82.2838 18.2661C82.2838 18.9985 82.3104 19.7285 82.3637 20.4367L82.3843 20.7066H112.084H112.094C113.003 20.6497 113.742 20.3301 114.293 19.7575C114.911 19.116 115.223 18.3182 115.223 17.3849C115.222 14.281 114.573 11.3745 113.293 8.74394ZM89.1283 15.1248C89.2929 13.1056 89.8389 11.3878 90.754 10.0162C91.7128 8.5781 92.9282 7.50434 94.3663 6.82885C97.2608 5.47061 100.315 5.47061 103.208 6.82885C104.646 7.50555 105.874 8.57931 106.856 10.0199C107.791 11.3914 108.348 13.108 108.513 15.126H89.1283V15.1248Z"
      fill="#6747B3"
    />
    <path
      d="M87.0135 31.7311C90.1513 34.9173 94.5698 36.5322 100.143 36.5322C102.869 36.5322 105.133 36.2767 106.871 35.7743C108.602 35.2732 110.381 34.5105 112.159 33.5082C113.425 32.7782 114.068 31.8485 114.068 30.7457C114.068 29.8959 113.763 29.1841 113.16 28.6309C112.563 28.0849 111.859 27.8077 111.065 27.8077C110.57 27.8077 110.116 27.9094 109.713 28.1103C108.38 28.7774 106.995 29.2943 105.599 29.6489C104.208 30.0036 102.556 30.1828 100.685 30.1828C97.2027 30.1828 94.4608 29.3173 92.536 27.6116C91.5494 26.7376 90.7626 25.6638 90.1852 24.4109H83.0042C83.7547 27.3307 85.0984 29.7882 87.0135 31.7311Z"
      fill="#23164C"
    />
    <path
      d="M74.9005 8.74394C73.6149 6.10615 71.7083 3.9671 69.2327 2.38612C66.7547 0.802717 63.7804 0.00012207 60.3932 0.00012207C56.7785 0.00012207 53.688 0.862035 51.2076 2.56044C48.7308 4.25763 46.8702 6.51289 45.6778 9.26448C44.4914 12.0003 43.891 15.0279 43.891 18.2661C43.891 18.9985 43.9176 19.7285 43.9709 20.4367L43.9915 20.7066H73.6912H73.7008C74.61 20.6497 75.3496 20.3301 75.9004 19.7575C76.5178 19.116 76.8301 18.3182 76.8301 17.3849C76.8289 14.281 76.1801 11.3745 74.9005 8.74394ZM50.7355 15.1248C50.9001 13.1056 51.4461 11.3878 52.3612 10.0162C53.32 8.5781 54.5354 7.50434 55.9735 6.82885C58.8679 5.47061 61.9222 5.47061 64.8154 6.82885C66.2535 7.50555 67.481 8.57931 68.4628 10.0199C69.3985 11.3914 69.9554 13.108 70.12 15.126H50.7355V15.1248Z"
      fill="#23164C"
    />
    <path
      d="M48.6207 31.7311C51.7584 34.9173 56.1769 36.5322 61.7503 36.5322C64.4765 36.5322 66.7402 36.2767 68.4786 35.7743C70.2097 35.2732 71.988 34.5105 73.7663 33.5082C75.0325 32.7782 75.6753 31.8485 75.6753 30.7457C75.6753 29.8959 75.3703 29.1841 74.7674 28.6309C74.1706 28.0849 73.4661 27.8077 72.6719 27.8077C72.1768 27.8077 71.7229 27.9094 71.3197 28.1103C69.9869 28.7774 68.6021 29.2943 67.2063 29.6489C65.8154 30.0036 64.163 30.1828 62.2927 30.1828C58.8099 30.1828 56.068 29.3173 54.1432 27.6116C53.1566 26.7376 52.3698 25.6638 51.7923 24.4109H44.6113C45.3619 27.3307 46.7056 29.7882 48.6207 31.7311Z"
      fill="#6747B3"
    />
    <path
      d="M37.2909 1.84742C36.5125 1.07025 35.4847 0.676819 34.2355 0.676819H12.7143C11.8221 0.676819 11.0667 0.992773 10.4687 1.615C9.87431 2.2348 9.57288 2.99866 9.57288 3.88599C9.57288 4.77696 9.87551 5.53113 10.4723 6.12672C11.0679 6.72353 11.8221 7.02617 12.713 7.02617H29.8399L17.9378 20.7066H26.0073L37.4446 7.73434C38.1189 6.91601 38.4615 5.9403 38.4615 4.83507C38.4603 3.63057 38.0669 2.6246 37.2909 1.84742Z"
      fill="#6747B3"
    />
    <path
      d="M10.728 28.9928C9.95926 29.856 9.56946 30.8571 9.56946 31.9672C9.56946 33.1874 10.0186 34.1546 10.9023 34.8434C11.7642 35.5141 12.8513 35.8542 14.1332 35.8542H35.2973C36.1847 35.8542 36.9485 35.5528 37.5683 34.9584C38.1906 34.3616 38.5053 33.6063 38.5053 32.7141C38.5053 31.8243 38.1918 31.0593 37.572 30.4395C36.9522 29.8196 36.1859 29.5061 35.2973 29.5061H18.4961L22.8444 24.4121H14.7143L10.728 28.9928Z"
      fill="#23164C"
    />
    <path
      d="M46.5203 48.4161C46.2019 48.4161 45.9295 48.5311 45.7104 48.7575C45.4937 48.9827 45.3835 49.2563 45.3835 49.5734V58.6731C45.3835 58.9903 45.4937 59.2627 45.7128 59.4806C45.9307 59.6985 46.2031 59.8099 46.5203 59.8099C46.8362 59.8099 47.1098 59.6997 47.335 59.483C47.5625 59.2651 47.6775 58.9927 47.6775 58.6731V49.5734C47.6775 49.2429 47.5674 48.9633 47.3483 48.7454C47.1304 48.5275 46.8519 48.4161 46.5203 48.4161Z"
      fill="#23164C"
    />
    <path
      d="M46.605 44.0677H46.4779C46.1329 44.0677 45.8327 44.1924 45.5858 44.4394C45.3388 44.6863 45.2141 44.9853 45.2141 45.3316V45.4163C45.2141 45.7613 45.3388 46.0615 45.5858 46.3085C45.8327 46.5554 46.1317 46.6801 46.4779 46.6801H46.605C46.95 46.6801 47.2503 46.5554 47.4972 46.3085C47.7442 46.0615 47.8689 45.7625 47.8689 45.4163V45.3316C47.8689 44.9866 47.7442 44.6863 47.4972 44.4394C47.2515 44.1936 46.9513 44.0677 46.605 44.0677Z"
      fill="#23164C"
    />
    <path
      d="M58.8461 48.8906C58.2457 48.5057 57.5314 48.3108 56.7228 48.3108C55.8463 48.3108 55.074 48.4972 54.4276 48.8652C53.9119 49.1582 53.4192 49.5359 52.9592 49.991V49.5746C52.9592 49.2574 52.8454 48.9826 52.6202 48.7575C52.395 48.5323 52.1202 48.4173 51.8031 48.4173C51.4847 48.4173 51.2123 48.5323 50.9932 48.7587C50.7765 48.9839 50.6664 49.2574 50.6664 49.5746V58.6743C50.6664 58.9915 50.7777 59.2639 50.9956 59.4818C51.2135 59.6997 51.4859 59.811 51.8031 59.811C52.119 59.811 52.3926 59.7009 52.6178 59.4842C52.8454 59.2663 52.9604 58.9939 52.9604 58.6743V52.3746C53.4301 51.7936 53.9337 51.3239 54.459 50.9776C54.9844 50.6314 55.5897 50.4559 56.2579 50.4559C57.7057 50.4559 58.4091 51.2427 58.4091 52.8625V58.6743C58.4091 58.9927 58.5192 59.2639 58.7383 59.4818C58.9562 59.6997 59.2286 59.811 59.5458 59.811C59.8617 59.811 60.1353 59.7009 60.3617 59.4842C60.5893 59.2663 60.7043 58.9939 60.7043 58.6743V52.6506C60.7043 51.8638 60.5445 51.1302 60.231 50.4728C59.9102 49.807 59.4453 49.2756 58.8461 48.8906Z"
      fill="#23164C"
    />
    <path
      d="M68.7279 57.6647C68.6153 57.6647 68.5233 57.6732 68.4446 57.6901C68.3792 57.7046 68.3187 57.7192 68.2521 57.7361C67.987 57.816 67.7195 57.856 67.4556 57.856C67.0936 57.856 66.791 57.7894 66.5586 57.6574C66.3588 57.5448 66.2583 57.2446 66.2583 56.7653V50.5406H68.4736C68.7642 50.5406 69.0087 50.4401 69.1988 50.2404C69.3876 50.0443 69.482 49.8058 69.482 49.531C69.482 49.2417 69.3852 48.9996 69.1951 48.8083C69.0051 48.6182 68.7618 48.5214 68.4724 48.5214H66.2571V47.0058C66.2571 46.8472 66.2014 46.7056 66.0925 46.5857C65.9811 46.4635 65.8359 46.3993 65.6724 46.3993C65.5102 46.3993 65.3456 46.4816 65.1846 46.6414L62.3071 49.4559C62.1425 49.5879 62.0553 49.7598 62.0553 49.9535C62.0553 50.1145 62.1146 50.2573 62.2248 50.3675C62.3361 50.4789 62.479 50.5382 62.64 50.5382H63.9643V56.9323C63.9643 58.9104 65.0672 59.9139 67.2425 59.9139C67.8877 59.9139 68.5063 59.7795 69.0874 59.512C69.4699 59.3123 69.6733 58.9999 69.6733 58.6089C69.6733 58.3462 69.5765 58.1199 69.384 57.9371C69.1939 57.7567 68.9724 57.6647 68.7279 57.6647Z"
      fill="#23164C"
    />
    <path
      d="M78.6882 49.071C77.899 48.5662 76.9523 48.3108 75.8761 48.3108C74.7273 48.3108 73.7432 48.5856 72.9527 49.1279C72.1634 49.669 71.5714 50.3869 71.1913 51.2633C70.8148 52.1301 70.6248 53.0888 70.6248 54.1142C70.6248 55.9373 71.1308 57.3754 72.1295 58.3886C73.1282 59.4031 74.5312 59.9164 76.2998 59.9164C77.1605 59.9164 77.8772 59.8353 78.4292 59.6755C78.9776 59.5169 79.5405 59.276 80.1022 58.9588C80.5283 58.7131 80.745 58.3959 80.745 58.017C80.745 57.7253 80.6397 57.4795 80.4314 57.2895C80.2281 57.103 79.9872 57.0086 79.7148 57.0086C79.5465 57.0086 79.3904 57.0437 79.2512 57.1127C78.8396 57.3185 78.4135 57.4783 77.9837 57.5873C77.5564 57.6962 77.0467 57.7519 76.4693 57.7519C75.4028 57.7519 74.5663 57.4892 73.9804 56.9699C73.4381 56.4893 73.0967 55.8186 72.9648 54.9749H79.9472C80.2934 54.9749 80.5767 54.8635 80.7886 54.6432C80.998 54.4253 81.1045 54.1541 81.1045 53.8382C81.1045 52.854 80.8987 51.9316 80.492 51.0963C80.084 50.2561 79.4763 49.5746 78.6882 49.071ZM78.8178 53.0404H72.9551C73.0144 52.4666 73.1766 51.9776 73.4393 51.5829C73.7298 51.1471 74.0978 50.8227 74.5324 50.6181C75.4137 50.2041 76.3398 50.2041 77.2199 50.6181C77.6544 50.8227 78.0261 51.1483 78.3239 51.5853C78.5926 51.9788 78.7585 52.4678 78.8178 53.0404Z"
      fill="#23164C"
    />
    <path
      d="M92.5261 48.4161C92.2078 48.4161 91.9342 48.5311 91.7163 48.7575C91.4996 48.9826 91.3894 49.2562 91.3894 49.5734V49.6666C90.4161 48.766 89.2589 48.3096 87.9442 48.3096C87.0847 48.3096 86.2385 48.5275 85.4299 48.956C84.62 49.387 83.9433 50.037 83.4192 50.888C82.895 51.7403 82.6287 52.7898 82.6287 54.0052C82.6287 55.2206 82.895 56.2702 83.4192 57.1224C83.9433 57.9734 84.62 58.6235 85.4299 59.0544C86.2373 59.4842 87.0835 59.7009 87.9442 59.7009C89.2589 59.7009 90.4161 59.2445 91.3894 58.3438V59.328C91.3894 61.5324 90.3871 62.6038 88.3255 62.6038C87.1017 62.6038 85.9516 62.3387 84.9069 61.8169L84.8936 61.8109C84.7314 61.7455 84.5801 61.7128 84.4445 61.7128C84.1515 61.7128 83.9034 61.8218 83.7061 62.036C83.5124 62.2467 83.4143 62.4912 83.4143 62.7636C83.4143 63.1691 83.5717 63.4705 83.8864 63.663C84.436 63.9814 85.1442 64.2513 85.9904 64.4668C86.8353 64.6811 87.6851 64.79 88.5168 64.79C90.1607 64.79 91.4475 64.284 92.3421 63.2877C93.2319 62.2963 93.6822 60.8703 93.6822 59.052V49.571C93.6822 49.2538 93.5684 48.979 93.3433 48.7538C93.1181 48.5311 92.8433 48.4161 92.5261 48.4161ZM91.3906 51.8964V56.1176C90.9536 56.5595 90.4924 56.9166 90.0203 57.1781C89.5409 57.4444 88.9792 57.58 88.3485 57.58C87.3413 57.58 86.5085 57.2725 85.8754 56.6648C85.2435 56.0607 84.9239 55.1661 84.9239 54.0064C84.9239 52.8479 85.2435 51.9533 85.8754 51.3481C86.5085 50.7416 87.3413 50.4329 88.3485 50.4329C88.9792 50.4329 89.5409 50.5685 90.0203 50.8348C90.4924 51.0987 90.9536 51.4546 91.3906 51.8964Z"
      fill="#23164C"
    />
    <path
      d="M102.219 48.3108H102.112C100.962 48.3108 99.9056 48.8676 98.9638 49.9668V49.5734C98.9638 49.2562 98.85 48.9814 98.6249 48.7563C98.3997 48.5311 98.1249 48.4161 97.8077 48.4161C97.4894 48.4161 97.217 48.5311 96.9979 48.7575C96.7812 48.9826 96.671 49.2562 96.671 49.5734V58.6731C96.671 58.9903 96.7812 59.2627 97.0003 59.4806C97.2182 59.6985 97.4906 59.8098 97.8077 59.8098C98.1237 59.8098 98.3973 59.6997 98.6224 59.483C98.85 59.2651 98.965 58.9927 98.965 58.6731V52.2802C99.3245 51.7475 99.7724 51.3214 100.297 51.0139C100.832 50.6992 101.428 50.5406 102.071 50.5406H102.178C102.506 50.5406 102.783 50.4389 103.001 50.238C103.223 50.0334 103.335 49.7671 103.335 49.4475C103.335 49.1315 103.229 48.8616 103.019 48.6425C102.806 48.4222 102.537 48.3108 102.219 48.3108Z"
      fill="#23164C"
    />
    <path
      d="M113.337 50.5418C113.004 49.8845 112.481 49.3422 111.782 48.9294C111.085 48.519 110.223 48.3108 109.22 48.3108C107.957 48.3108 106.698 48.5723 105.479 49.0892C105.046 49.2732 104.817 49.6097 104.817 50.0625C104.817 50.3385 104.914 50.5733 105.104 50.7634C105.294 50.9534 105.523 51.0503 105.784 51.0503C105.871 51.0503 105.975 51.0345 106.104 51.0018L106.117 50.9982C106.617 50.8312 107.077 50.7053 107.485 50.6217C107.888 50.5394 108.358 50.4983 108.881 50.4983C109.806 50.4983 110.489 50.6895 110.913 51.0672C111.328 51.4376 111.54 52.0671 111.541 52.9351H109.157C107.526 52.9351 106.266 53.2619 105.41 53.9047C104.538 54.5609 104.097 55.4518 104.097 56.5522C104.097 57.2205 104.264 57.8209 104.592 58.3366C104.92 58.8511 105.369 59.2481 105.928 59.5157C106.481 59.7808 107.089 59.9151 107.735 59.9151C109.235 59.9151 110.514 59.4551 111.541 58.546V58.6731C111.541 58.9915 111.652 59.2627 111.871 59.4806C112.089 59.6985 112.361 59.8098 112.678 59.8098C112.994 59.8098 113.268 59.6997 113.493 59.483C113.72 59.2651 113.835 58.9927 113.835 58.6731V52.6712C113.833 51.9098 113.666 51.1943 113.337 50.5418ZM110.174 57.4202C109.623 57.7095 109.044 57.856 108.455 57.856C107.698 57.856 107.158 57.7144 106.847 57.4359C106.538 57.1587 106.388 56.8004 106.388 56.3404C106.388 55.3489 107.405 54.8466 109.41 54.8466H111.54V56.3779C111.169 56.7883 110.71 57.1394 110.174 57.4202Z"
      fill="#23164C"
    />
  </svg>
</template>