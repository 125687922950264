<template>
  <div>
    <Nav></Nav>
    <div class="container-page row">
      <div class="col s2 mt3" id="menu">
        <SideBar></SideBar>
      </div>

      <div class="p2" style="height: 88vh; overflow: auto" id="colgeral">
        <div class="row menu">
          <span>Relatórios</span>
        </div>
        <div style="border-bottom: 1px solid rgba(96, 96, 96, 0.3)"></div>
        <div class="progress" v-if="loading">
          <div class="indeterminate"></div>
        </div>
        <table class="highlight">
          <thead>
            <tr>
              <td>Tipo</td>
              <td>Nome</td>
              <td>Data de envio</td>
              <td>Expira em</td>
              <td><center>Visualizar</center></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in filesReceived"
              :key="index"
              v-show="expired(item.datainicio, 24) != false"
            >
              <td>
                <div
                  v-if="item.extensao == 'application/doc'"
                  class="file-icon"
                  data-type="doc"
                ></div>
                <div
                  v-if="item.extensao == 'application/pdf'"
                  class="file-icon"
                  data-type="pdf"
                ></div>
                <div
                  v-if="item.extensao == 'text/plain'"
                  class="file-icon"
                  data-type="txt"
                ></div>
                <div
                  v-if="item.extensao == 'application/excel'"
                  class="file-icon"
                  data-type="xls"
                ></div>
                <div
                  v-if="item.extensao == 'image/png'"
                  class="file-icon"
                  data-type="png"
                ></div>
                <div
                  v-if="item.extensao == 'image/jpg'"
                  class="file-icon"
                  data-type="jpg"
                ></div>
                <div
                  v-if="item.extensao == 'image/jpeg'"
                  class="file-icon"
                  data-type="jpeg"
                ></div>
                <div
                  v-if="item.extensao == 'application/xml'"
                  class="file-icon"
                  data-type="xml"
                ></div>
                <RarIcon v-if="item.extensao == 'link'" />
              </td>
              <td>{{ item.mensagem }}</td>
              <td>{{ format(item.datainicio) }}</td>
              <td>
                {{ expired(item.datainicio, 24) }}
              </td>
              <td
                class="center"
                style="display: flex;align-items: center;justify-content: center;"
              >
                <i
                  v-show="item.extensao == 'application/pdf'"
                  @click="viewPdf(item.id)"
                  class="fas fa-eye"
                ></i>
                <i
                  v-show="item.extensao != 'application/pdf'"
                  @click="downloadFile(item.id)"
                  class="fas fa-file-download"
                ></i>

                <CopyIcon
                  class="link"
                  :data-clipboard-text="item.nome"
                  style="cursor: pointer; margin-left: 10px"
                  v-show="item.extensao === 'link'"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../components/Nav";
import SideBar from "../components/SideBar.vue";
import Service from "../service/Index.js";
import RarIcon from "../assets/files/rar.vue";
import CopyIcon from "../assets/files/copy.vue";
import { controlMenu, mountedMenu } from "../factory/CenterControlMenu.js";
import ClipboardJS from "clipboard";
import Swal from "sweetalert2/dist/sweetalert2.js";

var moment = require("moment");

export default {
  name: "Files",
  components: {
    Nav,
    SideBar,
    RarIcon,
    CopyIcon
  },
  data: () => {
    return {
      filesReceived: [],
      loading: false
    };
  },

  created() {
    this.getFiles();
    var clipboard = new ClipboardJS(".link");
    clipboard.on("success", function(e) {
      Swal.fire({
        title: "Link copiado!",
        text:
          "Utilize os 4 primeiros dígitos do CNPJ da empresa como senha de descompactação do arquivo baixado.",
        icon: "success",
        confirmButtonText: "ENTENDIDO",
        confirmButtonColor: "#6747b3",
        allowOutsideClick: false
      });
    });
    document.title = "Zee - Relatórios";
  },
  mounted() {
    mountedMenu();
  },
  updated() {
    controlMenu();
  },
  methods: {
    formatMessage(el) {
      try {
        const value = el;

        const separateValue = value
          .split("/")[6]
          .replace(".zip", "")
          .split("_")[2]
          .split("");

        const year = separateValue.slice(0, 4).join("");
        const month = separateValue.slice(4, 6).join("");

        const date = new Date(year, month);

        return `XML's referente ao mês ${date
          .getMonth()
          .toString()
          .padStart("2", 0)}/${date.getFullYear()}`;
      } catch (err) {
        console.log("Nome:", el);
        console.error("Error:", err);
        return el || "Relatório";
      }
    },
    expired(data, time) {
      if (data === undefined) {
        return "";
      }

      const dateNow = new Date();
      const dateFile = moment(data, "YYYYMMDD HH:mm:ss").add(time, "hours");

      if (dateFile <= dateNow) {
        return false;
      }
      return dateFile.format("DD/MM/YYYY hh:mm A");
    },
    format(date) {
      return moment(date).format("DD/MM/YYYY, HH:mm:ss");
    },
    async getFiles() {
      this.loading = true;
      this.filesReceived = [];
      let session = JSON.parse(localStorage.getItem("session"));
      let company = await Service.Company.get(session.companies[0].companyId);
      let result = await Service.File.get(company.data.CnpjCpf);

      if (result.status == 200) {
        for (let index = 0; index < result.data.length; index++) {
          if (
            result.data[index].mensagem.trim().toUpperCase() ==
            "ARQUIVO EM BRANCO"
          )
            continue;
          result.data[index].select = false;
          let email = JSON.parse(localStorage.getItem("session")).email;
          if (
            result.data[index].privado == null ||
            result.data[index].privado == 2
          ) {
            this.filesReceived.push(result.data[index]);
          }
          if (
            result.data[index].privado == 0 &&
            result.data[index].login.trim() == email
          ) {
            this.filesReceived.push(result.data[index]);
          }
        }
      }
      this.filesReceived = this.filesReceived.sort((a, b) => {
        let da = new Date(a.datainicio),
          db = new Date(b.datainicio);
        return da - db;
      });
      this.filesReceived = this.filesReceived.reverse();
      this.filesReceived = this.filesReceived.map(file =>
        file.extensao === "link"
          ? {
              ...file,
              mensagem: this.formatMessage(file.nome)
            }
          : { ...file }
      );
      this.loading = false;
    },
    async viewPdf(id) {
      this.loading = true;
      let result = await Service.File.getById(id);
      console.log(result);
      if (result.status == 200) this.openBase64NewTab(result.data.arquivo, id);
      this.loading = false;
    },
    openBase64NewTab(base64Pdf, id) {
      try {
        var blob = this.base64toBlob(base64Pdf);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
        } else {
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl);
        }
      } catch (e) {
        window.open(
          "https://api.serverlondrisoft.com:8010/Relatorios/View/PDF/" + id
        );
      }
    },
    base64toBlob(base64Data) {
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: "application/pdf" });
    },
    async downloadFile(id) {
      this.loading = true;
      let result = await Service.File.getById(id);

      if (result.status == 200)
        this.decodeRequest(
          result.data.extensao,
          result.data.arquivo,
          result.data.mensagem.trim().replaceAll(" ", "-"),
          result.data.nome
        );
      this.loading = false;
    },
    decodeRequest(extensao, textToDecode, message, name) {
      var decodedString = atob(textToDecode);

      if (extensao === "link") {
        const link = name;

        window.open(link, "_blank");
        Swal.fire({
          title: "Download iniciado!!",
          text:
            "Utilize os 4 primeiros dígitos do CNPJ da empresa como senha de descompactação do arquivo baixado.",
          icon: "success",
          confirmButtonText: "ENTENDIDO",
          confirmButtonColor: "#6747b3",
          allowOutsideClick: false
        });

        return;
      }

      var fileName = message;
      if (name.indexOf(".REM") != -1 || name.indexOf(".CRM") != -1) {
        var fileName = name;
      }
      var fileType = extensao;
      var blob = new Blob([decodedString], {
        type: "application/octet-stream"
      });

      if (fileType == "application/excel") {
        if (
          name.toUpperCase().indexOf("CONSIGNADO") != -1 ||
          message.toUpperCase().indexOf("CONSIGNADO")
        ) {
          var a = document.createElement("a");
          a.href = "data:application/vnd.ms-excel;base64," + textToDecode;
          a.download = name;
          a.click();
          return;
        }
        extensao = ".xls";
      } else if (name.indexOf(".REM") != -1 || name.indexOf(".CRM") != -1) {
        extensao = "";
      } else if (name.toUpperCase().indexOf(".XML") != -1) {
        extensao = ".xml";
      } else {
        extensao = ".txt";
      }

      var a = document.createElement("a");
      a.download = fileName + extensao;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function() {
        URL.revokeObjectURL(a.href);
      }, 1500);
    }
  }
};
</script>
<style scoped>
.progress {
  background-color: #d9c4de !important;
}
.progress .indeterminate {
  background-color: #732792 !important;
}

thead tr td {
  font-weight: bold;
  font-size: 14px;
  color: #6747b3 !important;
}
td i {
  padding: 10px;
  font-size: 23px;
  color: #6f29a8;
  cursor: pointer;
}
/* .p2 {
  padding: 20px !important;
} */
tr td {
  padding-left: 20px;
}
.menu {
  height: 48px;
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: 14px;
  color: #606060;
  padding-left: 24px;
  margin-bottom: 0px !important;
}
</style>
