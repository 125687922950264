<template>
  <router-view />
</template>

<style>
/* @import "styles/globalStyles.scss"; */
@import "assets/css/style.css";
@import "assets/css/file.css";
@import "assets/css/margin.css";
@import "assets/css/padding.css";
</style>
