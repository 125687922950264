<template>
  <nav>
    <router-link to="/Home" title="Ir para Home" class="brand-logo nav-wrapper"><Logo class="logo-zee" /></router-link>
    <ul id="nav-mobile" class="right hide-on-med-and-down">
      <div class="nav-wrapper user-name">
        <a href="https://londrisoft.movidesk.com/kb/article/194189/zee-base-de-conhecimento-visao-geral?preview=true&revisionId=631608" target="_blank" title="Preciso de Ajuda">
          <i
            class="fas fa-question-circle"
          ></i>
        </a>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
          <span>{{ user }}</span>
          <span style="font-size: 10px">{{ nameemp }}</span>
        </div>
        <li class="dropdown-trigger" data-target="dropdown1">
          <a href="#!">
            <i class="material-icons" title="Perfil">person </i>
          </a>
        </li>
      </div>
    </ul>
    <ul class="z-depth-1 dropdown-content dropdown-menu" id="dropdown1">
      <li>
        <a @click="logout()">Sair </a>
      </li>
    </ul>
  </nav>
</template>

<script>
// @ is an alias to /src
import Logo from "../assets/dashboard/logo-zee2.vue";

export default {
  components: {
    Logo,
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("session")).name;
    },
    nameemp() {
      try {
        return JSON.parse(localStorage.getItem("empdetail")).FictitiousName;
      } catch {
        return;
      }
    },
  },
  name: "NavBar",
  beforeMount() {
    var options = {
      alignment: "left",
      edge: "left",
    };
    setTimeout(function () {
      var elems = document.querySelectorAll(".dropdown-trigger");
      var instances = M.Dropdown.init(elems, options);
    }, 700);
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
      window.location.reload();
    },
    InitMenu() {
      window.Emitter.emit("changestepmenu", 3);
    },
  },
};
</script>
<style scoped>
nav {
  margin-bottom: 1px;
  height: 64px !important;
  line-height: 20px !important;
}
.nav-wrapper {
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand-logo {
  left: 10px;
}
#dropdown1 {
  top: 64px !important;
  color: #6747b3 !important;
  font-weight: bold;
}
.dropdown-menu li a i,
.dropdown-menu li a span {
  display: inline-block;
  font-size: 10px;
}
.user-name {
  float: right;
  font-weight: bold;
}
a {
  color: #6747b3 !important;
}
.logo-zee {
  width: 60%;
  height: 100%;
}
.m-0 {
  margin: 0;
}
</style>

