<template>
  <div class="col s6 offset-s3 card card-login center">
    <div v-if="stepforgetpassw == 0">
      <div class="row center">
        <span><Logo /></span>
        <p>
          Para recuperar sua senha é necessário digitar um e-mail cadastrado em
          nossa base.
        </p>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input id="email" type="text" v-model="user.email" />
          <i
            class="material-icons prefix fa fa-user-circle"
            aria-hidden="true"
            style="font-size: 20px"
          ></i>
          <label for="email">E-mail</label>
        </div>
        <div class="preloader-wrapper small active">
          <div class="spinner-layer spinner-green-only">
            <div
              id="circle-clipper"
              class="circle-clipper left"
              style="display: none"
            >
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="s12">
          <button
            style="font-size: 12px; width: 100%; height: 46px"
            class="btn-charge"
            @click="forgetPassword()"
          >
            Recuperar Senha
          </button>
        </div>
        <button class="btn-flat names-btns" @click="returninit()">
          Voltar
        </button>
      </div>
      <div class="row">
        <div class="s12 center">
          <a href="https://cadastro.serverlondrisoft.com/"
            >Ainda não tem uma conta? Cadastre-se</a
          >
        </div>
      </div>
    </div>

    <div v-if="stepforgetpassw == 1">
      <div class="row center">
        <span><Logo /></span>
        <p>Digite uma nova senha</p>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input type="password" v-model="remenberpassword.password" />
          <label for="password">Digite sua nova senha</label>
        </div>
      </div>
      <div class="row">
        <div class="input-field col s12">
          <input type="password" v-model="passwordconfirm" />

          <label for="password">Confirme sua senha</label>
        </div>
      </div>
      <div class="row">
        <div class="s12">
          <a
            style="font-size: 12px"
            class="waves-effect waves-light btn-large block"
            @click="forgetPasswordPost()"
          >
            Salvar
          </a>
        </div>
      </div>
    </div>
    <div v-if="stepforgetpassw == 2" class="animate__animated animate__fadeIn">
      <div class="row center">
        <span><Logo /></span>
        <p>Sua senha foi recuperada com sucesso!</p>
      </div>
      <div class="row center" style="height: 210px">
        <Password />
      </div>
      <button class="btn-flat names-btns" @click="returninit()">Voltar</button>
    </div>
  </div>
</template>

<script>
import Logo from "../assets/dashboard/logo-zee.vue";
import Password from "../assets/dashboard/rememberPassword.vue";

import Service from "../service/Index.js";

export default {
  name: "ForgetPassword",
  created() {
    document.title = "Zee - Esqueci Minha Senha";
  },
  components: {
    Logo,
    Password,
  },
  beforeMount() {
    if (this.$route.params.token !== undefined) {
      this.token = this.$route.params.token;
      this.stepforgetpassw = 1;
    }
  },
  data: () => {
    return {
      stepforgetpassw: 0,
      user: { email: "", FirstOrigin: "zee" },
      token: "",
      passwordconfirm: "",
      remenberpassword: {
        email: "",
        password: "",
        tokenRememberPassword: "",
      },
    };
  },
  methods: {
    returninit() {
      window.Emitter.emit("changesteplogin", 0);
      document.getElementById("circle-clipper").style.display = "none";
    },
    async forgetPassword() {
      document.getElementById("circle-clipper").style.display = "inline";

      let result = await Service.ForgetPassword.postcreateuser(this.user);

      if (result.status == 404) {
        document.getElementById("circle-clipper").style.display = "none";

        M.toast({ html: "Usuario não localizado" });
      } else {
        window.Emitter.emit("changesteplogin", 2);
        localStorage.setItem("forgetpassword", JSON.stringify(this.user));
      }
    },
    async forgetPasswordPost() {
      if (this.remenberpassword.password != this.passwordconfirm) {
        M.toast({ html: "Senhas não coincidem!" });
      } else {
        this.remenberpassword.tokenRememberPassword = this.token;
        let result = await Service.ForgetPassword.postremenber(
          this.remenberpassword
        );
        if (result.status == 404) {
          M.toast({ html: "Solicitação expirada!" });
          return;
        }
        this.stepforgetpassw = 2;
      }
    },
  },
};
</script>

<style scoped>
.input-field .prefix ~ label {
  margin-left: 0rem;
}
.prefix {
  margin-left: -29px;
}
h3 {
  color: #6747b3;
  padding: 0px;
  margin: 2px;
  font-weight: 600;
}
.icon-password {
  padding-top: 28px;
  color: #6747b3;
}
.material-icons {
  color: #8f8e8e;
}
.card-login {
  margin-top: 20vh;
  padding: 10px 30px !important;
  height: 480px !important;
}

.names-btns {
  font-size: 12px;
  color: #6747b3;
}
.input-field .prefix.active {
  color: #6747b3;
}
.active {
  color: #513497;
}
p {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #606060;
}
.btn-large {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  font-size: 14px;
}
</style>