<template>
  <svg
    width="73"
    height="59"
    viewBox="0 0 73 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M55 23.5555L66.3546 16.9999" stroke="#6747B3" />
    <ellipse cx="36.5" cy="55.5" rx="36.5" ry="3.5" fill="#F8F6FE" />
    <path
      d="M51.6905 24.9991H27.7024V14.9308C27.7024 11.0636 30.7686 7.85068 34.599 7.81162C38.468 7.77256 41.631 10.9464 41.631 14.8429V16.4054C41.631 17.7042 42.6659 18.7491 43.9524 18.7491H47.0476C48.3341 18.7491 49.369 17.7042 49.369 16.4054V14.8429C49.369 6.63975 42.7433 -0.0301773 34.6183 -0.000880418C26.4933 0.0284165 19.9643 6.78623 19.9643 14.9894V24.9991H17.6429C15.0796 24.9991 13 27.0987 13 29.6866V45.3116C13 47.8995 15.0796 49.9991 17.6429 49.9991H51.6905C54.2537 49.9991 56.3333 47.8995 56.3333 45.3116V29.6866C56.3333 27.0987 54.2537 24.9991 51.6905 24.9991ZM38.5357 39.8429C38.5357 42.0011 36.8043 43.7491 34.6667 43.7491C32.529 43.7491 30.7976 42.0011 30.7976 39.8429V35.1554C30.7976 32.9972 32.529 31.2491 34.6667 31.2491C36.8043 31.2491 38.5357 32.9972 38.5357 35.1554V39.8429Z"
      fill="#6747B3"
    />
    <path d="M52 19.3838L58.5048 7.99996" stroke="#6747B3" />
  </svg>
</template>