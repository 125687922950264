<template>
  <svg
    width="13"
    height="22"
    viewBox="0 0 13 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1266 0.595734C11.1178 0.488984 10.1039 0.437104 9.08948 0.440461C5.7262 0.440461 3.63635 2.582 3.63635 6.0303V8.37112H0.811157C0.535156 8.37094 0.311279 8.59457 0.311157 8.87057V12.7217C0.310974 12.9977 0.534607 13.2215 0.810608 13.2217H3.63635V20.9405C3.63617 21.2165 3.8598 21.4403 4.1358 21.4405H8.11389C8.38989 21.4406 8.61371 21.217 8.61389 20.941V13.2217H11.4304C11.6816 13.2216 11.8939 13.0353 11.9265 12.7862L12.4235 8.93558C12.4592 8.6619 12.2663 8.41104 11.9926 8.37534C11.9709 8.37253 11.9492 8.37112 11.9274 8.37112H8.61389V6.41214C8.61389 5.44241 8.8092 5.03714 10.0221 5.03714L12.0612 5.03616C12.3372 5.03629 12.561 4.81271 12.5612 4.53671V1.09183C12.5612 0.840851 12.3754 0.628693 12.1266 0.595734ZM11.5612 4.03616L10.0221 4.03714C7.86389 4.03714 7.61389 5.39261 7.61389 6.41214V8.87119C7.61377 9.14713 7.83734 9.371 8.11334 9.37112H11.359L10.9908 12.2217H8.11389C7.83789 12.2216 7.61407 12.4452 7.61389 12.7212V20.4405H4.63635V12.7217C4.63654 12.4457 4.4129 12.2219 4.13696 12.2217H1.31116V9.37112H4.13635C4.41235 9.37131 4.63617 9.14767 4.63635 8.87167V6.0303C4.63635 3.15628 6.30133 1.44046 9.08948 1.44046C10.0983 1.44046 11.0094 1.49515 11.5612 1.543V4.03616Z"
      fill="white"
    />
  </svg>
</template>
