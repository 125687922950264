export default class Session {
  static verifySession() {
    if (
      window.location.pathname.indexOf("redirecionando") != -1 ||
      window.location.pathname.indexOf("redefinir") != -1 ||
      window.location.pathname.indexOf("Documentos") != -1 ||
      window.location.pathname.indexOf("orcamentodigital") != -1
    ) {
      return true;
    }
    if (localStorage.getItem("session") != null) {
      let session = JSON.parse(localStorage.getItem("session"));

      if (
        new Date(
          session.expirationToken.substring(
            0,
            session.expirationToken.indexOf(".")
          )
        ) > new Date()
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
}
