<template>
  <svg
    class="testekrep"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 19H6V22V22C4.89543 22 4 21.1046 4 20V19Z" class="fill" />
    <rect x="4" y="8" width="2" height="6" class="fill" />
    <path d="M18 19H20V20C20 21.1046 19.1046 22 18 22V22V19Z" class="fill" />
    <rect x="18" y="8" width="2" height="6" class="fill" />
    <rect x="12" y="8" width="2" height="6" class="fill" />
    <rect x="4" y="16" width="2" height="4" class="fill" />
    <rect x="18" y="16" width="2" height="4" class="fill" />
    <path d="M20 3L20 6L4 6L4 3L20 3Z" class="fill" />
    <rect x="6" y="19" width="2" height="3" class="fill" />
    <rect x="6" y="8" width="2" height="6" class="fill" />
    <rect x="6" y="16" width="2" height="4" class="fill" />
    <rect x="16" y="19" width="2" height="3" class="fill" />
    <rect x="16" y="8" width="2" height="6" class="fill" />
    <rect x="10" y="8" width="2" height="6" class="fill" />
    <rect x="16" y="16" width="2" height="4" class="fill" />
    <rect x="10" y="16" width="4" height="6" class="fill" />
  </svg>
</template>