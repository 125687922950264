<template>
  <div class="container-menu" v-show="menushow == false">
    <div>
      <div
        class="menu menu-item-false"
        title="Ir para Home"
        :class="{ select: menuSelect == 'Home' }"
        @click="navigation('Home')"
      >
        <i class="fas fa-home" style="font-size: 18px"> </i>
      </div>

      <div
        class="menu menu-item-false"
        title="Ir para Integrações"
        :class="{ select: menuSelect == 'Integracoes' }"
        @click="navigation('Integracoes')"
      >
        <i class="fas fa-network-wired"> </i>
      </div>

      <div
        class="menu menu-item-false"
        title="Ir para Conversor"
        :class="{ select: menuSelect == 'Conversor' }"
        @click="navigation('Conversor')"
      >
        <i class="fas fa-th" style="font-size: 18px"></i>
      </div>
      <div class="menu menu-item-false" v-if="user == 1">
        <i
          class="fas fa-folder-open"
          title="Menu de arquivos"
          style="font-size: 18px; padding-left: 1px"
          @click="expandMenu(), navigation('Relatorio')"
        ></i>
      </div>
    </div>

    <div
      class="menu-arrow"
      title="Clique para expandir e fixar o menu"
      @click="expandMenu('eventClick')"
    >
      <i class="fas fa-chevron-circle-right"> </i>
    </div>
  </div>

  <div class="container-menu" v-show="menushow == true" id="menu-expand">
    <div
      class="menu-item"
      title="Ir para Home"
      :class="{ select: menuSelect == 'Home' }"
      @click="navigation('Home')"
      id="menu-arrow"
    >
      <i class="fas fa-home" style="font-size: 18px"> </i>
      <p style="font-weight: 700">Home</p>
    </div>

    <div
      class="menu-item"
      title="Ir para Integrações"
      :class="{ select: menuSelect == 'Integracoes' }"
      @click="navigation('Integracoes')"
      id="menu-arrow"
    >
      <i class="fas fa-network-wired" style="font-size: 18px"> </i>
      <p style="font-weight: 700">Integrações</p>
    </div>

    <div
      class="menu-item"
      title="Ir para Conversor"
      :class="{ select: menuSelect == 'Conversor' }"
      @click="navigation('Conversor')"
    >
      <i class="fas fa-th" style="font-size: 18px"></i>
      <p style="font-weight: 700; padding-left: 5px">Conversor xls</p>
    </div>
    <div
      class="row"
      style="display: flex; justify-content: center; margin-bottom: 0px"
    >
      <div
        class="center"
        style="border-bottom: 1px solid rgba(96, 96, 96, 0.3); width: 87%"
      ></div>
    </div>
    <div
      class="menu-item"
      title="Menu de arquivos"
      :class="{
        select:
          menuSelect == 'Relatorio' ||
          menuSelect == 'EnviarArquivos' ||
          menuSelect == 'Impressao'
      }"
      v-if="user == 1"
      @click="navigation('Relatorio'), visibleSubMenu()"
    >
      <i
        class="fas fa-folder-open"
        style="font-size: 18px; padding-left: 1px"
      ></i>
      <p style="font-weight: 700; padding-left: 2px">Arquivos</p>

      <i
        v-if="
          menuSubSelectView == 0 &&
            menuSelect != 'Conversor' &&
            menuSelect != 'Integracoes'
        "
        style="padding: 0px 30%"
        class="fas fa-angle-up"
      ></i>
      <i
        v-if="
          menuSubSelectView == 1 ||
            menuSelect == 'Conversor' ||
            menuSelect == 'Integracoes'
        "
        style="padding: 0px 30%"
        class="fas fa-angle-down"
      ></i>
    </div>

    <div
      class="row"
      style="display: flex; justify-content: center; margin-bottom: 0px"
    >
      <div
        v-if="
          menuSubSelectView == 1 ||
            menuSelect == 'Integracoes' ||
            menuSelect == 'Conversor'
        "
        id="rowsubmenu"
        style="border-bottom: 1px solid rgba(96, 96, 96, 0.3); width: 87%"
      ></div>
      <div
        v-if="
          menuSubSelectView == 0 &&
            menuSelect != 'Integracoes' &&
            menuSelect != 'Conversor'
        "
        style="border-bottom: 1px solid #6747b3; width: 87%"
      ></div>
    </div>

    <div
      class="menu-item-sub"
      v-if="
        menuSelect == 'Relatorio' ||
          menuSelect == 'EnviarArquivos' ||
          menuSelect == 'Impressao'
      "
    >
      <ul v-show="menuSubSelectView == 0">
        <li
          @click="navigation('Relatorio')"
          title="Ir para relatório"
          :class="{ select: menuSubSelect == 'Relatorio' }"
        >
          <i
            class="fas fa-file-alt"
            style="font-size: 18px; padding-left: 5px"
          ></i>
          <span style="padding-left: 16.51px">Relatórios</span>
        </li>
        <li
          @click="navigation('EnviarArquivos')"
          title="Ir para arquivos enviados"
          :class="{ select: menuSubSelect == 'EnviarArquivos' }"
        >
          <i class="fas fa-file-import" style="font-size: 18px"></i>
          <span style="padding-left: 16.51px">Enviados</span>
        </li>
        <li
          @click="navigation('Impressao')"
          title="Ir para impressões"
          :class="{ select: menuSubSelect == 'Impressao' }"
        >
          <i
            class="fas fa-print"
            style="font-size: 18px; padding-left: 2px"
          ></i>

          <span style="padding-left: 14px">Impressões</span>
        </li>
      </ul>
    </div>
    <!-- <div class="footer-version">
      <small>
        {{ version }}
      </small>
    </div> -->
    <br />
    <div
      class="menu-arrow-show"
      title="Clique para recolher"
      @click="reduceMenu('eventClick')"
      v-if="menufixed == 1"
    >
      <i class="fas fa-chevron-circle-left"> </i>
    </div>
    <div
      class="menu-arrow-show"
      title="Clique para expandir e fixar o menu"
      @click="expandMenu('eventClick')"
      v-if="menufixed == 0"
    >
      <i class="fas fa-chevron-circle-right"> </i>
    </div>
  </div>
</template>
<script>
import Icons from "../assets/dashboard/conversor-icons.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  name: "SideBar",
  components: {
    Icons
  },
  data: () => {
    return {
      menuSelect: "",
      menuSubSelect: "recebidos",
      menuSubSelectView: 0,
      menushow: true,
      menufixed: 0,
      function: "",
      stepconvert: 0
    };
  },
  beforeMount() {
    this.selectMenu();
    this.menuSubSelect = this.$route.name;
    if (window.Conpass) {
      window.Conpass.init({
        name: JSON.parse(localStorage.getItem("session")).name,
        email: JSON.parse(localStorage.getItem("session")).email
      });
    }
  },
  mounted() {
    window.Emitter.on("menureduce", value => {
      this.menushow = value;
    });
    this.menufixed = localStorage.getItem("menufixed");
    if (localStorage.getItem("menufixed") == 0) {
      this.menushow = false;
      this.reduceMenu();
    }
    window.Emitter.on("stepconvert", value => {
      this.stepconvert = value;
    });
  },
  updated() {
    this.selectMenu();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("session")).type;
    }
  },
  methods: {
    selectSubMenu(option) {
      this.menuSubSelect = option;
    },
    navigation(to) {
      if (localStorage.getItem("ExcelPayload") && this.stepconvert == 1) {
        Swal.fire({
          title: "Deseja realmente sair?",
          text: "Todo processo será perdido!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#6747b3",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não"
        }).then(result => {
          if (result.isConfirmed) {
            localStorage.removeItem("ExcelPayload");
            this.$router.push(to);
          }
        });
      } else {
        this.$router.push(to);
      }
    },
    selectMenu() {
      this.menuSelect = this.$route.name;
    },
    visibleSubMenu() {
      if (this.menuSubSelectView == 0) {
        this.menuSubSelectView = 1;
      } else {
        this.menuSubSelectView = 0;
      }
    },
    expandMenu() {
      localStorage.setItem("menufixed", 1);
      this.menufixed = 1;
      this.menushow = true;
      window.Emitter.emit("expandmenu", true);
    },
    reduceMenu(event) {
      if (event) {
        localStorage.setItem("menufixed", 0);
        this.menufixed = 0;
      }
      if (localStorage.getItem("menufixed") == 0) {
        this.menushow = false;
        window.Emitter.emit("reducemenu", false);
      }
    }
  }
};
</script>
<style scoped>
.menu {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-item-sub ul li i {
  margin-right: 10px;
}
.menu-item-sub ul {
  width: 100%;
  margin: 0px 0px;
  padding: 0px 12%;
}
.menu-item-sub {
  background: #edebf2;
}
.col {
  width: 100%;
}
.container-menu {
  height: calc(100vh - 68px);
  background-color: #f8f6fe;
  color: #606060;
}
ul {
  padding: 10px;
}
li {
  padding: 10px;
  vertical-align: middle;
  vertical-align: middle;
  cursor: pointer;
}
span {
  font-size: 14px;
  font-weight: 700;
}
.select {
  font-weight: 700;
  color: #6747b3 !important;
}
.namesideconvert {
  padding-left: 10px;
  font-size: 15px;
  font-style: normal;
}
.namesideintegra {
  font-size: 15px;
  font-style: normal;
}
.label {
  width: 256px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 22px;
}
.menu-item {
  display: flex;
  align-items: center;
  padding-left: 9%;
  cursor: pointer;
}
.menu-item-false {
  height: 48px;
  cursor: pointer;
}
.menu-item p {
  margin-left: 12%;
}
.menu-item:hover {
  background: #edebf2;
}
.menu-item-false:hover {
  background: #edebf2;
}
.menu-arrow {
  padding-left: 55px;
  height: 0px;
  font-size: 15px;
  color: #6747b3;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .menu-arrow:hover {
  font-size: 17px !important;
} */
.menu-arrow-show {
  padding-left: 210px;
  height: 0px;
  font-size: 15px;
  color: #6747b3;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .menu-arrow-show:hover {
  font-size: 17px !important;
} */
</style>
