<template>
  <div>
    <Nav></Nav>
    <div class="container-page row">
      <div class="col s2 mt3" id="menu">
        <SideBar></SideBar>
      </div>

      <div
        class="col s10 content-page-right p2"
        style="height: 88vh"
        id="colgeral"
      >
        <div class="row menu">
          <span>Arquivos Enviados</span>
        </div>
        <div style="border-bottom: 1px solid rgba(96, 96, 96, 0.3)"></div>
        <div class="progress" v-if="loading">
          <div class="indeterminate"></div>
        </div>
        <table class="highlight">
          <thead>
            <tr>
              <td>Tipo</td>
              <td>Nome</td>
              <td>Data de envio</td>
              <td>Expira em</td>
              <td><center>Visualizar</center></td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in filesReceived"
              :key="index"
              v-show="expired(item.dataInserido, 96) != false"
            >
              <td>
                <div
                  v-if="item.extensao == 'application/doc'"
                  class="file-icon"
                  data-type="doc"
                ></div>
                <div
                  v-if="item.extensao == 'application/pdf'"
                  class="file-icon"
                  data-type="pdf"
                ></div>
                <div
                  v-if="item.extensao == 'text/plain'"
                  class="file-icon"
                  data-type="txt"
                ></div>
                <div
                  v-if="
                    item.extensao == 'application/excel' ||
                    item.extensao == 'application/vnd.ms-excel'
                  "
                  class="file-icon"
                  data-type="xls"
                ></div>
                <div
                  v-if="item.extensao == 'text/xml'"
                  class="file-icon"
                  data-type="xml"
                ></div>

                <div
                  v-if="item.extensao == ''"
                  class="file-icon"
                  data-type="ret"
                ></div>
                <div
                  v-if="item.extensao == 'application/x-pkcs12'"
                  class="file-icon"
                  data-type="pfx"
                ></div>
                <div
                  v-if="item.extensao == 'image/png'"
                  class="file-icon"
                  data-type="png"
                ></div>
                <div
                  v-if="item.extensao == 'image/jpg'"
                  class="file-icon"
                  data-type="jpg"
                ></div>
                <div
                  v-if="item.extensao == 'image/jpeg'"
                  class="file-icon"
                  data-type="jpeg"
                ></div>
              </td>
              <td>{{ item.nome }}</td>
              <td>{{ format(item.dataInserido) }}</td>
              <td>{{ expired(item.dataInserido, 96) }}</td>
              <td class="center">
                <i
                  v-show="item.extensao == 'application/pdf'"
                  @click="viewPdf(item.id)"
                  class="fas fa-eye"
                ></i>
                <i
                  v-show="item.extensao != 'application/pdf'"
                  @click="downloadFile(item.id)"
                  class="fas fa-file-download"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="fixed-action-btn" @click="openModal()">
      <button
        class="btn-charge"
        style="width: 94px; height: 46px; border-radius: 20px"
      >
        <i class="material-icons"> upload </i>Enviar
      </button>
    </div>
    <div id="modalFile" class="modal modal-fixed-footer">
      <div class="modal-content">
        <form action="#">
          <div class="file-field input-field">
            <div class="btn btn-charge">
              <span>Arquivo</span>
              <input type="file" />
            </div>
            <div class="file-path-wrapper">
              <input
                class="file-path validate"
                type="text"
                @change="convertFileBase64()"
              />
            </div>
            <div
              class="row center"
              style="
                font-size: 20px;
                line-height: 25px;
                color: #606060 !important;
                margin-top: 30px;
              "
            >
              Arquivos Permitidos
              <br />
              <br />
              <center>
                <Arquivos />
              </center>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect btn-flat btn-cancel"
          >Cancelar</a
        >
        <a href="#!" class="waves-effect btn-flat btn-cancel" @click="send()"
          >Enviar</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../components/Nav";
import SideBar from "../components/SideBar.vue";
import Service from "../service/Index.js";
import Imp from "../assets/files/tutorial_impressao.vue";
import Arrow from "../assets/files/arrow_button.vue";
import Arquivos from "../assets/files/arquivos_permitidos.vue";
import { controlMenu, mountedMenu } from "../factory/CenterControlMenu.js";

var moment = require("moment");

export default {
  name: "Files",
  components: {
    Nav,
    SideBar,
    Imp,
    Arrow,
    Arquivos,
  },
  data: () => {
    return {
      filesReceived: [],
      loading: false,
      modalFile: null,
      file: {
        Nome: "",
        Extensao: "",
        Value: "",
        CNPJ: "",
        login: "",
      },
    };
  },
  created() {
    this.getFiles();
    document.title = "Zee - Enviados"
  },
  updated() {
    this.initModals();
    controlMenu();
  },
  mounted() {
    mountedMenu();
  },
  methods: {
    convertFileBase64() {
      var input = document.querySelector("input[type=file]");

      var file = input.files[0],
        reader = new FileReader();
      console.log(file.name.toLowerCase().indexOf(".ret"));

      if (file.size > 3000000) {
        document.querySelector(".file-path").value = "";
        document.querySelector("input[type=file]").value = "";
        M.toast({ html: "Tamanho excedido, valor máximo permitido 3MB!" });
        return;
      }

      if (
        file.name.toLowerCase().indexOf(".xml") == -1 &&
        file.name.toLowerCase().indexOf(".ret") == -1 &&
        file.name.toLowerCase().indexOf(".txt") == -1 &&
        file.name.toLowerCase().indexOf(".pfx") == -1 &&
        file.name.toLowerCase().indexOf(".png") == -1 &&
        file.name.toLowerCase().indexOf(".jpg") == -1 &&
        file.name.toLowerCase().indexOf(".jpeg") == -1 &&
        file.name.toLowerCase().indexOf(".pdf") == -1

        // file.name.toLowerCase().indexOf(".pdf") == -1 &&
        // file.name.toLowerCase().indexOf(".xlsxs") == -1
      ) {
        document.querySelector(".file-path").value = "";
        document.querySelector("input[type=file]").value = "";
        M.toast({ html: "Arquivo enviado inválido!" });
        return;
      }
      let self = this;
      self.file.Nome = file.name;
      self.file.Extensao = file.type;
      reader.onloadend = function () {
        self.file.Value = reader.result.replace(/^data:.+;base64,/, "");
      };
      reader.readAsDataURL(file);
    },
    async send() {
      let session = JSON.parse(localStorage.getItem("session"));
      let company = await Service.Company.get(session.companies[0].companyId);

      this.file.CNPJ = company.data.CnpjCpf;
      this.file.login = session.email;

      if (this.file.Nome == "") {
        M.toast({ html: "Selecione um arquivo para envio!" });
        return;
      }
      await Service.File.postFileSend(this.file);
      this.file.Nome = "";
      document.querySelector(".file-path").value = "";
      document.querySelector("input[type=file]").value = "";

      this.modalFile[0].close();
      this.getFiles();
    },
    initModals() {
      var options = {
        edge: "right",
        draggable: true,
        inDuration: 250,
        outDuration: 200,
        onOpenStart: null,
        onOpenEnd: null,
        onCloseStart: null,
        onCloseEnd: null,
        preventScrolling: true,
      };
      if (this.modalFile == null) {
        var elemsExcel = document.querySelectorAll("#modalFile");
        console.log(elemsExcel);
        this.modalFile = M.Modal.init(elemsExcel, options);
      }
    },
    openModal() {
      this.modalFile[0].open();
    },
    expired(data, time) {
      //   if(data === undefined) {
      //     return "";
      //   }
      const dateNow = new Date();
      const dateFile = moment(data, "YYYYMMDD HH:mm:ss").add(time, "hours");

      if (dateFile <= dateNow) {
        return false;
      }
      return dateFile.format("DD/MM/YYYY hh:mm ");
    },
    format(date) {
      return moment(date).format("DD/MM/YYYY, HH:mm:ss");
    },
    async getFiles() {
      this.loading = true;
      this.filesReceived = [];
      let session = JSON.parse(localStorage.getItem("session"));
      let company = await Service.Company.get(session.companies[0].companyId);
      let result = await Service.File.getFileSend(company.data.CnpjCpf);
      if (result.status == 200) {
        result.data = result.data.reverse();
        for (let index = 0; index < result.data.length; index++) {
          result.data[index].select = false;
        }
        this.filesReceived = result.data;
      }
      this.loading = false;
    },
    async viewPdf(id) {
      this.loading = true;
      let result = await Service.File.getFileSendById(id);
      if (result.status == 200) this.openBase64NewTab(result.data.value);
      this.loading = false;
    },
    openBase64NewTab(base64Pdf) {
      var blob = this.base64toBlob(base64Pdf);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
      } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
      }
    },
    base64toBlob(base64Data) {
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: "application/pdf" });
    },
    async downloadFile(id) {
      this.loading = true;
      let result = await Service.File.getFileSendById(id);
      if (result.status == 200)
        this.decodeRequest(
          result.data.extensao,
          result.data.value,
          result.data.nome.trim().replaceAll(" ", "-")
        );
      this.loading = false;
    },
    decodeRequest(extensao, textToDecode, name) {
      var decodedString = atob(textToDecode);
      var fileName = name;
      var fileType = ".xml";
      var blob = new Blob([decodedString], { type: fileType });
      var a = document.createElement("a");
      a.download = fileName;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function () {
        URL.revokeObjectURL(a.href);
      }, 1500);
    },
  },
};
</script>
<style scoped>
.progress {
  background-color: #d9c4de !important;
}
.progress .indeterminate {
  background-color: #732792 !important;
}

thead tr td {
  font-weight: bold;
  color: #6747b3 !important;
  font-size: 14px;
}

td i {
  padding: 10px;
  font-size: 23px;
  color: #6f29a8;
  cursor: pointer;
}
/* .p2 {
  padding: 20px !important;
} */
tr td {
  padding-left: 20px;
}
.menu {
  height: 48px;
  display: flex;
  align-items: center;

  font-weight: bold;
  font-size: 14px;
  color: #606060;
  padding-left: 24px;
  margin-bottom: 0px !important;
}
.file-icon[data-type="pdf"] {
  background: #992923;
}
.file-icon[data-type="txt"] {
  background: #c4c4c4;
}
.file-icon[data-type="ret"] {
  background: #319399;
}
.file-icon[data-type="pfx"] {
  background: #512da8;
}
.file-icon[data-type="xlsx"] {
  background: #176638;
}
.file-icon[data-type="jpg"] {
  background: #4650ac;
}
.file-icon[data-type="png"] {
  background: #4650ac;
}
.file-icon[data-type="jpeg"] {
  background: #4650ac;
}
.fixed-action-btn {
  transform: rotate();
}
.btn {
  -webkit-transition: background-color 0s ease-out;
  transition: background-color 0s ease-out;
}
.modal-content {
  padding-top: 59px !important;
  padding-left: 28px !important;
}
.btn-cancel {
  right: 10px;
  color: #6747b3;
}
</style>