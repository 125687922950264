<template>
  <svg
    width="27"
    height="33"
    viewBox="0 0 27 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 15.4805L3.00025 2.00042C3.00027 0.895688 3.89597 0.000205379 5.0007 0.000458692L11.7801 0.00201319L20.0059 0L20.0002 2.5L20.0059 4.89197V5.79854H20.7002H23.8494H26.9987L26.9987 17.9761L27 28.9944C27.0001 30.1011 26.1014 30.9976 24.9946 30.9946L14.974 30.9676H5.00025C3.89569 30.9676 3.00027 30.0722 3.00025 28.9676L3 15.4805Z"
      fill="#018FEF"
    />
    <path d="M26.9677 5.80645L20 0V5.80645H26.9677Z" fill="#00568F" />
    <path d="M26 6.80645L19 1V6.80645H26Z" fill="#00568F" />
    <path
      d="M2 16.4805L2.00025 3.00042C2.00027 1.89569 2.89597 1.00021 4.0007 1.00046L10.7801 1.00201L19.0059 1L19.0002 3.5L19.0059 5.89197V6.79854H19.7002H22.8494H25.9987L25.9987 18.9761L26 29.9944C26.0001 31.1011 25.1014 31.9976 23.9946 31.9946L13.974 31.9676H4.00025C2.89569 31.9676 2.00027 31.0722 2.00025 29.9676L2 16.4805Z"
      fill="#00568F"
    />
    <path d="M24 7.80645L17 2V7.80645H24Z" fill="#018FEF" />
    <path
      d="M-1.33549e-06 17.4805L0.000245005 4.00042C0.000265084 2.89569 0.895969 2.00021 2.0007 2.00046L8.7801 2.00201L17.0059 2L17.0002 4.5L17.0059 6.89197V7.79854H17.7002H20.8494H23.9987L23.9987 19.9761L24 30.9944C24.0001 32.1011 23.1014 32.9976 21.9946 32.9946L11.974 32.9676H2.00024C0.89569 32.9676 0.000265096 32.0722 0.000245029 30.9676L-1.33549e-06 17.4805Z"
      fill="#018FEF"
    />
    <path
      d="M4.78516 28V21.5605H6.07422L6.27344 22.5449C6.69531 21.8691 7.30664 21.5312 8.10742 21.5312C8.27539 21.5312 8.41211 21.5391 8.51758 21.5547L8.49414 22.8379C8.33789 22.8145 8.17578 22.8027 8.00781 22.8027C7.44141 22.8027 7.01758 22.9531 6.73633 23.2539C6.45898 23.5547 6.32031 23.9727 6.32031 24.5078V28H4.78516ZM11.1074 28.123C10.5098 28.123 10.0254 27.959 9.6543 27.6309C9.28711 27.2988 9.10352 26.8223 9.10352 26.2012C9.10352 25.5332 9.32227 25.0391 9.75977 24.7188C10.1973 24.3945 10.8574 24.1895 11.7402 24.1035C11.8613 24.0879 11.9922 24.0723 12.1328 24.0566C12.2734 24.041 12.4316 24.0254 12.6074 24.0098C12.7832 23.9941 12.9219 23.9805 13.0234 23.9688V23.6289C13.0234 23.2383 12.9336 22.9551 12.7539 22.7793C12.5742 22.5996 12.3008 22.5098 11.9336 22.5098C11.4102 22.5098 10.7637 22.6562 9.99414 22.9492C9.99023 22.9375 9.92578 22.7598 9.80078 22.416C9.67578 22.0723 9.61133 21.8965 9.60742 21.8887C10.3652 21.5645 11.1855 21.4023 12.0684 21.4023C12.9355 21.4023 13.5664 21.5918 13.9609 21.9707C14.3555 22.3457 14.5527 22.9551 14.5527 23.7988V28H13.416C13.4121 27.9844 13.3691 27.8535 13.2871 27.6074C13.2051 27.3613 13.1641 27.2305 13.1641 27.2148C12.8438 27.5273 12.5293 27.7578 12.2207 27.9062C11.916 28.0508 11.5449 28.123 11.1074 28.123ZM11.5234 27.0332C11.8789 27.0332 12.1875 26.9492 12.4492 26.7812C12.7148 26.6094 12.9043 26.4023 13.0176 26.1602V24.918C13.0059 24.918 12.9023 24.9258 12.707 24.9414C12.5156 24.957 12.4121 24.9648 12.3965 24.9648C11.7754 25.0195 11.3203 25.1367 11.0312 25.3164C10.7422 25.4961 10.5977 25.7852 10.5977 26.1836C10.5977 26.457 10.6777 26.668 10.8379 26.8164C10.998 26.9609 11.2266 27.0332 11.5234 27.0332ZM16.1055 28V21.5605H17.3945L17.5938 22.5449C18.0156 21.8691 18.627 21.5312 19.4277 21.5312C19.5957 21.5312 19.7324 21.5391 19.8379 21.5547L19.8145 22.8379C19.6582 22.8145 19.4961 22.8027 19.3281 22.8027C18.7617 22.8027 18.3379 22.9531 18.0566 23.2539C17.7793 23.5547 17.6406 23.9727 17.6406 24.5078V28H16.1055Z"
      fill="white"
    />
  </svg>
</template>
