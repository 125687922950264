<template>
  <div>
    <Nav></Nav>

    <div class="container-page row">
      <div class="row">
        <div class="col s2">
          <SideBar></SideBar>
        </div>
        <div class="col s10">
          <div class="row">
            <div class="col s12 center">
              <img
                width="250"
                src="../assets/NotFoundPage/error-404.png"
                alt="Banner página não encontrada"
              />
            </div>
          </div>
          <div class="row center">
            <div class="col s12 center">
              <h3>Página não localizada</h3>
              <p>Você pode tentar retornar a página inicial =)</p>
            </div>
          </div>
          <div class="row">
            <div class="s12 group-button center">
              <router-link to="/home" class="waves-effect waves-light btn-large"
                >Ir para página inicial</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav";
import SideBar from "../components/SideBar.vue";

export default {
  created() {
    document.title = "Zee - Página Não Localizada";
  },
  name: "NavBar",
  components: {
    Nav,
    SideBar
  }
};
</script>
<style scoped>
img {
  margin-top: 5vh;
}
</style>
