import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Session from "./factory/Session.ts";
import mitt from "mitt";
const emitter = mitt();

import "sweetalert2/dist/sweetalert2.min.css";

var app = createApp(App)
  .use(store)
  .use(router)
  .mount("#app");

window.Emitter = emitter;

if (!Session.verifySession()) {
  localStorage.clear();
  router.push("/");
}
