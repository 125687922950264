import Https from "./Config";

export default {
  get: cnpj =>
    Https.createAxiosInstance(
      false,
      "https://api.serverlondrisoft.com:8010/",
      false
    ).get(`Relatorios/${cnpj}`),
  getById: id =>
    Https.createAxiosInstance(
      false,
      "https://api.serverlondrisoft.com:8010/",
      false
    ).get(`Relatorios/Content/${id}`),
  getFileSend: cnpj =>
    Https.createAxiosInstance(
      false,
      "https://api.serverlondrisoft.com:8010/",
      false
    ).get(`Arquivos/${cnpj}`),
  postFileSend: obj =>
    Https.createAxiosInstance(
      false,
      "https://api.serverlondrisoft.com:8010/",
      false
    ).post(`Arquivos/`, obj),
  getFileSendById: id =>
    Https.createAxiosInstance(
      false,
      "https://api.serverlondrisoft.com:8010/",
      false
    ).get(`Arquivos/Content/${id}`),
  getByHash: hash =>
    Https.createAxiosInstance(
      false,
      "https://api.serverlondrisoft.com:8010/",
      false
    ).get(`Relatorios/ContentByHash/${hash}`)
};
