<template>
  <svg
    width="136"
    height="51"
    viewBox="0 0 136 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 16H23" stroke="#6747B3" />
    <path d="M0 30H23" stroke="#6747B3" />
    <ellipse cx="97.5" cy="47.5" rx="36.5" ry="3.5" fill="#F8F6FE" />
    <path
      d="M131.599 0.66968H63.401C62.8135 0.669004 62.2361 0.822888 61.7255 1.11617C61.6032 1.18627 61.4854 1.26415 61.3729 1.34935C60.9469 1.66832 60.6008 2.08366 60.3624 2.56206C60.1239 3.04047 59.9998 3.56864 60 4.10422V39.8957C60.0008 40.8063 60.3594 41.6795 60.997 42.3234C61.6346 42.9673 62.4992 43.3294 63.401 43.3302H131.599C132.501 43.3294 133.365 42.9673 134.003 42.3234C134.641 41.6794 134.999 40.8063 135 39.8957V4.10422C134.999 3.19357 134.641 2.32046 134.003 1.67654C133.365 1.03262 132.501 0.670504 131.599 0.66968V0.66968ZM134.642 39.8957C134.641 40.7103 134.32 41.4913 133.749 42.0673C133.179 42.6434 132.406 42.9675 131.599 42.9687H63.401C62.5943 42.9675 61.8209 42.6434 61.2505 42.0674C60.6801 41.4913 60.3591 40.7104 60.358 39.8957V4.10422C60.3605 3.45478 60.5656 2.82267 60.9441 2.29764C61.3226 1.7726 61.8553 1.38135 62.4665 1.17944C62.4916 1.17221 62.5149 1.16317 62.54 1.15594C62.8196 1.07293 63.1095 1.03093 63.401 1.03121H131.599C132.406 1.03238 133.179 1.35651 133.749 1.93256C134.32 2.50861 134.641 3.28956 134.642 4.10422V39.8957Z"
      stroke="#6747B3"
    />
    <path
      d="M97.2173 21.9999C96.9455 21.9998 96.678 21.9316 96.44 21.8016L60.7807 2.37836C60.7391 2.35569 60.7083 2.31758 60.6951 2.27242C60.6818 2.22727 60.6873 2.17876 60.7102 2.13757C60.7331 2.09638 60.7716 2.06588 60.8172 2.05279C60.8629 2.0397 60.9119 2.04508 60.9535 2.06775L96.6128 21.491C96.7946 21.5903 96.9986 21.6433 97.2063 21.6451C97.4141 21.6469 97.619 21.5975 97.8026 21.5013L134.049 2.57869C134.07 2.56777 134.092 2.56101 134.116 2.55882C134.139 2.55662 134.163 2.55903 134.185 2.56589C134.208 2.57275 134.229 2.58395 134.247 2.59882C134.265 2.61369 134.28 2.63196 134.291 2.65257C134.302 2.67319 134.309 2.69575 134.311 2.71896C134.313 2.74217 134.311 2.76557 134.304 2.78783C134.297 2.8101 134.285 2.83078 134.27 2.84869C134.255 2.86661 134.237 2.88141 134.216 2.89224L97.9698 21.8149C97.7379 21.9363 97.4796 21.9998 97.2173 21.9999V21.9999Z"
      stroke="#6747B3"
    />
    <path
      d="M97.5 27C100.538 27 103 24.7614 103 22C103 19.2386 100.538 17 97.5 17C94.4624 17 92 19.2386 92 22C92 24.7614 94.4624 27 97.5 27Z"
      fill="#6747B3"
    />
    <path d="M29 40H45V34H40.1765" stroke="#6747B3" />
    <path d="M35 26H51V20H46.1765" stroke="#6747B3" />
    <path d="M29 12H45V6H40.1765" stroke="#6747B3" />
  </svg>
</template>